import { Modal, Select } from "antd";
import React from "react";

const PelvisSelectorModal = ({
  openPelvisSelectorModal,
  setOpenPelvisSelectorModal,
  handleSelectPelvisPart,
}) => {
  return (
    <Modal
      title="Select Part"
      open={openPelvisSelectorModal}
      onCancel={() => setOpenPelvisSelectorModal(false)}
      footer={null}
    >
      <Select
        style={{ width: 200 }}
        onChange={(value) => handleSelectPelvisPart(value)}
        aria-placeholder="select symptom part"
        options={[
          { label: "Pelvis", value: "Pelvis" },
          { label: "Genitalia", value: "Genitalia" },
        ]}
      />
    </Modal>
  );
};

export default PelvisSelectorModal;
