// PhoneNumberInput.jsx
import React from "react";
import { Form } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./PhoneInput.css";

const PhoneNumberInput = ({ form, name = null, label = null }) => {
  const validatePhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject();
    }

    const isValid = isValidPhoneNumber(value, "ET"); // Use the appropriate country code
    if (!isValid) {
      return Promise.reject(new Error("Invalid phone number format"));
    }
    return Promise.resolve();
  };

  const handlePhoneNumberChange = (formattedNumber) => {
    form.setFieldsValue({ phone: formattedNumber });
    // console.log(formattedNumber);
  };

  return (
    <Form.Item
      name={name || "phone"}
      // label={label || "Phone"}
      label={label}
      rules={[
        { required: name !== "phone2" },
        { validator: validatePhoneNumber },
      ]}
    >
      <PhoneInput
        country={"et"}
        onlyCountries={["et"]}
        masks={{ et: "(...) ..-..-.." }}
        enableAreaCodes={true}
        className="custom-phone-input"
        onChange={handlePhoneNumberChange}
      />
    </Form.Item>
  );
};

export default PhoneNumberInput;
