import { React, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch.js";
import { Link } from "react-router-dom";
import { Card } from "antd";
// import {
//   consultData,
//   specialistData,
//   articleData,
// } from "../../services/data.js";
import "./Home.css";
import Footer from "../../../components/common/footer/Footer.jsx";
import Sidebar from "../../../components/common/sidebar/Sidebar.jsx";
import Slider from "../../../components/common/slider/Slider.jsx";
import ErrorPage from "../../../components/common/error-page/ErrorPage.jsx";

const { Meta } = Card;

function Home() {
  const [serviceData, setServiceData] = useState();
  const [specialistData, setSpecialistData] = useState();
  const [articleData, setArticleData] = useState();
  const [consultData, setConsultData] = useState();
  //
  const [serviceDataErr, setServiceDataErr] = useState(false);
  const [specialistDataErr, setSpecialistDataErr] = useState(false);
  const [articleDataErr, setArticleDataErr] = useState(false);
  const [consultDataErr, setConsultDataErr] = useState(false);

  const {
    data: serviceDataRes,
    loading: serviceLoading,
    error: serviceError,
    getRequest: getServices,
  } = useFetch("/api/our-services");
  const {
    data: specialistDataRes,
    loading: specialistLoading,
    error: specialistError,
    getRequest: getSpecialistData,
  } = useFetch("/api/specialists");

  const {
    data: consultDataRes,
    loading: consultLoading,
    error: consultError,
    getRequest: getConsultData,
  } = useFetch("/api/consults");
  const {
    data: articleDataRes,
    loading: articleLoading,
    error: articleError,
    getRequest: getArticleData,
  } = useFetch("/api/articles");

  useEffect(() => {
    getServices();
    getSpecialistData();
    getArticleData();
    getConsultData();
  }, []);

  useEffect(() => {
    console.log("service", serviceDataRes);
    if (serviceDataRes?.data) {
      setServiceData(serviceDataRes.data);
    }
    if (serviceError) {
      setServiceDataErr(true);
    }
  }, [serviceDataRes, serviceError]);
  //
  useEffect(() => {
    if (specialistDataRes?.data) {
      setSpecialistData(specialistDataRes.data);
    }
    if (specialistError) {
      setSpecialistDataErr(true);
    }
  }, [specialistDataRes, specialistError]);
  //
  useEffect(() => {
    console.log("consult", consultDataRes, consultError);

    if (consultDataRes?.data) {
      setConsultData(consultDataRes.data);
    }
    if (consultError) {
      setConsultDataErr(true);
    }
  }, [consultDataRes, consultError]);
  //
  useEffect(() => {
    if (articleDataRes?.data) {
      setArticleData(articleDataRes.data);
    }

    if (articleError) {
      setArticleDataErr(true);
    }
  }, [articleDataRes, articleError]);

  return (
    <>
      <Sidebar />
      <main id="Home-main" className="container">
        <section>
          <div id="search">
            <div className="container">
              <div className="from-container">
                <input
                  className="full-width search"
                  type="search"
                  id="search"
                  placeholder="Search for: Doctors , Clinic , Hospital , Pharmacy , etc . . ."
                />
              </div>
            </div>
          </div>
        </section>

        <section id="home-service">
          <div className="container">
            <div className="card-container">
              {serviceDataErr && <ErrorPage />}
              {serviceData &&
                serviceData.map((data, i) => {
                  const { image, title, content, link } = data;
                  return (
                    <div key={i} className="card box-shadow">
                      <div className="card-img">
                        <img src={image} alt="" />
                      </div>
                      <div className="card-content">
                        <Link to={`/app/${link}`}>
                          {" "}
                          <h4 className="">{title}</h4>
                          <p>{content}</p>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section id="home-consult" className="py-3 border-bottom">
          <div className="container">
            <div className="section-header">
              <h3> Consult top doctors for any health concern</h3>
              <p>
                Private consultations with verified doctors in all specialists
              </p>
            </div>
            <div className="card-container">
              {consultDataErr && <ErrorPage />}
              {consultData &&
                consultData.map((data, i) => {
                  const { image, title, content, link } = data;
                  return (
                    <article key={i} className="card border">
                      <div className="circle-img bg-icon bg-icon">
                        <img src={image} alt="" />
                      </div>
                      <div className="card-content">
                        <h4 className="heading">{title}</h4>
                        <p className="price">{400}ETB</p>
                        <Link className="text-primary" to={`/app/${link}`}>
                          {content}
                        </Link>
                      </div>
                    </article>
                  );
                })}
            </div>
          </div>
        </section>

        <section id="home-book" className="py-3 border-bottom">
          <div className="container">
            <div className="section-header">
              <h3> Book an appointment for an in-clinic consultation</h3>
              <p>Find experienced doctors across all specialties</p>
            </div>
            <div className="card-container">
              {specialistDataErr && <ErrorPage />}
              {specialistData &&
                specialistData.map((data, i) => {
                  const { image, title, content, link } = data;
                  return (
                    <article key={i} className="card border">
                      <div className="image-cont">
                        <div className="card-img ">
                          <img src={image} alt="" />
                        </div>
                      </div>
                      <div className="card-content">
                        <h4 className="heading">{title}</h4>

                        <Link className="text-primary" to={`/${link}`}>
                          {content}
                        </Link>
                      </div>
                    </article>
                  );
                })}
            </div>
          </div>
        </section>

        <section id="home-article" className="py-3 border-bottom">
          <div className="container">
            <div className="grid-2-1">
              <div className="card-content">
                <h3 className="py-2"> Read top articles from health experts</h3>
                <p>
                  Dive into a vast library of articles: We cover a comprehensive
                  range of medical topics, from common ailments to chronic
                  conditions. Find in-depth explanations, symptom guides,
                  treatment options, and preventative measures – all presented
                  in an accessible format.
                </p>
                <a className="btn bg-primary home-article-btn">
                  See all articles
                </a>
              </div>
              <div className="card-container">
                {articleDataErr && <ErrorPage />}
                {articleData &&
                  articleData.map((data, i) => {
                    const { image, title, content, link, name } = data;
                    return (
                      <article key={i} className="card border">
                        <div className="card-img">
                          <img src={image} alt="" />
                        </div>
                        <div className="card-content">
                          <h6 className="heading">{title}</h6>

                          <h4 className="text-primary">{content}</h4>
                          <p className="text-primary">{name}</p>
                        </div>
                      </article>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
        <Slider />
        <section id="home-download" className="py-4 ">
          <div className="container">
            <div className="grid-2-1">
              <div className=" download-img-1">
                <img className="" src="../images/download-img-4.jpg" alt="" />
              </div>

              <div className="card">
                <div className="card-content">
                  <h1>Download the YESMADE app </h1>
                  <p>
                    Access consultation with YesMed top doctors on the YESMADE
                    app. Connect with doctors online, available 24/7, from the
                    comfort of your home.
                  </p>
                </div>
                <div className="grid-2-1">
                  <div className="card-img">
                    {" "}
                    <img src="../images/download-img-1.webp" alt="" />
                  </div>
                  {/* <div className="card-img">
                    {" "}
                    <img src="../images/download-img-2.webp" alt="grid-img" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Home;
