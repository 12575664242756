import React, { useState } from "react";
import HospitalBooking from "../hospital-booking/HospitalBooking";
import HospitalDetails from "../hospital-details/HospitalDetails";
import DoctorDetail from "../doctor-detail/DoctorDetail";
import AppointmentDetails from "../appointment-details/AppointmentDetails";
import BookingCalendar from "../booking-calendar/BookingCalendar";

const HospitalPagesNavigate = () => {
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // Add this line
  const [view, setView] = useState("hospitalBooking");

  const handleHospitalCardClick = (hospital) => {
    setSelectedHospital(hospital);
    setView("hospitalDetails");
  };

  const handleDoctorCardClick = (doctor) => {
    setSelectedDoctor(doctor);
    setView("doctorDetail");
  };

  const handleBookAppointment = () => {
    setView("appointmentDetails");
  };

  const handleBackToHospitalDetails = () => {
    setSelectedDoctor(null);
    setView("hospitalDetails");
  };

  const handleBackToHospitals = () => {
    setSelectedHospital(null);
    setView("hospitalBooking");
  };

  const handleConfirmBooking = (appointment) => {
    setSelectedAppointment(appointment);
    setView("bookingCalendar");
  };

  return (
    <>
      {view === "hospitalBooking" && (
        <HospitalBooking onCardClick={handleHospitalCardClick} />
      )}
      {view === "hospitalDetails" && (
        <HospitalDetails
          hospital={selectedHospital}
          onDoctorCardClick={handleDoctorCardClick}
          onBack={handleBackToHospitals}
        />
      )}
      {view === "doctorDetail" && (
        <DoctorDetail
          hospital={selectedHospital}
          doctor={selectedDoctor}
          onBook={handleBookAppointment}
          onHospitalClick={handleHospitalCardClick} // Add this prop
          onBack={handleBackToHospitalDetails}
        />
      )}
      {view === "appointmentDetails" && (
        <AppointmentDetails
          doctor={selectedDoctor}
          hospital={selectedHospital}
          onBack={handleBackToHospitalDetails}
          onConfirm={handleConfirmBooking} // Correctly pass the function here
        />
      )}
      {view === "bookingCalendar" && (
        <BookingCalendar
          appointmentType={selectedAppointment}
          doctor={selectedDoctor}
          hospital={selectedHospital}
          onBack={() => setView("appointmentDetails")}
        />
      )}
    </>
  );
};

export default HospitalPagesNavigate;
