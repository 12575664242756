import React, { useState } from "react";
import { Checkbox, Typography, Button, Space } from "antd";

const { Title } = Typography;

const GeneralSymptomsSelector = ({ onNext, formData, setFormData }) => {
  const AdultSymptoms = [
    "Fever (elevated body temperature)",
    "Loss of Appetite (not feeling hungry or eating less than usual)",
    "Weight Loss (losing weight without trying)",
    "Fatigue (feeling very tired or having no energy)",
    "Night Sweating (sweating excessively during sleep)",
    "Chills (feeling cold or shivering)",
    "Cough (persistent coughing)",
    "Shortness of Breath (difficulty breathing or feeling breathless)",
    "Nausea (feeling like they need to throw up)",
    "Vomiting (throwing up)",
  ];
  const childSymptoms = [
    "Fever (elevated body temperature)",
    "Poor feeding (less breastfeeding or formula milk feeding or eating less than usual)",
    "Irritable or excessively crying (the baby crying more than usual)",
    "Lethargic (the baby is sleepy or less active than usual)",
    "Weight Loss (losing weight without trying)",
    "Night Sweating (sweating excessively during sleep)",
    "Cough (persistent coughing)",
    "Fast breathing (Breathing very fast from usual)",
    "Vomiting (throwing out anything the baby is fed)",
  ];
  const [selectedSymptoms, setSelectedSymptoms] = useState(
    formData.selectedGeneralSymptoms || []
  );
  const symptoms = formData.age > 13 ? AdultSymptoms : childSymptoms;
  const handleSymptomChange = (checkedValues) => {
    setSelectedSymptoms(checkedValues);
  };

  const handleNext = () => {
    setFormData({ ...formData, selectedGeneralSymptoms: selectedSymptoms }); // Persist to formData
    setSelectedSymptoms([]); // Reset locally
    onNext({});
  };
  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <div>
        <Title level={4} style={{ textAlign: "center" }}>
          General Symptoms
        </Title>

        <Checkbox.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
          onChange={handleSymptomChange}
          value={selectedSymptoms}
        >
          {symptoms.map((symptom, index) => (
            <Checkbox key={index} value={symptom}>
              {symptom}
            </Checkbox>
          ))}
        </Checkbox.Group>

        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Space>
            <Button type="primary" onClick={handleNext}>
              Next
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default GeneralSymptomsSelector;
