import React, { useState } from "react";
import { Steps, Card, Button } from "antd";
import VisualBodySelector from "./visual-body-selector/VisualBodySelector";
import PatientDetailForm from "./patient-detail-form/PatientDetailForm";
import GeneralSymptomsSelector from "./generalSymptoms/GeneralSymptomsSelector ";
import TermOfService from "./generalSymptoms/TermOfService";
import SymptomSelector from "./symptom-selector/SymptomSelector";

const { Step } = Steps;

const SymptomChecker = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    isAccepted: false, // Terms of Service
    selectedGeneralSymptoms: [], // General Symptoms
    age: null,
    gender: null,
    isPregnant: false,
    selectedSymptomsDetails: [], // SymptomSelector
    additionalInfo: {
      //SymptomSelector
      duration: "",
      medicalConditions: "",
      medications: "",
      additionalSymptoms: "",
    },
  });

  // Handle Next button click
  const handleNext = (stepData) => {
    console.log("the data from the current step is:", stepData);
    // Update form data with the data from the current step
    setFormData((prev) => {
      const updatedFormData = { ...prev, ...stepData };
      console.log("The updated form data is:", updatedFormData);
      return updatedFormData; // Update the form data with the new values
    });
    // Move to the next step
    setCurrentStep((prev) => prev + 1);
  };

  // Handle Back button click
  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // Render the current step's component
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <TermOfService
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <PatientDetailForm
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <GeneralSymptomsSelector
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );

      case 3:
        return (
          <VisualBodySelector
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", padding: "20px" }}>
      <Card
        style={{ maxWidth: "500px", margin: "auto", marginTop: "1rem" }}
        title={
          currentStep > 0 && (
            <Button
              onClick={handleBack}
              style={{
                backgroundColor: "transparent",
                color: "#000",
                padding: "8px 12px",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                fontSize: 11,
              }}
              aria-label="Back to the form"
            >
              ← Back to the previous step
            </Button>
          )
        }
        headStyle={{
          borderBottom: "none", // Removes the line below the title
        }}
      >
        {/* Ant Design Steps */}
        <Steps
          current={currentStep}
          style={{ marginBottom: 20, display: "none" }}
        >
          <Step title="Terms of Service" />
          <Step title="General Symptoms" />
          <Step title="Age & Gender" />
          <Step title="Symptom Checker Guidance" />
          {/* <Step title="Symptom Selector" /> */}
          <Step title="Symptom history" />
        </Steps>

        {/* Render the current step's content */}
        {renderStepContent()}
      </Card>
    </div>
  );
};

export default SymptomChecker;
