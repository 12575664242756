import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import ErrorPage from "../../../common/error-page/ErrorPage";
import { Card, Button } from "antd";
import img from "../../../../image/med1.webp";
import "./PharmacyPortal.css";

const { Meta } = Card;

function PharmacyPortal() {
  const [medicineData, setMedicineData] = useState([]);
  const [medicineDataErr, setMedicineDataErr] = useState(false);

  const {
    data: medicineDataRes,
    loading: medicineLoading,
    error: medicineError,
    getRequest,
  } = useFetch("/api/medicine");

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (medicineDataRes) {
      setMedicineData(medicineDataRes?.medicines || []);
    }
    if (medicineError) {
      setMedicineDataErr(true);
    }
  }, [medicineDataRes, medicineError]);

  return (
    <>
      {medicineDataErr && <ErrorPage />}
      {!medicineDataErr && (
        <div id="pharmacy-portal">
          <SearchMedicine medicineData={medicineData} />

          <section className="pharmacy-portal">
            <h1 className="pharmacy-title">Hibret Pharmacy</h1>
            <div className="medicine-grid">
              {medicineData.map((medicine, i) => (
                <MedicineCard key={i} medicine={medicine} />
              ))}
            </div>
          </section>
          <div className="post-medicine-button">
            <a href="/medicineForm">Post a New Medicine</a>
          </div>
        </div>
      )}
    </>
  );
}

const SearchMedicine = ({ medicineData }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (medicine) => {
    // Navigate to the selected medicine page
    navigate(`/app/medicine/${medicine.ID}`);
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) {
      setSearchResults([]);
      return;
    }

    const filteredData = medicineData?.filter((medicine) =>
      medicine["MEDICINE NAME"]
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm, medicineData]);

  return (
    <section id="search">
      <div className="medicine-nav-container">
        <div className="search-container">
          <div className="search-bar search">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search for Medicine"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <div className="search-list-container">
                <ul className="lists">
                  {searchResults.length > 0
                    ? searchResults.map((medicine) => (
                        <li
                          className="text-primary name"
                          key={medicine.ID}
                          onClick={() => handleMedicineClick(medicine)}
                        >
                          {medicine["MEDICINE NAME"]}
                        </li>
                      ))
                    : searchTerm && <p>No results found.</p>}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MedicineCard = ({ medicine }) => {
  const navigate = useNavigate();

  const handleMedicineClick = (medicine) => {
    // Navigate to the selected medicine page
    navigate(`/app/medicine/${medicine.ID}`);
  };
  return (
    <Card
      hoverable
      style={{
        width: 250,
      }}
      cover={
        <img
          style={{
            width: "100%",
            height: 150,
            objectFit: "cover",
          }}
          alt={medicine["MEDICINE NAME"]}
          src={medicine?.image || img}
        />
      }
    >
      <Meta
        title={medicine["MEDICINE NAME"]}
        description={
          <div id="medicine-description">
            <p>{medicine.DETAIL}</p>
            {/* <p>{medicine.STORAGE}</p> */}
          </div>
        }
      />
      <div
        className="medicine-antd-card-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <h2 className="price">${medicine?.PRICE || 255}</h2>
        <Button onClick={() => handleMedicineClick(medicine)} type="primary">
          Add
        </Button>
      </div>
    </Card>
  );
};

export default PharmacyPortal;
