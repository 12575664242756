import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Image,
  Typography,
  Rate,
  Button,
  Row,
  Col,
  Space,
} from "antd";
import useFetch from "../../../hooks/useFetch";

const { Title, Text } = Typography;

const HospitalDetails = ({ hospital, onDoctorCardClick, onBack }) => {
  const [doctorList, setDoctorList] = useState([]);
  const { data: doctors, loading, getRequest } = useFetch("/api/doctorInHosp");

  useEffect(() => {
    console.log(
      "hospital ,,,,,,,,,,,,,,,,aaaaaaaaaaaaaaaaaaaaaaa",
      hospital,
      "sssssssss",
      hospital.userId,
      hospital.phone
    );
    if (hospital.phone) getRequest({ phone: hospital.phone });
  }, []);

  useEffect(() => {
    if (doctors) {
      setDoctorList(doctors.doctors);
    }
    console.log(
      "doctors ,,,,,,,,,,,,,,",
      doctors,
      "----------length-------",
      doctors?.doctors?.length
    );
  }, [doctors]);

  return (
    <Card style={{ padding: "20px", margin: "auto", maxWidth: "100%" }}>
      <Title level={2} style={{ textAlign: "center" }}>
        {hospital.name || hospital.organizationName}
      </Title>

      {/* Hospital Image - center aligned, responsive */}
      <Row justify="center">
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Image
            width="100%"
            src={`/images/hospital/${hospital.profImg}`}
            alt={hospital.name || hospital.organizationName}
            style={{ marginBottom: "20px" }}
          />
        </Col>
      </Row>

      {/* Doctors List */}
      <Title level={3}>{doctors?.doctors?.length} Doctors</Title>
      {doctorList && (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
          }}
          dataSource={doctorList}
          renderItem={(doctor) => (
            <List.Item key={doctor.id}>
              <Card
                bordered={false}
                onClick={() => onDoctorCardClick(doctor)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Box shadow
                  transition: "box-shadow 0.3s", // Transition for smooth effect
                }}
                hoverable // Ensures hover effect
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow =
                    "0 4px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow =
                    "0 2px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <Space align="center" style={{ width: "100%" }}>
                  <Image
                    src={`/images/user/${doctor.profImg}`}
                    alt={doctor.fullName}
                    preview={false}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      marginRight: "1rem",
                    }}
                  />
                  <Space direction="vertical" size={0}>
                    <Text strong>{doctor.fullName}</Text>
                    <Text type="secondary">{doctor.specialty}</Text>
                  </Space>
                </Space>
              </Card>
            </List.Item>
          )}
        />
      )}

      {/* Services List */}
      <Title level={3}>Services</Title>
      <List
        dataSource={hospital.services}
        renderItem={(service, index) => (
          <List.Item
            key={index}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 4,
            }}
          >
            <Card
              cover={<Image src={service.image} alt={service.name} />}
              bordered={false}
              style={{
                textAlign: "center",
                cursor: "pointer", // Add pointer cursor
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Box shadow
                transition: "box-shadow 0.3s", // Transition for smooth effect
              }}
              hoverable // Ensures hover effect
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 4px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 2px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <Title>{service.name}</Title>
              <Text>{service.description}</Text>
            </Card>
          </List.Item>
        )}
      />

      {/* Location and Rating - Adjusted with responsive Columns */}
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Title level={4}>Location</Title>
          <Text className="hidden">{hospital.location}</Text>
        </Col>
        <Col xs={24} sm={12}>
          <Title level={4}>Rating</Title>
          <Rate disabled defaultValue={hospital.rating} />
        </Col>
      </Row>

      {/* Back Button - centered and responsive */}
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col>
          <Button type="primary" onClick={onBack} style={{ width: "100%" }}>
            Back
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default HospitalDetails;
