import { Modal, Select } from "antd";
import React, { useEffect } from "react";

const HeadSelectorModal = ({
  openHeadSelectorModal,
  setOpenHeadSelectorModal,
  handleSelectHeadPart,
}) => {
  return (
    <Modal
      title="Select Part"
      open={openHeadSelectorModal}
      onCancel={() => setOpenHeadSelectorModal(false)}
      footer={null}
    >
      <Select
        style={{ width: 200 }}
        onChange={(value) => handleSelectHeadPart(value)}
        options={[
          { label: "Head", value: "Head" },
          { label: "Eye", value: "Eye" },
          { label: "Ear", value: "Ear" },
          { label: "Nose", value: "Nose" },
          { label: "Mouth and Throat", value: "Mouth and Throat" },
          { label: "Face", value: "Face" },
          { label: "Scalp", value: "Scalp" },
        ]}
      />
    </Modal>
  );
};

export default HeadSelectorModal;
