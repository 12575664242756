import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import ErrorPage from "../../../common/error-page/ErrorPage";
import { useParams } from "react-router-dom";
import Footer from "../../../common/footer/Footer.jsx";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
import { SearchMedicine } from "../../patient/pharmacy-user-portal/PharmacyUserPortal.jsx";
import img from "../../../../image/med.webp";
import { Card } from "antd";
import "./MedicineMainInfo.css";

const MedicineMainInfo = () => {
  const [cartItems, setCartItems] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const [medicineDataErr, setMedicineDataErr] = useState(false);

  const {
    data: medicineDataRes,
    loading: medicineLoading,
    error: medicineError,
    getRequest,
  } = useFetch("/api/medicine");

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (medicineDataRes) {
      setMedicineData(medicineDataRes?.medicines || []);
    }
    if (medicineError) {
      setMedicineDataErr(true);
    }
  }, [medicineDataRes, medicineError]);

  const { id } = useParams();
  console.log(id);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false); //for the popup message
  const selectedMedicine = medicineData?.find((medicine) => medicine?.ID == id);
  console.log(selectedMedicine?.ID, id);

  const handleAddToCartClick = () => {
    // Add code to actually add the item to the cart (not shown here)
    setShowConfirmationPopup(true); // Show the confirmation popup
  };

  const handleClosePopup = () => {
    setShowConfirmationPopup(false); // Hide the confirmation popup
  };

  const handleConfirmAddToCart = () => {
    const cartItem = { ...selectedMedicine }; // Create a copy
    cartItems.push(cartItem);
    // Add the item to the cart (if not already done in handleAddToCartClick)
    setShowConfirmationPopup(false); // Hide the confirmation popup after confirmation
    console.log(cartItem);
  };

  return (
    <>
      {medicineDataErr && <ErrorPage />}
      {medicineData && (
        <div id="medicine-detail">
          <SearchMedicine />
          {/* <UserPortal /> */}
          <div id="head">
            <h2>Medicine Details</h2>
            <div id="content-wrapper">
              <Card className="right-container">
                <img src={img} alt="NO Image" />
              </Card>
              <Card className="left-container">
                <h3>MEDICINE NAME: {selectedMedicine?.["MEDICINE NAME"]}</h3>

                {/* <p>
                  <strong> PURPOSE: </strong> {selectedMedicine?.PURPOSE}
                </p> */}
                {/* <p>
                  <strong> TYPE: </strong> {selectedMedicine?.TYPE}
                </p>
                <p>
                  <strong> STORAGE: </strong> {selectedMedicine?.STORAGE}
                </p>
                <p>
                  <strong> FREQUENCY : </strong> {selectedMedicine?.FREQUENCY}{" "}
                </p> */}
                <p>
                  <strong> DETAIL: </strong> {selectedMedicine?.DETAIL}
                </p>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam
                  laudantium, cum, tenetur sequi eum facilis veniam consequatur
                  placeat delectus modi quas quisquam ab! Assumenda dolorem
                  iusto asperiores eaque obcaecati consequatur recusandae, fuga
                  itaque, facere aliquid repellendus! Fuga nihil dignissimos
                  quas?
                </p>
              </Card>
            </div>
          </div>
          <div className="popup">
            <div className="add-to-cart-button">
              <button onClick={handleAddToCartClick}>Add to Cart</button>
            </div>
            {showConfirmationPopup && (
              <div id="confirmation-popup" className="hidden">
                <div className="popup-container">
                  <h3 className="popup-title">Confirm Action</h3>
                  <p className="popup-message">
                    Are you sure you want to add this item to your cart?
                  </p>
                  <div className="button-container">
                    <button
                      id="confirm-button"
                      className="primary-button"
                      onClick={handleConfirmAddToCart}
                    >
                      Yes
                    </button>
                    <button
                      id="cancel-button"
                      className="secondary-button"
                      onClick={handleClosePopup}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default MedicineMainInfo;
