import { React, useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import "./PatientCart.css";

function UserCart() {
  const [cartItems, setCartItems] = useState([]);
  return (
    <>
      <div id="view-cart">
        <div className="view-cart">
          <div className="cart-container">
            <h1>Your Cart</h1>
            {cartItems.length === 0 ? (
              <p>
                <strong>Your cart is empty.</strong>
              </p>
            ) : (
              <ol>
                {cartItems.map((cartItem) => (
                  <li key={cartItem.id}>
                    <div className="content-wrapper">
                      <div className="right-content">
                        <img src={cartItem.image} alt={cartItem.name} />
                      </div>
                      <div>
                        <h2>Name: {cartItem.name}</h2>
                        <h3>dosage: {cartItem.dosage}</h3>
                        <h3>It cures from: {cartItem.pain}</h3>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserCart;
