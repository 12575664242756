import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Form,
  Select,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import usePost from "../../../../hooks/usePost";
import usePatch from "../../../../hooks/usePatch";
import useToast from "../../../common/toast/toast";
import useFetch from "../../../../hooks/useFetch";
import useUpload from "../../../../hooks/useUpload";
import NewMedicineForm from "../admin-medicine-form/MedicineForm";
const { Option } = Select;

const AdminMedicineTable = () => {
  const { showToast } = useToast();
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateRes, setUpdateRes] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  //// form modal state
  const [fileList, setFileList] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [addPost, setAddPost] = useState(false);
  //
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  //
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  //// form modal upload
  const { upload, reset: resetUpload } = useUpload("/api/medicine", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });
  //
  // const [postId, setPostId] = useState(null);
  const [form] = Form.useForm();

  //// post table getData
  const { data, loading, getRequest } = useFetch("/api/medicine");

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setEditPost(true);
    setAddPost(true);
    setIsModalVisible(true);
  };
  const handleAdd = async () => {
    setAddPost(true);
    setIsModalVisible(true);
  };

  const handleDelete = (record) => {
    // Perform  delete operation here
  };

  // form modal handlers
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      // values.field = "ourMedicine";
      // if (fileList.length === 0) {
      //   form.setFields([
      //     {
      //       name: "image",
      //       errors: ["Please upload an image!"],
      //     },
      //   ]);

      //   return;
      // }

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("image", file.originFileObj);
        }
      });

      // Perform the post request or other handling
      await upload(formData);

      setIsModalVisible(false);
    } catch (errorInfo) {}
  };

  useEffect(() => {
    getRequest();
  }, [createRes]);
  useEffect(() => {
    if (createLoading) {
      showToast(createLoading.message?.replace("AxiosError:", ""));
    }
    if (createRes) {
      createRes.msg && showToast(createRes.msg);
      !createRes.msg && showToast(createRes.status);
    }
    if (createLoading || createRes) resetUpload();
  }, [createLoading, createLoading, createRes]);

  const handleModalCancel = () => setIsModalVisible(false);

  const filteredData = useMemo(() => {
    return data?.medicines?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [data?.medicines, searchText, selectedColumn]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData?.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  /////////////////
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSeeMoreModalVisible, setIsSeeMoreModalModalVisible] =
    useState(false);

  const handleSeeMore = (record) => {
    setSelectedRecord(record);
    setIsSeeMoreModalModalVisible(true);
  };
  const renderWithTruncation = (field, maxLength, record) => {
    const isLongContent = record?.[field]?.length > maxLength;
    const content = isLongContent
      ? `${record?.[field]?.slice(0, maxLength)}...`
      : record?.[field];

    return <span>{content}</span>;
  };
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "MEDICINE NAME",
        key: "MEDICINE NAME",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["MEDICINE NAME"].toUpperCase()}
            </div>

            <div className="tableSubCols details">Type: {record["TYPE"]}</div>
            <div className="tableSubCols details">
              Frequency: {record["FREQUENCY"]}
            </div>
            <div className="tableSubCols details">
              STORAGE: {record["STORAGE"]}
            </div>
          </>
        ),
      },
    ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !["MEDICINE NAME", "TYPE", "FREQUENCY", "STORAGE"].includes(key)
    );
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0).toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title:
        key === "createdAt" || key === "updatedAt"
          ? editCols(key)
          : key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleSeeMore(record)}>
            See More
          </Button>
        </span>
      ),
    });

    const columnsWithEllipsis = [...commonColumns, ...dynamicColumns].map(
      (col) => ({
        ...col,
        ellipsis: true,
      })
    );
    return columnsWithEllipsis;
  };

  const customColumns = useMemo(() => generateColumns(data?.medicines), [data]);
  const columnOptionsSearch = [
    "NAME",
    "STORAGE",
    "PURPOSE",
    "FREQUENCY",
    "TYPE",
  ];
  const columnOptions = columnOptionsSearch?.map((col) => ({
    key: col,
    value: col,
  }));
  return (
    <div className="container-table">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
          ></Select>
        </Col>

        <Col flex="auto">
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => handleAdd()}
              icon={<PlusOutlined />}
            >
              Add Medicine
            </Button>
          </div>
        </Col>
      </Row>
      <DynamicTable
        data={paginatedData}
        tableActions={[]}
        loading={loading}
        customColumns={customColumns}
        scroll={{ x: 1200 }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: filteredData?.length,
          onChange: handlePageChange,
        }}
      />

      <Modal
        title="Add Medicine"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <NewMedicineForm
          form={form}
          onPreview={onPreview}
          onChange={onChange}
          fileList={fileList}
          handleModalOk={handleModalOk}
        />
      </Modal>
      <Modal
        title="Full Content"
        visible={isSeeMoreModalVisible}
        onCancel={() => setIsSeeMoreModalModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setIsSeeMoreModalModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          {selectedRecord &&
            Object.entries(selectedRecord).map(([key, value]) => (
              <p key={key}>
                <strong>{key}:</strong> {value}
              </p>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default AdminMedicineTable;
