import React, { useEffect, useState } from "react";
import "./PatientForm.css";
import { generalClinicalGuidanceFormData } from "../../../../services/data";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Checkbox,
  message,
  Button,
  Modal,
  Typography,
  Row,
  Col,
  Card,
  Layout,
  Select,
} from "antd";
import useFetch from "../../../../hooks/useFetch2";
import usePost from "../../../../hooks/usePost";

const { Footer } = Layout;
const { Title, Text, Paragraph } = Typography;

const PatientForm = ({
  bodyPart,
  setIsSymptomsModalOpen,
  resetCheckboxes,
  setCheckedStates,
  setSelectedData,
  selectedData,
  checkedStates,
  selectedGender,
  age,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showHistory, setShowHistory] = useState(false); // Track if PatientHistory should be displayed

  const [modalCheckedValues, setModalCheckedValues] = useState({});
  const [isSymptomModalOpen, setIsSymptomModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();

  const [createRes, setCreateRes] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);

  const { post, reset: resetPost } = usePost("/api/symptom", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });

  // Filtered data based on the selected body part
  const filteredData = generalClinicalGuidanceFormData.filter(
    (category) => category.symptomCategory === bodyPart
  );

  const saveDataToLocalStorage = (data) => {
    try {
      const jsonData = JSON.stringify(data);
      localStorage.setItem("patientFormData", jsonData);
      console.log("Data saved to local storage:", jsonData);
    } catch (error) {
      console.error("Error saving data to local storage:", error);
    }
  };

  const handleCheckboxChange = (e, symptom, data) => {
    const { checked } = e.target;

    if (checked) {
      if (!data || data.length === 0) {
        setCheckedStates((prevState) => ({
          ...prevState,
          [symptom]: true,
        }));

        const newSelection = {
          symptom: symptom,
          subSymptom: symptom,
          options: [{ name: symptom, selected: [] }],
        };

        setSelectedData((prevData) => [
          ...prevData.filter((item) => item.symptom !== symptom),
          newSelection,
        ]);

        // Add newEntry logic here
        const newEntry = {
          [symptom]: {
            options: [{ name: symptom, selected: [] }],
          },
        };

        setFormData((prevData) => ({
          ...prevData,
          ...newEntry,
        }));

        saveDataToLocalStorage(newSelection);
        setIsSymptomModalOpen(true);
      } else {
        setModalContent({ symptom, data: Array.isArray(data) ? data : [data] });
        setModalCheckedValues({});
        setIsOpen(true);
      }
    } else {
      setCheckedStates((prevState) => ({
        ...prevState,
        [symptom]: false,
      }));

      setSelectedData((prevData) =>
        prevData.filter((item) => item.symptom !== symptom)
      );

      // Remove the symptom from formData
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[symptom];
        return updatedData;
      });
    }
  };

  const handleCloseModal = () => {
    if (Object.values(modalCheckedValues).flat().length > 0) {
      setCheckedStates((prevState) => ({
        ...prevState,
        [modalContent.symptom]: true,
      }));

      const newSelection = {
        symptom: modalContent.symptom,
        options: Object.entries(modalCheckedValues).map(([key, values]) => ({
          name: key,
          selected: values,
        })),
      };

      setSelectedData((prevData) => [
        ...prevData.filter((item) => item.symptom !== modalContent.symptom),
        newSelection,
      ]);

      // Add newEntry logic here
      const newEntry = {
        [modalContent.symptom]: Object.entries(modalCheckedValues).map(
          ([key, values]) => ({
            name: key,
            selected: values,
          })
        ),
      };

      setFormData((prevData) => ({
        ...prevData,
        ...newEntry,
      }));

      saveDataToLocalStorage(newSelection);
    } else {
      setCheckedStates((prevState) => ({
        ...prevState,
        [modalContent.symptom]: false,
      }));

      // Remove the symptom from formData
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[modalContent.symptom];
        return updatedData;
      });
    }

    setIsSymptomModalOpen(true);
    setIsOpen(false);
  };

  const handleModalCheckboxChange = (groupName, checkedValues) => {
    setModalCheckedValues((prevState) => ({
      ...prevState,
      [groupName]: checkedValues,
    }));
  };

  useEffect(() => {
    console.log("Age:", age, "Gender:", selectedGender);
  }, [age, selectedGender]);

  const handleFormSubmit = async () => {
    try {
      const symptoms = Object.keys(formData).reduce((acc, key) => {
        acc[key] = formData[key].options; // Assuming each symptom has an 'options' field
        return acc;
      }, {});

      console.log("Symptoms:", symptoms);

      const requestData = {
        // ...formData,
        symptoms: symptoms,
        age: age, // Include age in the request
        gender: selectedGender, // Include gender in the request
      };
      console.log("request data", requestData);
      // Assuming formData is the data you want to submit
      await post(requestData); // Submit form data to the backend
      console.log("Form submitted successfully!", requestData);
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(error.message || "Failed to submit symptoms.");
    }
  };

  useEffect(() => {
    console.log(createError);

    if (createError?.status) {
      message[createError?.status && "error"]?.(
        createError?.message && "Error creating symptoms"
      );
    }
  }, [createError]);
  useEffect(() => {
    console.log("=====--------====", createRes);

    if (createRes?.status) {
      message[createRes?.status && "success"]?.(createRes?.message);
    }

    if (createRes?.status === "success") {
      setCheckedStates({});
      setModalCheckedValues({});
      setSelectedData([]);
      navigate("/app/patient_history", {
        state: { generalClinicalGuidanceFormData: formData },
      });
    }
  }, [createRes]);

  const handleCancel = () => {
    setCheckedStates({});
    setModalCheckedValues({});
    setSelectedData([]);
    setIsOpen(false);
    setIsSymptomModalOpen(false);
    if (resetCheckboxes) {
      resetCheckboxes();
    }
  };

  // Define a function to reset checkboxes in PatientForm
  const handleResetCheckboxes = () => {
    setCheckedStates({});
  };

  useEffect(() => {
    if (resetCheckboxes) {
      resetCheckboxes(handleResetCheckboxes);
    }
  }, [resetCheckboxes]);

  return (
    <>
      <Form
        layout="vertical"
        // onFinish={handleFormSubmit}
        // loading={notifyLoading}
        id="patientGeneralForm"
      >
        <Row gutter={[16, 16]}>
          {filteredData.map((categoryData, index) => (
            <Col key={index} span={24}>
              <Title level={4}>{categoryData.symptomCategory}</Title>
              {categoryData.data.map((item, idx) => (
                <Form.Item key={idx}>
                  <Checkbox
                    checked={checkedStates[item.name] || false}
                    onChange={(e) =>
                      handleCheckboxChange(e, item.name, item.options || [])
                    }
                  >
                    {item.name || "Unnamed Symptom"}
                  </Checkbox>
                </Form.Item>
              ))}
            </Col>
          ))}
        </Row>

        <Modal
          style={{ maxWidth: 400 }}
          title={
            <span style={{ fontSize: "18px", fontWeight: "semibold" }}>
              Symptom Details
            </span>
          }
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          onOk={handleCloseModal}
        >
          <Title level={5}>{modalContent.symptom}</Title>
          <Form layout="vertical">
            {modalContent.data &&
              modalContent.data.map((field, idx) => (
                <Form.Item
                  label={<span style={{ fontSize: "15px" }}>{field.name}</span>}
                  key={idx}
                >
                  {field.items.length === 1 && (
                    <Checkbox.Group
                      options={field.items}
                      value={modalCheckedValues[field.name] || []}
                      onChange={(checkedValues) =>
                        handleModalCheckboxChange(field.name, checkedValues)
                      }
                    />
                  )}
                  {field.items.length > 1 && (
                    <Select
                      mode="multiple"
                      options={field.items.map((item) => ({ value: item }))}
                      onChange={(checkedValues) =>
                        handleModalCheckboxChange(field.name, checkedValues)
                      }
                    />
                  )}
                </Form.Item>
              ))}
          </Form>
        </Modal>

        <Modal
          style={{ maxWidth: 400 }}
          title={
            <span style={{ fontSize: "18px", fontWeight: "semibold" }}>
              Selected Symptoms
            </span>
          }
          open={isSymptomModalOpen}
          onCancel={handleCancel}
          footer={[
            <Footer className="selected-symptoms-btn-container">
              <Button
                key="submit"
                type="primary"
                onClick={handleFormSubmit}
                loading={createLoading}
              >
                Submit
              </Button>
              <Form.Item className="right-btn-container">
                <Button
                  style={{ marginRight: "1rem" }}
                  htmlType="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="add-other-symptom"
                  type="primary"
                  onClick={() => {
                    setIsSymptomModalOpen(false);
                    setIsOpen(false);
                    setIsSymptomsModalOpen(false);
                  }}
                >
                  Add other symptom
                </Button>
              </Form.Item>
            </Footer>,
          ]}
        >
          {selectedData.length === 0 ? (
            <Paragraph>No symptoms selected yet.</Paragraph>
          ) : (
            selectedData.map((item, index) => (
              <div key={index}>
                <Title level={5}>{item.symptom}</Title>
                {item.options.map((option, idx) => (
                  <Paragraph key={idx}>
                    <Text style={{ fontSize: "15px", fontWeight: "600" }}>
                      -- {option.name ? `${option.name}:` : ""}
                    </Text>{" "}
                    {option.selected.join(", ")}
                  </Paragraph>
                ))}
              </div>
            ))
          )}
        </Modal>
      </Form>
    </>
  );
};

export default PatientForm;
