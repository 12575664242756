import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../common/footer/Footer.jsx";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
import { SearchDoctor } from "../doctor-list/DoctorList.jsx";
import ProfileImg from "../../../../image/service-img-5.png";
import useFetchUsers from "../../../../hooks/useFetchUsers";
import ErrorPage from "../../../common/error-page/ErrorPage";
import {
  FaCommentDots,
  FaThumbsUp,
  FaGem,
  FaCheckCircle,
} from "react-icons/fa";
import "../doctor-list/DoctorList.css";

const DoctorInfo = () => {
  const [medicalProfessionalsData, setMedicalProfessionalsData] = useState([]);
  const [medicalProfessionalsDataErr, setMedicalProfessionalsDataErr] =
    useState(false);
  const { data, loading, fetchUsers } = useFetchUsers("/adminApi/users");

  useEffect(() => {
    fetchUsers({ role: "doctor" });
  }, []);

  useEffect(() => {
    if (data) {
      setMedicalProfessionalsData(data);
    }
  }, [data]);
  useEffect(() => {
    console.log("---------[", data);
  }, [data]);

  const { id } = useParams();
  console.log(id);
  const selectedDoctor = medicalProfessionalsData?.find((doctor) =>
    console.log(doctor)
  );
  console.log("--", medicalProfessionalsData);
  console.log("------", selectedDoctor);

  return (
    <>
      <div id="doctorList">
        <div className="container">
          {medicalProfessionalsDataErr && <ErrorPage />}
          return (
          <section id="find-right-doctor" className="border-bottom">
            <article className="doctor-card card border container-grid-2-1">
              <div className="" style={{ margin: "auto" }}>
                <div className="circle-img">
                  <img
                    className="profile-image"
                    src={ProfileImg}
                    alt="Doctor Profile Image"
                  />
                </div>

                <div className="">
                  <h2 className="doctor-name">
                    <Link href=""> Dr. {selectedDoctor["FULL NAME"]}</Link>
                    <span>
                      {/* <span
                              className={
                                { active }
                                  ? "active-sign mx-2"
                                  : "inactive-sign mx-2"
                              }
                            ></span> */}
                    </span>
                  </h2>
                  <p className="doctor-specialty">General Physician</p>
                  <p className="doctor-experience">54 years experience</p>
                  <h3 className="patient-stories">
                    <span>
                      <FaCommentDots className="text-green" />
                    </span>
                    108 Patient Stories
                  </h3>
                </div>
              </div>

              <div className="doctor-detail-row">
                <div className="grid-2-1 border">
                  <div className="recommendation card-content">
                    <Link>
                      <span className="bg-green mini-box">
                        <FaThumbsUp className="text-light" /> 93%
                      </span>
                      Patient Recommendation
                    </Link>
                  </div>

                  <div className="excellence-rating card-content">
                    <Link>
                      <span className="bg-blue mini-box">
                        <FaGem className="text-light" /> 4.0
                      </span>
                      Hospital Excellence Rating
                    </Link>
                  </div>
                </div>

                <div className="card-content border">
                  <Link href="#">
                    Electronics City • Practo Hospitals - Neo
                  </Link>
                  <br />
                  <Link href="#">
                    <span className="text-green">Open</span> • Updated today
                  </Link>
                  <br />
                  <Link href="#">~ ₹700 Consultation Fees</Link> <br />
                </div>

                <div className="availability-info card-content border">
                  <h3 className="text-green">NEXT AVAILABLE AT</h3>

                  <div className="available-slots grid-2-1">
                    <div>
                      <small className="mx-1">11:30 AM, 29th Feb</small>
                      <Link href="#" className="btn  btn-contact">
                        Contct Clinic
                      </Link>
                    </div>

                    <div>
                      <small className="mx-1">11:30 AM, 29th Feb</small>
                      <Link href="#" className="btn  btn-book">
                        Book Clinic
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
          );
        </div>
        <Footer />
      </div>
    </>
  );
};
export default DoctorInfo;
