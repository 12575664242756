import React, { useEffect, useState } from "react";
import { InputNumber, Button, Select, Popconfirm } from "antd";

const { Option } = Select;

const SymptomForm = ({ onNext, formData, setFormData }) => {
  const [ageFilled, setAgeFilled] = useState(false);
  const [genderFilled, setGenderFilled] = useState(false);

  useEffect(() => {
    setAgeFilled(!!formData.age);
    setGenderFilled(!!formData.gender);
  }, [formData]);

  const handleAgeChange = (value) => {
    const isValidAge = value >= 1 && value <= 115; // Check valid age range
    setAgeFilled(!!isValidAge);
    setFormData({ ...formData, age: value });
  };

  const handleGenderSelect = (gender) => {
    if (gender === "female") {
      setFormData({ ...formData, gender, isPregnant: false });
    } else {
      setFormData({ ...formData, gender });
    }
  };

  const handlePregnancyStatus = (status) => {
    setFormData({ ...formData, isPregnant: status });
    onNext({ ...formData, isPregnant: status }); // Proceed to the next step
  };

  const handleNext = () => {
    if (formData.gender === "female" && formData.age > 13) {
      return;
    }
    onNext(formData);
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      {/* Age Input */}
      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "8px" }}>Age</label>
        <InputNumber
          placeholder="Enter your age"
          style={{ width: "70%" }}
          onChange={handleAgeChange}
          value={formData.age}
          min={1}
          max={115}
        />
      </div>

      {/* Gender Selection */}
      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "8px" }}>Sex</label>
        <Select
          placeholder="Select your gender"
          style={{ width: "70%" }}
          onChange={(value) => handleGenderSelect(value)}
          value={formData.gender}
          // disabled={!ageFilled}
        >
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
        </Select>
      </div>

      {formData.gender === "female" && formData.age > 13 ? (
        <Popconfirm
          placement="top"
          title="Are you pregnant?"
          onConfirm={() => handlePregnancyStatus(true)}
          onCancel={() => handlePregnancyStatus(false)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            disabled={!ageFilled || !genderFilled}
            style={{ marginTop: "20px" }}
          >
            Next
          </Button>
        </Popconfirm>
      ) : (
        <Button
          type="primary"
          disabled={!ageFilled || !genderFilled}
          style={{ marginTop: "20px" }}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default SymptomForm;
