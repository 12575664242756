import React, { useEffect, useState } from "react";
import { List, Typography, message, Modal, Button, Spin, Card } from "antd";
import VirtualList from "rc-virtual-list"; // Import VirtualList for scrolling
import useFetch from "../../hooks/useFetch2";

const { Title, Text } = Typography;
const ContainerHeight = 600;

const SymptomHistory = () => {
  const [getSymptomHistoryResp, setGetSymptomHistoryResp] = useState([]);
  const [symptomHistoryLoading, setSymptomHistoryLoading] = useState(false);
  const [symptomHistoryError, setSymptomHistoryError] = useState(null);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [selectedSymptomItem, setSelectedSymptomItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { get } = useFetch(`/api/symptoms/`, {
    setData: setGetSymptomHistoryResp,
    setLoading: setSymptomHistoryLoading,
    setError: setSymptomHistoryError,
  });

  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    if (symptomHistoryError) {
      message.error(symptomHistoryError); // Display error message
    }
  }, [symptomHistoryError]);
  const appendData = () => {
    // Fetch additional data for lazy loading
    if (!symptomHistoryLoading) {
      get();
    }
  };

  const onScroll = (e) => {
    // Detect if the user has scrolled to the bottom
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          ContainerHeight
      ) <= 1
    ) {
      appendData();
    }
  };

  const handleItemClick = (symptomData) => {
    console.log(symptomData);

    setSelectedSymptom(symptomData);
    if (symptomData && symptomData.symptoms)
      setSelectedSymptomItem(JSON.parse(symptomData.symptoms));
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedSymptom(null);
  };

  if (symptomHistoryLoading && getSymptomHistoryResp.length === 0) {
    return <Spin size="large" />;
  }

  return (
    <Card style={{ padding: 10, maxWidth: 500, margin: "auto" }}>
      {getSymptomHistoryResp?.data?.length === 0 && (
        <Title level={3} style={{ margin: 40, textAlign: "center" }}>
          You have no symptom record before
        </Title>
      )}
      {getSymptomHistoryResp?.data?.length > 0 && (
        <>
          <Title level={4}>Symptom History</Title>
          <List>
            <VirtualList
              data={getSymptomHistoryResp?.data?.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt) // Sort by date
              )}
              height={ContainerHeight}
              itemHeight={47}
              itemKey={(item) => item.createdAt}
              onScroll={onScroll} // Handle scrolling
            >
              {(item) => {
                const symptoms = JSON.parse(item.symptoms);
                const bodyPart =
                  symptoms.selectedSymptomsDetails[0]?.bodyPart || "N/A";
                const date = new Date(item.createdAt).toLocaleDateString();

                return (
                  <List.Item
                    onClick={() => handleItemClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <List.Item.Meta
                      title={`Body Part: ${bodyPart}`}
                      description={`Date: ${date}`}
                    />
                  </List.Item>
                );
              }}
            </VirtualList>
          </List>
        </>
      )}

      {/* Modal to display detailed information */}
      <Modal
        title="Symptom Details"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedSymptom && (
          <div>
            {selectedSymptomItem?.selectedGeneralSymptoms?.length > 0 && (
              <>
                <Title level={5}>General Symptoms</Title>
                <ul>
                  {selectedSymptomItem?.selectedGeneralSymptoms?.map(
                    (generalSymptom, index) => (
                      <li key={index}>
                        <strong>-</strong> {generalSymptom}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
            <br />
            <Title level={5}>Selected Symptoms</Title>
            <ul>
              {selectedSymptomItem?.selectedSymptomsDetails.map(
                (symptom, index, array) => {
                  const isFirstOccurrence =
                    index === 0 ||
                    symptom.bodyPart !== array[index - 1].bodyPart;

                  return (
                    <div key={`${symptom.bodyPart}-${symptom.name}`}>
                      {isFirstOccurrence && (
                        <li>
                          <strong>Body Part:</strong> {symptom.bodyPart}
                        </li>
                      )}
                      <li>
                        <strong>Symptom:</strong> {symptom.name}
                        {symptom.option && symptom.option.length > 0 && (
                          <ul>
                            {symptom.option.map((opt) =>
                              opt.items && opt.items.length > 0 ? (
                                <li key={opt.name}>
                                  <strong>Option:</strong> {opt.name}
                                  <ul>
                                    {opt.items.map((item) => (
                                      <li key={item}>
                                        <strong>Item:</strong> {item}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ) : null
                            )}
                          </ul>
                        )}
                      </li>
                    </div>
                  );
                }
              )}
            </ul>

            {selectedSymptomItem?.additionalInfo &&
              Object.values(selectedSymptomItem?.additionalInfo).some(
                (value) => value
              ) && (
                <div>
                  <br />
                  <Title level={5}>Additional Information</Title>
                  <ul>
                    {selectedSymptomItem.additionalInfo?.duration && (
                      <li>
                        <strong>Duration of Symptoms:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.duration}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo?.medicalConditions && (
                      <li>
                        <strong>Previous Medical Conditions:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.medicalConditions}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo?.medications && (
                      <li>
                        <strong>Current Medications:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.medications}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo?.additionalSymptoms && (
                      <li>
                        <strong>Additional Symptoms:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.additionalSymptoms}
                      </li>
                    )}
                  </ul>
                </div>
              )}
          </div>
        )}
      </Modal>
    </Card>
  );
};

export default SymptomHistory;
