import React from "react";
import { Checkbox, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";

const { Title, Text, Paragraph } = Typography;

const TermOfService = ({ onNext, formData, setFormData }) => {
  const navigate = useNavigate();
  const handleAcceptTerms = (e) => {
    setFormData({ ...formData, isAccepted: e.target.checked });
  };
  const handleShowHistory = () => {
    navigate("/app/symptom-history");
  };
  return (
    <div>
      <Title level={4} style={{ textAlign: "center" }}>
        Symptom Guidance
      </Title>
      <Paragraph>
        <Text strong>Check your symptoms</Text>
        <br />
        Take a short symptom assessment to easily assist you on:
        <ul>
          <li>1. Recommendation of the right health center</li>
          <li>2. Recommendation of the right consultant</li>
        </ul>
      </Paragraph>

      <Title level={5} style={{ textAlign: "center" }}>
        Terms of Service
      </Title>
      <Paragraph>
        Before using the symptom guidance, please accept the terms of service.
      </Paragraph>
      <ol>
        <li>
          <Text strong> - Do not use it in case of emergency:</Text> Call our
          short call service in the case of an emergency health issue.
        </li>
        <li>
          <Text strong>- This is not used for diagnosis or consultation:</Text>{" "}
          This form is not used to treat or consult you about your health issue
          but only to guide you to the right health center and doctor.
        </li>
        <li>
          <Text strong>- Your data is safe:</Text> The information you provide
          won’t be shared or used to identify you.
        </li>
      </ol>

      <Checkbox
        onChange={handleAcceptTerms}
        checked={formData.isAccepted}
        style={{ marginTop: "20px" }}
      >
        I have read and accept the terms of service
      </Checkbox>

      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          type="primary"
          disabled={!formData.isAccepted}
          onClick={() => onNext({})}
        >
          Next
        </Button>
      </div>
      <div style={{ textAlign: "left" }}>
        <Button type="primary" onClick={handleShowHistory}>
          My Previous history
        </Button>
      </div>
    </div>
  );
};

export default TermOfService;
