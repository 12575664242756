import React, { useState, useEffect } from "react";
import { Form, Button, message, Card } from "antd";
import "react-phone-number-input/style.css";

import useFetch from "../../../hooks/useFetch2";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import Verification from "../verification/PhoneVerification";
import { useSession } from "../../../contexts/sessionData";
import { useNavigate } from "react-router-dom";
import useToast from "../toast/toast";

const PhoneAuth = () => {
  const [authForm] = Form.useForm();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [codeSent, setCodeSent] = useState(false);

  const [codeLoading, setCodeLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [codeRes, setCodeRes] = useState(null);
  const { authenticated, setAuthenticated } = useSession(false);

  // Get the code from the server
  const { get: getCode, reset: resetGetCode } = useFetch("/auth/get-code", {
    setLoading: setCodeLoading,
    setError: setCodeError,
    setData: setCodeRes,
  });

  // Load phone number from localStorage on component mount
  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phone");
    if (storedPhoneNumber) {
      authForm.setFieldValue("phone", storedPhoneNumber);
    }
    console.log(storedPhoneNumber);
  }, []);

  useEffect(() => {
    if (codeRes?.status)
      message[codeRes?.status](codeRes?.message || codeRes?.status);

    if (codeRes?.status === "success") {
      setCodeSent(true);
    }
    resetGetCode();
  }, [codeRes]);

  useEffect(() => {
    if (codeError?.status)
      message[codeError?.status](codeError?.message || codeError?.status);

    if (codeError?.status === "error") {
      setCodeSent(false);
    }
    resetGetCode();
  }, [codeError]);

  const handleGetCode = async () => {
    try {
      const { phone } = await authForm.validateFields(["phone"]);

      if (!phone) return message.error("Please enter your phone number");

      localStorage.setItem("phone", phone);

      getCode({ phone });
    } catch (error) {
      console.log(error);
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  return (
    <>
      <div
        id="Phone-auth"
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          marginTop: "150px",
          // display: "flex",
        }}
      >
        <div
          className="container"
          style={{
            justifyContent: "center",
          }}
        >
          {!codeSent && (
            <Card
              style={{
                maxWidth: 500,
                margin: "auto",

                padding: 20,
                borderRadius: 12,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: 30,
                  color: "#3f51b5",
                }}
              >
                Welcome! Verify Your Phone
              </h2>
              <Form layout="vertical" form={authForm}>
                <PhoneNumberInput form={authForm} />
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={codeLoading}
                    onClick={handleGetCode}
                    icon={<i className="fas fa-paper-plane" />}
                  >
                    Send Verification Code
                  </Button>
                </Form.Item>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    color: "#3f51b5",
                  }}
                >
                  Need Help? Call{" "}
                  <a
                    href="tel:9080"
                    style={{ color: "#1890ff", fontWeight: "bold" }}
                  >
                    <i className="fas fa-phone" style={{ marginRight: 5 }} />
                    9080
                  </a>
                </div>
              </Form>
            </Card>
          )}
          {codeSent && <Verification />}
        </div>
      </div>
    </>
  );
};

export default PhoneAuth;
