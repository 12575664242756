import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch.js";
import "./Payment.css";

const PaymentMethod = ({ name, icon, isSelected, onClick }) => (
  <button
    className={`payment-method ${isSelected ? "selected" : ""}`}
    onClick={onClick}
  >
    <img src={icon} alt={name} />
    {name}
  </button>
);
function Payment() {
  const [notificationList, setNotificationList] = useState([]);
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);
  const { id } = useParams();
  const selectedMedicine = notificationList?.find(
    (medicine) => medicine?.id == id
  );
  const selectedMaterial = diagnosticMaterialData?.find(
    (material) => material?.id == id
  );

  const navigate = useNavigate();

  const [selectedService, setSelectedService] = useState("");

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false); //for the popup message
  const [selectedMethod, setSelectedMethod] = useState("");

  const handleMethodSelect = (method) => setSelectedMethod(method);

  const handleAddToCartClick = () => {
    // Add code to actually add the item to the cart (not shown here)
    setShowConfirmationPopup(true); // Show the confirmation popup
  };

  const handleClosePopup = () => {
    setShowConfirmationPopup(false); // Hide the confirmation popup
  };

  const handleConfirmAddToCart = () => {
    const selectedProduct = "diagnosis";
    switch (selectedProduct) {
      case "diagnosis":
        navigate(`/materialFullInformation/${selectedMaterial?.id}`);

        break;
      case "medicine":
        navigate(`/medicineFullInformation/${selectedMedicine?.id}`);

        break;
      case "doctor":
        navigate("/doc");
        break;
      default:
        console.error("Unexpected service selected");
    }

    setShowConfirmationPopup(false); // Hide the confirmation popup after confirmation
  };

  return (
    <>
      <div id="payment">
        <div className="payment-container">
          <h2>Payment Method</h2>
          <div className="payment-methods">
            <PaymentMethod
              name="Telebirr"
              icon="../../images/telebirr.png"
              isSelected={selectedMethod === "telebirr"}
              onClick={() => handleMethodSelect("telebirr")}
            />
            <PaymentMethod
              name="Awashbirr"
              icon="../../images/awashBirr.png"
              isSelected={selectedMethod === "awashbirr"}
              onClick={() => handleMethodSelect("awashbirr")}
            />
            <PaymentMethod
              name="CBE Birr"
              icon="../../images/cbe.png"
              isSelected={selectedMethod === "cbebebirr"}
              onClick={() => handleMethodSelect("cbebebirr")}
            />
            <PaymentMethod
              name="Wegagen Bank"
              icon="../../images/wegagenBank.png"
              isSelected={selectedMethod === "wegagenbank"}
              onClick={() => handleMethodSelect("wegagenbank")}
            />
          </div>
          {/* Conditionally render payment details based on selected method */}
          {selectedMethod === "telebirr" && (
            <div className="payment-details">
              {/* Telebirr specific details */}
              <p>Enter your Telebirr phone number:</p>
              <input type="tel" placeholder="09xxxxxxxxxx" />
            </div>
          )}
          {selectedMethod === "awashbirr" && (
            <div className="payment-details">
              {/* Awashbirr specific details */}
              <p>Enter your Awashbirr account number:</p>
              <input type="text" placeholder="Your Awashbirr Account Number" />
            </div>
          )}
          {selectedMethod === "cbebebirr" && (
            <div className="payment-details">
              {/* Awashbirr specific details */}
              <p>Enter your CBE account number:</p>
              <input type="text" placeholder="Your Awashbirr Account Number" />
            </div>
          )}
          {selectedMethod === "wegagenbank" && (
            <div className="payment-details">
              {/* Awashbirr specific details */}
              <p>Enter your wegagen Bank account number:</p>
              <input type="text" placeholder="Your Awashbirr Account Number" />
            </div>
          )}
          <div>
            {/* <Link
          to={`/medicineFullInformation/${selectedMedicine?.id || ""}`}
          key={cartItems?.id}
          className={!selectedMethod ? "disabled-link" : ""}
          id="pay-link"
          >
          Pay Now
        </Link>  */}

            <div className="popup">
              <button
                id="pay-button"
                className={!selectedMethod ? "disabled-link" : ""}
                onClick={handleAddToCartClick}
              >
                Pay Now
              </button>
              {showConfirmationPopup && (
                <div id="confirmation-popup">
                  <div className="popup-container">
                    <h3 className="popup-title">Are you sure?</h3>

                    <div className="button-container">
                      <button
                        id="confirm-button"
                        className="primary-button"
                        onClick={handleConfirmAddToCart}
                      >
                        Yes
                      </button>
                      <button
                        id="cancel-button"
                        className="secondary-button"
                        onClick={handleClosePopup}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <button type="submit" disabled={!selectedMethod}>
          Pay Now
        </button> */}
        </div>
      </div>
    </>
  );
}

export default Payment;
