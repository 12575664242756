import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Form,
  Select,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../../admin/tables/DynamicTable";
import AdminUpdate from "../../admin/registration-forms/AdminUpdateFormContainer";
import usePost from "../../../hooks/usePost";
import usePatch from "../../../hooks/usePatch";
import useToast from "../toast/toast";
import useFetch from "../../../hooks/useFetch";
import useUpload from "../../../hooks/useUpload";
import PostsForm from "../post-form/PostForm";
import { statusOptions } from "../../../services/data";
const { Option } = Select;
const PostTable = ({ postOptionData }) => {
  const { showToast } = useToast();
  const [searchText, setSearchText] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("TITLE");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedField, setSelectedField] = useState(postOptionData[0]?.key);
  const [updateRes, setUpdateRes] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSeeMoreModalVisible, setIsSeeMoreModalModalVisible] =
    useState(false);
  //// form modal state
  const [fileList, setFileList] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [addPost, setAddPost] = useState(false);
  //upload
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  //// form modal upload
  const { upload, reset: resetUpload } = useUpload("/api/homePagePost", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });
  //
  const [form] = Form.useForm();
  ////// form modal update
  const { post: updatePost, reset: resetPost } = usePost("/api/homePagePost", {
    setData: setUpdateRes,
    setLoading: setUpdateLoading,
    setError: setUpdateError,
  });
  const { data, error, loading, getRequest } = useFetch("/api/homePagePost");

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);
  const handleFieldChange = (value) => setSelectedField(value);

  const handleAdd = async () => {
    setAddPost(true);
    setIsModalVisible(true);
  };

  // form modal handlers
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();

      // if (fileList.length === 0) {
      //   form.setFields([
      //     {
      //       name: "image",
      //       errors: ["Please upload an image!"],
      //     },
      //   ]);
      //   return;
      // }

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("image", file.originFileObj);
        }
      });

      // Perform the post request
      await upload(formData);

      setIsModalVisible(false);
    } catch (errorInfo) {
      console.log("error info", errorInfo);
    }
  };
  // useEffect(() => {
  //   if (updateError) {
  //     showToast(updateError.message?.replace("AxiosError:", ""));
  //   }
  //   if (updateRes) showToast(updateRes.status);

  //   if (updateError || updateRes) resetPost();
  // }, [updateLoading, updateError, updateRes]);
  useEffect(() => {
    if (createError) {
      showToast(createError.message?.replace("AxiosError:", ""));
    }
    if (createRes) {
      createRes.msg && showToast(createRes.msg);
      !createRes.msg && showToast(createRes.status);
    }
    if (createError || createRes) resetPost();
  }, [createLoading, createError, createRes]);
  useEffect(() => {
    if (selectedField) getRequest({ field: selectedField });
  }, [selectedField, createRes]);

  const handleModalCancel = () => setIsModalVisible(false);

  let filteredData = useMemo(() => {
    return data?.homePagePosts?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [data, searchText, selectedColumn, selectedField]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData?.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSeeMore = (record) => {
    setSelectedRecord(record);
    setIsSeeMoreModalModalVisible(true);
  };

  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [];
    // [
    //   {
    //     title: "TITLE",
    //     key: "title",
    //     render: (text, record) => renderWithTruncation("TITLE", 15, record),
    //   },
    //   {
    //     title: "CONTENT",
    //     key: "content",
    //     render: (text, record) => renderWithTruncation("CONTENT", 10, record),
    //   },
    // ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !["FIELD"].includes(key)
    );
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0).toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title:
        key === "createdAt" || key === "updatedAt"
          ? editCols(key)
          : key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleSeeMore(record)}>
            See More
          </Button>
        </span>
      ),
    });
    const columnsWithEllipsis = [...commonColumns, ...dynamicColumns].map(
      (col) => ({
        ...col,
        ellipsis: true,
      })
    );
    return columnsWithEllipsis;
  };
  const customColumns = useMemo(
    () => generateColumns(data?.homePagePosts),
    [data]
  );
  const columnOptionsSearch = ["TITLE", "CONTENT", "STATUS"];
  const columnOptions = columnOptionsSearch?.map((col) => ({
    key: col,
    value: col,
  }));
  return (
    <div className="container-table">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Field"
            value={selectedField}
            onChange={handleFieldChange}
            style={{ width: 200 }}
            allowClear
          >
            {postOptionData.map((option, i) => (
              <Option key={i} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>

        <Col flex="auto">
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => handleAdd()}
              icon={<PlusOutlined />}
            >
              Add Post
            </Button>
          </div>
        </Col>
      </Row>
      <DynamicTable
        data={paginatedData}
        tableActions={[]}
        loading={loading}
        customColumns={customColumns}
        scroll={{ x: 1000 }}
      />

      <Modal
        title="Add Post"
        visible={isModalVisible && selectedField}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <PostsForm
          field={selectedField}
          form={form}
          onPreview={onPreview}
          onChange={onChange}
          fileList={fileList}
          handleModalOk={handleModalOk}
          postOptionData={postOptionData}
        />
      </Modal>
      <Modal
        title="Full Content"
        visible={isSeeMoreModalVisible}
        onCancel={() => setIsSeeMoreModalModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setIsSeeMoreModalModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          {selectedRecord &&
            Object.entries(selectedRecord).map(([key, value]) => (
              <p key={key}>
                <strong>{key}:</strong> {value}
              </p>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default PostTable;
