import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  Rate,
  Avatar,
  Space,
  Typography,
  Divider,
} from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import useFetch from "../../../hooks/useFetch";

const { Text, Title } = Typography;

const cardData = [
  // {
  //   id: 1,
  //   name: "Dr. John Doe",
  //   image: "../../../../images/adult_doctor.png",
  //   online: true,
  //   location: "New York, USA",
  //   specialty: "Cardiologist",
  //   rating: 4.5,
  // },
  // {
  //   id: 2,
  //   name: "Dr. Jane Smith",
  //   image: "../../../../images/adult_doctor.png",
  //   online: false,
  //   location: "San Francisco, USA",
  //   specialty: "Dermatologist",
  //   rating: 4.0,
  // },
  // {
  //   name: "Dr. John Doe",
  //   specialty: "Cardiologist",
  //   availability: {
  //     Monday: {
  //       period: "full",
  //       times: [
  //         "9:00 AM",
  //         "10:00 AM",
  //         "11:00 AM",
  //         "1:00 PM",
  //         "2:00 PM",
  //         "3:00 PM",
  //         "5:00 PM",
  //         "6:00 PM",
  //         "7:00 PM",
  //       ],
  //     },
  //     Tuesday: { period: "half", times: ["9:00 AM", "10:00 AM", "11:00 AM"] },
  //     Wednesday: { period: "rest", times: [] },
  //     Thursday: { period: "half", times: ["9:00 AM", "10:00 AM", "11:00 AM"] },
  //     Friday: { period: "half", times: ["10:00 PM", "11:00 PM"] },
  //     Saturday: { period: "half", times: ["9:00 AM", "10:00 AM", "11:00 AM"] },
  //     Sunday: { period: "rest", times: [] },
  //   },
  //   bookedAppointments: {
  //     "2024-11-10": ["9:00 AM", "2:00 PM"],
  //     // other dates with booked times
  //   },
  // },
  {
    image: "../../../../images/adult_doctor.png",
    name: "Dr. John Doe",
    specialty: "Cardiologist",
    availability: {
      Monday: {
        period: "full",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "2:00 PM",
          },
          afternoon: {
            start: "1:00 PM",
            end: "4:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
      Tuesday: {
        period: "half",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
      Wednesday: {
        period: "rest",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
      Thursday: {
        period: "half",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
      Friday: {
        period: "half",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "10:00 PM",
            end: "12:00 AM",
          },
        },
      },
      Saturday: {
        period: "half",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
      Sunday: {
        period: "rest",
        timeShift: {
          morning: {
            start: "9:00 AM",
            end: "12:00 PM",
          },
          afternoon: {
            start: "12:00 PM",
            end: "3:00 PM",
          },
          evening: {
            start: "5:00 PM",
            end: "8:00 PM",
          },
        },
      },
    },
    bookedAppointments: {
      "2024-11-10": ["9:00 AM", "2:00 PM"],
      // other dates with booked times
    },
  },
];

const Booking = ({ onCardClick }) => {
  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {cardData.map((card, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card
              bordered={false}
              style={{
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() => onCardClick(card)}
            >
              <Avatar src={card.image} size={80} style={{ marginBottom: 10 }} />
              <Title level={5}>{card.name}</Title>
              <Badge
                status={card.online ? "success" : "default"}
                text={card.online ? "Online" : "Offline"}
              />
              <Text type="secondary">
                <EnvironmentOutlined /> {card.location}
              </Text>
              <Text>{card.specialty}</Text>
              <Divider />
              <div style={{ textAlign: "center" }}>
                <Rate allowHalf defaultValue={card.rating} disabled />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Booking;
