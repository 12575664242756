import React from "react";
import PostTable from "../../../common/post-table/postTable";
const AdminDrArticleTable = () => {
  const postOptionData = [
    { key: "article", value: "article", label: "Article" },
  ];

  return <PostTable postOptionData={postOptionData} />;
};

export default AdminDrArticleTable;
