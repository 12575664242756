import { Menu, Button } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from "../../../image/logo.png";
import { useAdminAccAuth } from "../../../contexts/AdminAccountAuth";

function Sidenav({ color }) {
  const navigate = useNavigate();
  const { authAdmin, setAuthAdmin } = useAdminAccAuth();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const handleLogout = () => {
    localStorage.setItem("adminAccessToken", "");
    setAuthAdmin({ authenticated: false });
    navigate("/admin");
  };

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" style={{ width: 70, objectFit: "contain" }} />
        <span className="text-dark">YESMED ADMIN</span>
      </div>
      <hr />

      <Menu theme="light" mode="inline">
        {authAdmin.authenticated && (
          <>
            <Menu.Item key="1">
              <NavLink to="/admin/dashboard">
                <span className="label">Dashboard</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="16">
              <NavLink to="/admin/post">
                <span className="label">Post</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="11">
              <NavLink to="/admin/dr_articles">
                <span className="label">Dr Articles</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="12">
              <NavLink to="/admin/service">
                <span className="label">Services</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="13">
              <NavLink to="/admin/medicines">
                <span className="label">Medicines</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink to="/admin/users">
                <span className="label">Users</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
              <NavLink to="/admin/symptomRequest">
                <span className="label">Symptom Request</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink to="/billing">
                <span className="label">Billing</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item className="menu-item-header" key="5">
              Account Pages
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/profile">
                <span className="label">Profile</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="8">
              <NavLink to="/admin/register">
                <span className="label">Register</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="9" onClick={handleLogout}>
              <NavLink>
                <span className="label">Logout</span>
              </NavLink>
            </Menu.Item>
          </>
        )}
        {!authAdmin.authenticated && (
          <Menu.Item key="10">
            <NavLink to="/admin/login">
              <span className="label">Login</span>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
      <div className="aside-footer"></div>
    </>
  );
}

export default Sidenav;
