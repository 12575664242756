import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Form,
  Select,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import AdminUpdate from "../../registration-forms/AdminUpdateFormContainer";
import usePost from "../../../../hooks/usePost";
import useToast from "../../../common/toast/toast";
import useFetchUsers from "../../../../hooks/useFetchUsers";
import { Link } from "react-router-dom";
import {
  diagnosticColumnData,
  hospitalColumnData,
  pharmacyColumnData,
  doctorColumnData,
  patientColumnData,
  staffColumnData,
  excludedOrganizationsColumnData,
  excludedDoctorData,
  excludedStaffData,
  organizationsOptionsSearchData,
  doctorOptionsSearchData,
  staffOptionsSearchData,
} from "../../../../services/data";
const { Option } = Select;

const UserTable = () => {
  const { showToast } = useToast();

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("PHONE");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState("doctor");
  const [updateRes, setUpdateRes] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSeeMoreModalVisible, setIsSeeMoreModalModalVisible] =
    useState(false);
  const [form] = Form.useForm();
  const { post: updateUser, reset: resetPost } = usePost("/adminApi/update", {
    setData: setUpdateRes,
    setLoading: setUpdateLoading,
    setError: setUpdateError,
  });

  const { data, loading, fetchUsers } = useFetchUsers("/adminApi/users");

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);
  const handleRoleChange = (value) => setSelectedRole(value);

  const handleEdit = (record) => {
    setSelectedRole(record.role);
    setEditingUser(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  const handleSeeMore = (record) => {
    setSelectedRecord(record);
    setIsSeeMoreModalModalVisible(true);
  };
  const handleDelete = (record) => {
    // Perform delete operation here
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await updateUser(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (updateError) {
      showToast(updateError.message?.replace("AxiosError:", ""));
    }
    if (updateRes) showToast(updateRes.status);

    if (updateError || updateRes) resetPost();
  }, [updateLoading, updateError, updateRes]);

  useEffect(() => {
    if (selectedRole) fetchUsers({ role: selectedRole });
  }, [selectedRole]);

  const handleModalCancel = () => setIsModalVisible(false);

  const filteredData = useMemo(() => {
    return data
      ?.filter((item) => {
        if (!selectedColumn || !searchText) return true;
        return item[selectedColumn]
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase());
      })
      ?.filter((item) => item.ROLE === selectedRole);
  }, [data, searchText, selectedColumn, selectedRole]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData?.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  let selectedColumnData;
  let excludedColumnData;
  let selectedColumnOptionsSearch;
  switch (selectedRole) {
    case "hospital":
      selectedColumnData = hospitalColumnData;
      excludedColumnData = excludedOrganizationsColumnData;
      selectedColumnOptionsSearch = organizationsOptionsSearchData;
      break;
    case "pharmacy":
      selectedColumnData = pharmacyColumnData;
      excludedColumnData = excludedOrganizationsColumnData;
      selectedColumnOptionsSearch = organizationsOptionsSearchData;

      break;
    case "diagnostic_center":
      selectedColumnData = diagnosticColumnData;
      excludedColumnData = excludedOrganizationsColumnData;
      selectedColumnOptionsSearch = organizationsOptionsSearchData;

      break;
    case "doctor":
      selectedColumnData = doctorColumnData;
      excludedColumnData = excludedDoctorData;
      selectedColumnOptionsSearch = doctorOptionsSearchData;

      break;
    case "staff":
      selectedColumnData = staffColumnData;
      excludedColumnData = excludedStaffData;
      selectedColumnOptionsSearch = staffOptionsSearchData;

      break;
    case "patient":
      selectedColumnData = patientColumnData;
      excludedColumnData = excludedStaffData;
      selectedColumnOptionsSearch = staffOptionsSearchData;

      break;

    default:
      selectedColumnData = patientColumnData;
      excludedColumnData = excludedStaffData;
      selectedColumnOptionsSearch = staffOptionsSearchData;
  }

  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = selectedColumnData;

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !excludedColumnData.includes(key)
    );
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0)?.toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title:
        key === "createdAt" || key === "updatedAt"
          ? editCols(key)
          : key.charAt(0)?.toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleSeeMore(record)}>
            See More
          </Button>
        </span>
      ),
    });

    const columnsWithEllipsis = [...commonColumns, ...dynamicColumns].map(
      (col) => ({
        ...col,
        ellipsis: true,
      })
    );
    return columnsWithEllipsis;
  };

  const customColumns = useMemo(() => generateColumns(data), [data]);
  const columnOptionsSearch = selectedColumnOptionsSearch;

  const columnOptions = columnOptionsSearch?.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div className="container-table">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Role"
            value={selectedRole}
            onChange={handleRoleChange}
            style={{ width: 200 }}
            allowClear
          >
            <Option key="staff" value="staff">
              Staff
            </Option>
            <Option key="patient" value="patient">
              Patient
            </Option>
            <Option key="doctor" value="doctor">
              Doctor
            </Option>
            <Option key="hospital" value="hospital">
              Hospital
            </Option>
            <Option key="pharmacy" value="pharmacy">
              Pharmacy
            </Option>
            <Option key="diagnostic_center" value="diagnostic_center">
              Diagnostic
            </Option>
          </Select>
        </Col>

        <Col flex="auto">
          <div style={{ textAlign: "right" }}>
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to="/admin/register"> Add User</Link>
            </Button>
          </div>
        </Col>
      </Row>
      <DynamicTable
        data={paginatedData}
        tableActions={[]}
        loading={loading}
        customColumns={customColumns}
        scroll={{ x: 500 }}
      />

      <Modal
        title="Add User"
        visible={isModalVisible && selectedRole}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <AdminUpdate role={selectedRole} form={form} />
      </Modal>
      <Modal
        title="Full Content"
        visible={isSeeMoreModalVisible}
        onCancel={() => setIsSeeMoreModalModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setIsSeeMoreModalModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          {selectedRecord &&
            Object.entries(selectedRecord).map(([key, value]) => (
              <p key={key}>
                <strong>{key}:</strong> {value}
              </p>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default UserTable;
