import React, { useState } from "react";
import { Card, Typography, Space, Button, Divider, Radio } from "antd";
import {
  AudioOutlined,
  VideoCameraOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import BookingCalendar from "../booking-calendar/BookingCalendar";

const { Title, Text } = Typography;

const AppointmentDetails = ({ doctor, onBack, onConfirm }) => {
  const { name } = doctor;
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleAppointmentSelect = (e) => {
    setSelectedAppointment(e.target.value);
  };

  const handleConfirm = () => {
    if (selectedAppointment) {
      onConfirm(selectedAppointment); // Pass the selected appointment to the next page
    }
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={3}>Book Appointment with {name}</Title>
          <Divider />
          <Radio.Group
            onChange={handleAppointmentSelect}
            value={selectedAppointment}
          >
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Radio value="audio">
                <Text>
                  <AudioOutlined /> Audio Appointment - $50
                </Text>
              </Radio>
              <Radio value="video">
                <Text>
                  <VideoCameraOutlined /> Video Appointment - $75
                </Text>
              </Radio>
              <Radio value="personal">
                <Text>
                  <HomeOutlined /> Personal Appointment - $120
                </Text>
              </Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Button
            type="primary"
            block
            disabled={!selectedAppointment}
            onClick={handleConfirm}
            style={{
              opacity: selectedAppointment ? 1 : 0.7,
              transition: "opacity 0.3s",
            }}
          >
            Confirm Booking
          </Button>
          <Button type="link" onClick={onBack} block>
            Back to Doctor Details
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default AppointmentDetails;
