import { useState } from "react";
import { postApi } from "../services/api";
import { useSpinner } from "../contexts/spinnerContext.js";

const usePost = (endpoint, prop) => {
  const { showSpinner, hideSpinner } = useSpinner();

  const post = async (postValue) => {
    prop.setLoading(true);
    showSpinner();
    try {
      console.log("posting");
      const result = await postApi(endpoint, postValue);
      console.log("result =-=-=-=-=--=-=--=-=-", result);
      if (result?.status === "success" && result?.redirect)
        prop?.setRedirect && prop?.setRedirect(result.redirect);

      prop?.setData(result);
    } catch (err) {
      console.error(err);
      if (err.name === "TypeError" || err.name === "SyntaxError")
        err.message = "System Error";
      prop?.setError(err);
    } finally {
      hideSpinner();
    }
  };

  const reset = () => {
    prop.setLoading(false);
    prop.setData(null);
    prop.setError(false);
  };

  return { reset, post };
};

export default usePost;
