/* import React, { useState } from "react";
import { Card, Calendar, Radio, Space, Button, Typography, Badge } from "antd";
import {
  ClockCircleOutlined,
  CoffeeOutlined,
  MoonOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const BookingCalendar = ({ appointmentType, doctor, onConfirm, onBack }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const today = moment();

  // Handle date selection
  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setSelectedTimePeriod(null); // Reset time period and time
    setSelectedTime(null);
  };

  // Handle time period change (morning, afternoon, evening)
  const handleTimePeriodChange = (e) => {
    setSelectedTimePeriod(e.target.value);
    setSelectedTime(null); // Reset selected time
  };

  // Handle time slot selection
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  // Determine available time periods for the selected date
  const getAvailableTimesForDay = () => {
    if (!selectedDate) return [];

    const dayOfWeek = moment(selectedDate).format("dddd");
    const availability = doctor.availability[dayOfWeek];

    if (!availability || availability.period === "rest") return []; // Rest day

    // Remove already booked appointments
    const bookedTimes = doctor.bookedAppointments[selectedDate] || [];
    return availability.times.filter((time) => !bookedTimes.includes(time));
  };

  // Disable unavailable days (before today, rest days)
  const disabledDate = (current) => {
    if (!current) return false;

    const formattedDay = current.format("dddd");
    const isRestDay = doctor.availability[formattedDay]?.period === "rest";

    return current < today.startOf("day") || isRestDay;
  };

  const handleConfirmBooking = () => {
    if (selectedDate && selectedTime) {
      onConfirm(); // Call onConfirm to trigger the next step
    } else {
      alert("Please select both date and time");
    }
  };

  const availableTimes = getAvailableTimesForDay();

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={3}>Appointment: {appointmentType}</Title>
          <Title level={4}>
            Dr. {doctor.name} - {doctor.specialty}
          </Title>

          <Calendar
            fullscreen={false}
            onSelect={handleDateSelect}
            disabledDate={disabledDate}
          />

          {selectedDate && (
            <>
              <Radio.Group
                onChange={handleTimePeriodChange}
                value={selectedTimePeriod}
              >
                <Space direction="vertical">
                  <Radio value="morning">
                    <ClockCircleOutlined /> Morning
                  </Radio>
                  <Radio value="afternoon">
                    <CoffeeOutlined /> Afternoon
                  </Radio>
                  <Radio value="evening">
                    <MoonOutlined /> Evening
                  </Radio>
                </Space>
              </Radio.Group>

              <div>
                <Title level={5}>Select Time</Title>
                <Space wrap>
                  {availableTimes.length === 0 ? (
                    <Text>No available times for the selected day.</Text>
                  ) : (
                    availableTimes.map((time) => (
                      <Button
                        key={time}
                        type={selectedTime === time ? "primary" : "default"}
                        onClick={() => handleTimeSelect(time)}
                      >
                        {time}
                      </Button>
                    ))
                  )}
                </Space>
              </div>
            </>
          )}

          <Button
            type="primary"
            block
            disabled={!selectedDate || !selectedTime}
            onClick={handleConfirmBooking}
          >
            Confirm Booking
          </Button>

          <Button type="link" onClick={onBack} block>
            Back to Appointment Details
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default BookingCalendar; */
import React, { useState } from "react";
import { Card, Calendar, Radio, Space, Button, Typography } from "antd";
import {
  ClockCircleOutlined,
  CoffeeOutlined,
  MoonOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const BookingCalendar = ({ doctor, onConfirm, onBack }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [shiftTimes, setShiftTimes] = useState(null); // Store start and end time of the selected shift

  const today = moment();

  // Handle date selection
  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setSelectedTimePeriod(null); // Reset time period when selecting a new date
    setShiftTimes(null); // Reset time shift when selecting a new date
  };

  // Handle time period change (morning, afternoon, evening)
  const handleTimePeriodChange = (e) => {
    const timePeriod = e.target.value;
    setSelectedTimePeriod(timePeriod);

    // Fetch start and end times for the selected time shift
    const dayOfWeek = moment(selectedDate).format("dddd");
    const availabilityForDay = doctor.availability[dayOfWeek];

    if (availabilityForDay) {
      const timeShift = availabilityForDay.timeShift[timePeriod];
      if (timeShift) {
        setShiftTimes({ start: timeShift.start, end: timeShift.end });
      } else {
        setShiftTimes(null);
      }
    }
  };

  // Disable unavailable days (before today, rest days)
  const disabledDate = (current) => {
    if (!current) return false;

    const formattedDay = current?.format("dddd");
    const isRestDay = doctor?.availability[formattedDay]?.period === "rest";

    return current < today?.startOf("day") || isRestDay;
  };

  // Confirm booking handler
  const handleConfirmBooking = () => {
    if (selectedDate && selectedTimePeriod && shiftTimes) {
      onConfirm(); // Proceed to the next step (could be submitting or navigating)
    } else {
      alert("Please select a date and a time period.");
    }
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={4}>
            Dr. {doctor.name} - {doctor.specialty}
          </Title>

          <Calendar
            fullscreen={false}
            onSelect={handleDateSelect}
            disabledDate={disabledDate}
          />

          {selectedDate && (
            <>
              <Title level={5}>Select Time Period</Title>
              <Radio.Group
                onChange={handleTimePeriodChange}
                value={selectedTimePeriod}
              >
                <Space direction="vertical">
                  <Radio value="morning">
                    <ClockCircleOutlined /> Morning
                  </Radio>
                  <Radio value="afternoon">
                    <CoffeeOutlined /> Afternoon
                  </Radio>
                  <Radio value="evening">
                    <MoonOutlined /> Evening
                  </Radio>
                </Space>
              </Radio.Group>

              {shiftTimes && (
                <div>
                  <Title level={5}>Selected Time Shift</Title>
                  <Text>
                    Start: {shiftTimes.start} - End: {shiftTimes.end}
                  </Text>
                </div>
              )}

              <Button
                type="primary"
                block
                onClick={handleConfirmBooking}
                disabled={!selectedDate || !selectedTimePeriod || !shiftTimes}
              >
                Confirm Booking
              </Button>
            </>
          )}

          <Button type="link" onClick={onBack} block>
            Back to Appointment Details
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default BookingCalendar;
