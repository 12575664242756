// import React, { useEffect, useState } from "react";
// import "./PatientHistory.css";

// import { Card, Collapse, List, Typography } from "antd";
// import { DownOutlined } from "@ant-design/icons";

// const { Title, Text } = Typography;
// const { Panel } = Collapse;
// const PatientHistory = () => {
//   const [formData, setFormData] = useState({});
//   const [modalFormDatas, setModalFormData] = useState({});

//   useEffect(() => {
//     try {
//       const storedData = localStorage.getItem("patientFormData");
//       console.log("Stored Data:", storedData); // Check stored data
//       if (storedData) {
//         setFormData(JSON.parse(storedData));
//       }
//     } catch (error) {
//       console.error("Error retrieving data from localStorage:", error);
//     }
//   }, []);
//   useEffect(() => {
//     const modalStoredData = localStorage.getItem("modalFormData");
//     console.log("Stored modal Data:", modalStoredData); // Check stored data
//     if (modalStoredData) {
//       setModalFormData(JSON.parse(modalStoredData));
//     }
//   }, []);

//   return (
//     <>
//       <div id="patient-history" className="py-5">
//         <div className="container">
//           <Title level={2} className="text-center mb-4">
//             Form Data Display
//           </Title>
//           <Card>
//             {Object.keys(formData).map((symptom, index) => (
//               <Card
//                 key={index}
//                 title={<Title level={3}>{symptom}</Title>}
//                 bordered={false}
//                 className="mb-4 box-shadow"
//                 style={{
//                   padding: "16px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//               >
//                 <Collapse
//                   expandIconPosition="right"
//                   expandIcon={({ isActive }) => (
//                     <DownOutlined rotate={isActive ? 180 : 0} />
//                   )}
//                 >
//                   {Object.keys(modalFormDatas).map((key, idx) => (
//                     <Panel header={<Text strong>{key}</Text>} key={idx}>
//                       <List
//                         size="small"
//                         bordered
//                         dataSource={Object.entries(modalFormDatas[key])}
//                         renderItem={([subKey, value]) => (
//                           <List.Item>
//                             <Text>{value}</Text>
//                           </List.Item>
//                         )}
//                       />
//                     </Panel>
//                   ))}
//                 </Collapse>
//               </Card>
//             ))}
//           </Card>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PatientHistory;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, List, Modal, Typography, Form, Checkbox, Button } from "antd";
import { generalClinicalGuidanceFormData } from "../../../../services/data";
const { Title, Text, Paragraph } = Typography;

const PatientHistory = () => {
  const [formData, setFormData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalCheckedValues, setModalCheckedValues] = useState({});
  const location = useLocation();
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);

  // Access the state passed through the navigate function
  useEffect(() => {
    if (location.state && location.state.generalClinicalGuidanceFormData) {
      setFormData(location.state.generalClinicalGuidanceFormData);
    }
  }, [location]);

  const handleCardClick = (symptom) => {
    setModalContent({
      symptom,
      data: formData[symptom]?.options || [],
    });
    setModalCheckedValues({});
    setIsModalVisible(true);
  };

  const handleModalCheckboxChange = (groupName, checkedValues) => {
    setModalCheckedValues((prev) => ({
      ...prev,
      [groupName]: checkedValues,
    }));
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Function to truncate long descriptions
  const truncateDescription = (description) => {
    if (typeof description !== "string") {
      // If description is not a string, return a default message or an empty string
      return "No description ";
    }

    const words = description.split(" ");
    if (words.length > 6) {
      return words.slice(0, 6).join(" ") + "...";
    }
    return description;
  };

  return (
    <>
      <div
        id="patient-history"
        className="py-5"
        style={{
          maxWidth: 400,
          margin: "auto",
          alignItems: "center",
        }}
      >
        <Card className="container">
          <Title level={4}>Patient History</Title>
          {/* {generalClinicalGuidanceFormData.map((category, categoryIndex) => (
            <List
              key={categoryIndex}
              itemLayout="horizontal"
              header={<h2>{category.symptomCategory}</h2>} // Displaying category as a header
              dataSource={Object.keys(formData)}
              renderItem={(symptom) => (
                <List.Item>
                  <List.Item.Meta
                    title={<span>{symptom.name}</span>}
                    description={
                      item.options ? (
                        <div>
                          {item.options.map((option, optionIndex) => (
                            <div key={optionIndex}>
                              <strong>{option.name}</strong>
                              <ul>
                                {option.items.map((subItem, subItemIndex) => (
                                  <li key={subItemIndex}>{subItem}</li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <span>No description available</span>
                      )
                    }
                  />
                </List.Item>
              )}
            />
          ))} */}
          <List
            // pagination={{
            //   position: ["bottom"],
            //   align: "end",
            // }}
            dataSource={Object.keys(formData)}
            renderItem={(symptom) => (
              <List.Item>
                <Card onClick={() => handleCardClick(symptom)} hoverable>
                  <Title level={4} style={{ margin: 0, fontSize: "12px" }}>
                    {symptom}
                  </Title>
                  {/* <div>
                    {formData[symptom]
                      ? truncateDescription(formData[symptom])
                      : "No description available"}
                  </div> */}
                </Card>
              </List.Item>
            )}
            split={false}
          />
          {/* <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(symptom) => (
              <List.Item onClick={() => handleCardClick(symptom)} hoverable>
                <List.Item.Meta title={symptom} description=" " />
              </List.Item>
            )}
          />{" "}
          split={false} */}
          <Modal
            title={
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "semibold",
                }}
              >
                Symptom Details
              </span>
            }
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={[
              <Button key="cancel" onClick={handleModalCancel}>
                Cancel
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <Title level={5}>{modalContent.symptom}</Title>
              {/* {modalContent.data &&
                modalContent.data.map((field, idx) => (
                  <Form.Item
                    label={
                      <span style={{ fontSize: "15px" }}>{field.name}</span>
                    }
                    key={idx}
                  >
                    <Checkbox.Group
                      options={field.selected}
                      value={modalCheckedValues[field.name] || []}
                      onChange={(checkedValues) =>
                        handleModalCheckboxChange(field.name, checkedValues)
                      }
                    />
                  </Form.Item>
                ))} */}
              {modalContent.data &&
                modalContent.data?.map((item, index) => (
                  <div key={index}>
                    <Title level={5}>{item.symptom}</Title>
                    {item.options?.map((option, idx) => (
                      <Paragraph key={idx}>
                        <Text style={{ fontSize: "15px", fontWeight: "600" }}>
                          {option.item}
                        </Text>{" "}
                        {option.selected.join(", ")}
                      </Paragraph>
                    ))}
                  </div>
                ))}
            </Form>
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default PatientHistory;
