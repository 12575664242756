// TABLE DATA
export const diagnosticColumnData = [
  {
    title: "PHONE",
    key: "PHONE",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["PHONE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL: {record["EMAIL"]}</div>
        <div className="tableSubCols details">
          USERNAME: {record["USERNAME"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["TELEGRAM ID"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["USER ID"]}</div>
      </>
    ),
  },
  {
    title: "SPECIALTY",
    key: "SPECIALTY",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">{record["SPECIALTY"]}</div>
        <div className="tableSubCols details">
          ROLE: {record["ROLE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["EXPERIENCE"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["LICENSE NUMBER"]}
        </div>
        <div className="tableSubCols details">STATUS: {record["STATUS"]}</div>
      </>
    ),
  },
  {
    title: "ORGANIZATION NAME",
    key: "ORGANIZATION NAME",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["ORGANIZATION NAME"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["BRANCH NUMBER"]}
        </div>
      </>
    ),
  },
];

export const hospitalColumnData = [
  {
    title: "PHONE",
    key: "PHONE",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["PHONE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL: {record["EMAIL"]}</div>
        <div className="tableSubCols details">
          USERNAME: {record["USERNAME"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["TELEGRAM ID"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["USER ID"]}</div>
      </>
    ),
  },
  {
    title: "SPECIALTY",
    key: "SPECIALTY",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">{record["SPECIALTY"]}</div>
        <div className="tableSubCols details">
          ROLE: {record["ROLE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["EXPERIENCE"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["LICENSE NUMBER"]}
        </div>
        <div className="tableSubCols details">STATUS: {record["STATUS"]}</div>
      </>
    ),
  },
  {
    title: "ORGANIZATION NAME",
    key: "ORGANIZATION NAME",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["ORGANIZATION NAME"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["BRANCH NUMBER"]}
        </div>
      </>
    ),
  },
];
export const pharmacyColumnData = [
  {
    title: "PHONE",
    key: "PHONE",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["PHONE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL: {record["EMAIL"]}</div>
        <div className="tableSubCols details">
          USERNAME: {record["USERNAME"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["TELEGRAM ID"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["USER ID"]}</div>
      </>
    ),
  },
  {
    title: "SPECIALTY",
    key: "SPECIALTY",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">{record["SPECIALTY"]}</div>
        <div className="tableSubCols details">
          ROLE: {record["ROLE"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["EXPERIENCE"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["LICENSE NUMBER"]}
        </div>
        <div className="tableSubCols details">STATUS: {record["STATUS"]}</div>
      </>
    ),
  },
  {
    title: "ORGANIZATION NAME",
    key: "ORGANIZATION NAME",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["ORGANIZATION NAME"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["BRANCH NUMBER"]}
        </div>
      </>
    ),
  },
];
export const doctorColumnData = [
  {
    title: "Full Name",
    key: "fullName",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          SPECIALTY: {record["specialty"]}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["licenseNumber"]}
        </div>

        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Phone",
    key: "phone",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "userId",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["role"]}</div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
      </>
    ),
  },
];

export const patientColumnData = [
  {
    title: "Personal Info",
    key: "personalInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Contact Info",
    key: "contactInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "identificationInfo",
    render: (text, record) => (
      <>
        {" "}
        <div className="tableSubCols heading">
          {record["role"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details ">USER ID: {record["userId"]}</div>
      </>
    ),
  },
];
export const staffColumnData = [
  {
    title: "Personal Info",
    key: "personalInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Contact Info",
    key: "contactInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading "> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "identificationInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["type"]}</div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
        <div className="tableSubCols details">ROLE: {record["role"]}</div>
      </>
    ),
  },
];

export const generalClinicalGuidanceFormData = [
  // Head and Face
  {
    symptomCategory: "Head and Face",
    data: [
      {
        name: "Head symptom",
        // type: "Type of Headache",
        options: [
          {
            name: "Headache",
            items: [
              "Throbbing headache (Pulsing pain)",
              "Pulsating headache (Beating or pulsing pain)",

              "Dull headache (Mild, aching pain)",
              "Sharp headache (Sudden, intense pain)",
            ],
          },
          {
            name: "",
            items: [
              "Sudden severe headache (Very bad headache that starts suddenly)",
            ],
          },

          {
            name: "trauma",
            items: ["Head injury or trauma (Hit or injury to the head)"],
          },
          {
            name: "Head shaking",
            items: ["Head shaking (Involuntary shaking of the head)"],
          },
          {
            name: "Dizziness",
            items: [
              "Dizziness/Vertigo (Feeling unsteady or like the room is spinning)",
            ],
          },
          {
            name: "Loss of balance",
            items: ["Loss of balance (Trouble staying steady or walking)"],
          },
          {
            name: "Behavioral change",
            items: ["Behavioral change (Changes in mood or behavior)"],
          },
          {
            name: "Insomnia",
            items: ["Insomnia (Trouble sleeping)"],
          },
          {
            name: "Fainting",
            items: ["Fainting (Sudden loss of consciousness)"],
          },
          {
            name: "Seizure",
            items: ["Seizure (Sudden shaking or jerking movements )"],
          },
        ],
      },
      {
        name: "Eye Symptoms",
        options: [
          { name: "Pain", items: ["Pain (Hurting in or around the eye)"] },
          { name: "Itching", items: ["Itching (Feeling itchy in the eye)"] },
          { name: "Redness", items: ["Redness (Red or irritated eyes)"] },
          {
            name: "Yellow Discoloration",
            items: ["Yellow Discoloration (Yellowish eyes)"],
          },
          {
            name: "Blurry Vision",
            items: ["Blurry Vision (Trouble seeing clearly)"],
          },
          {
            name: "Double Vision",
            items: ["Double Vision (Seeing two images of the same thing)"],
          },
          {
            name: "Difficulty Seeing Distant",
            items: ["Difficulty Seeing Distant (Unable to see from far)"],
          },
          {
            name: "Difficulty Seeing Close",
            items: ["Difficulty Seeing Close (Unable to see from near)"],
          },
          {
            name: "Recent Dark Circles",
            items: ["Recent Dark Circles (New dark circles around the eyes)"],
          },
          {
            name: "Sensitivity to Light",
            items: [
              "Sensitivity to Light (Eyes hurt or squint in bright light)",
            ],
          },
          { name: "Dryness", items: ["Dryness (Eyes feel dry)"] },
          {
            name: "Excessive Tearing",
            items: ["Excessive Tearing (Eyes watering too much)"],
          },
          {
            name: "Eye Discharge",
            items: ["Eye Discharge (Fluid coming out of the eyes)"],
          },
          {
            name: "Swelling",
            items: ["Swelling (Puffy or swollen area around the eyes)"],
          },
          { name: "Injury", items: ["Injury (Hurt or damage to the eye)"] },
        ],
      },
      {
        name: "Ear Symptoms",
        options: [
          {
            name: "Ear Pain",
            items: ["Ear Pain (Hurting in or around the ear)"],
          },
          { name: "Hearing Loss", items: ["Hearing Loss (Trouble hearing)"] },
          {
            name: "Ringing in the Ears",
            items: [
              "Ringing in the Ears (Buzzing or ringing sound in the ears)",
            ],
          },
          {
            name: "Ear Discharge",
            items: ["Ear Discharge (Fluid coming out of the ear)"],
          },
          {
            name: "Ear Ulcer",
            items: ["Ear Ulcer (Sore or wound in the ear)"],
          },
          {
            name: "Fullness or Pressure",
            items: [
              "Fullness or Pressure (Feeling of fullness or pressure in the ear)",
            ],
          },
          { name: "Itching", items: ["Itching (Itchy feeling in the ear)"] },
          {
            name: "Allergic Reaction",
            items: ["Allergic Reaction (Ear reaction from an allergy)"],
          },
          {
            name: "Swelling on the Ear",
            items: ["Swelling on the Ear (Ear is puffy or swollen)"],
          },
          {
            name: "Swelling Behind the Ear",
            items: ["Swelling Behind the Ear (Puffy area behind the ear)"],
          },
          {
            name: "Foreign Body in the Ear",
            items: ["Foreign Body in the Ear (Something stuck in the ear)"],
          },
          {
            name: "Ear Injury",
            items: ["Ear Injury (Hurt or damage to the ear)"],
          },
        ],
      },
      {
        name: "Nose Symptoms",
        options: [
          {
            name: "Runny Nose",
            items: ["Runny Nose (Fluid coming from the nose)"],
          },
          { name: "Congestion", items: ["Congestion (Stuffy nose)"] },
          { name: "Sneezing", items: ["Sneezing"] },
          {
            name: "Nose Bleeding",
            items: ["Nose Bleeding (Blood coming from the nose)"],
          },
          {
            name: "Nose Ulcer",
            items: ["Nose Ulcer (Sore or wound in or around the nose)"],
          },
          {
            name: "Loss of Smell",
            items: ["Loss of Smell (Can't smell things properly)"],
          },
          {
            name: "Nasal Pain",
            items: ["Nasal Pain (Hurting in or around the nose)"],
          },
          {
            name: "Foreign Body in the Nose",
            items: ["Foreign Body in the Nose (Something stuck in the nose)"],
          },
          {
            name: "Nasal Itching",
            items: ["Nasal Itching (Itchy feeling in the nose)"],
          },
          {
            name: "Nose Injury",
            items: ["Nose Injury (Hurt or damage to the nose)"],
          },
        ],
      },
      {
        name: "Mouth and Throat Symptoms",
        options: [
          { name: "Sore Throat", items: ["Sore Throat (Pain in the throat)"] },
          {
            name: "Difficulty Swallowing",
            items: ["Difficulty Swallowing (Hard to swallow)"],
          },
          {
            name: "Voice Change",
            items: ["Voice Change (Voice sounds different or raspy)"],
          },
          { name: "Dry Mouth", items: ["Dry Mouth (Mouth feels dry)"] },
          {
            name: "Mouth Sores",
            items: ["Mouth Sores (Sores or ulcers in the mouth)"],
          },
          {
            name: "White Patches in the Mouth",
            items: [
              "White Patches in the Mouth (White spots inside the mouth)",
            ],
          },
          {
            name: "Swollen Gums",
            items: ["Swollen Gums (Puffy or swollen gums)"],
          },
          {
            name: "Bleeding Gums",
            items: ["Bleeding Gums (Gums bleed easily)"],
          },
          { name: "Gum Pain", items: ["Gum Pain (Hurting gums)"] },
          { name: "Tongue Pain", items: ["Tongue Pain (Hurting tongue)"] },
          {
            name: "White Patches on the Tongue",
            items: ["White Patches on the Tongue (White spots on the tongue)"],
          },
          {
            name: "Swollen Tongue",
            items: ["Swollen Tongue (Puffy or enlarged tongue)"],
          },
          {
            name: "Tongue Ulcer",
            items: ["Tongue Ulcer (Sore on the tongue)"],
          },
          {
            name: "Change in Taste",
            items: ["Change in Taste (Food tastes different)"],
          },
          {
            name: "Loss of Taste",
            items: ["Loss of Taste (Can't taste things properly)"],
          },
          {
            name: "Swollen Lips",
            items: ["Swollen Lips (Puffy or swollen lips)"],
          },
          {
            name: "Bad Breath",
            items: ["Bad Breath (Unpleasant smell from the mouth)"],
          },
          { name: "Jaw Pain", items: ["Jaw Pain (Hurting in the jaw)"] },
          { name: "Toothache", items: ["Toothache (Pain in the teeth)"] },
        ],
      },
      {
        name: "Face Symptoms",
        options: [
          {
            name: "Facial Pain",
            items: ["Facial Pain (Hurting in or around the face)"],
          },
          {
            name: "Facial Drooping",
            items: [
              "Facial Drooping (One side of the face looks or feels lower)",
            ],
          },
          {
            name: "Numbness",
            items: ["Numbness (Loss of feeling in the face)"],
          },
          {
            name: "Facial Movement Issues",
            items: ["Facial Movement Issues (Trouble moving facial muscles)"],
          },
          {
            name: "Facial Swelling",
            items: ["Facial Swelling (Puffy or swollen face)"],
          },
          {
            name: "Yellow Face",
            items: ["Yellow Face (Yellowish skin on the face)"],
          },
          {
            name: "Facial Twitching",
            items: ["Facial Twitching (Involuntary movements in the face)"],
          },
          {
            name: "Pale Face",
            items: ["Pale Face (Face looks unusually pale)"],
          },
          {
            name: "Facial Itching",
            items: ["Facial Itching (Itchy skin on the face)"],
          },
          {
            name: "Red Patch on the Cheek",
            items: ["Red Patch on the Cheek (Red spots or area on the cheek)"],
          },
          {
            name: "Black Patch on the Cheek",
            items: ["Black Patch on the Cheek (Dark spots on the cheek)"],
          },
          {
            name: "Facial Rash",
            items: ["Facial Rash (Red or irritated spots on the face)"],
          },
          { name: "Acne", items: ["Acne (Pimples or bumps on the face)"] },
          {
            name: "Facial Injury",
            items: ["Facial Injury (Hurt or damage to the face)"],
          },
        ],
      },
      {
        name: "Scalp Symptoms",
        options: [
          { name: "Scalp Itching", items: ["Scalp Itching (Itchy scalp)"] },
          { name: "Scalp Dryness", items: ["Scalp Dryness (Dry scalp)"] },
          { name: "Scalp Pain", items: ["Scalp Pain (Hurting on the scalp)"] },
          {
            name: "Scalp Ulcer",
            items: ["Scalp Ulcer (Sore or wound on the scalp)"],
          },
          {
            name: "Scalp Redness",
            items: ["Scalp Redness (Red or irritated scalp)"],
          },
          {
            name: "Scalp Swelling",
            items: ["Scalp Swelling (Puffy or swollen scalp)"],
          },
          {
            name: "Scalp Lumps",
            items: ["Scalp Lumps (Bumps or lumps on the scalp)"],
          },
          { name: "Hair Loss", items: ["Hair Loss (Losing hair)"] },
          {
            name: "Visible Rash or Scaling",
            items: [
              "Visible Rash or Scaling (Red, scaly patches on the scalp)",
            ],
          },
        ],
      },
    ],
  },
  // Neck
  {
    symptomCategory: "Neck",
    data: [
      {
        name: "Neck Stiffness (Difficulty moving the neck)",
      },
      {
        name: "Neck pain during movement (Pain when moving the neck)",
      },
      {
        name: "Swelling in the neck (Neck looks or feels swollen)",
      },
      {
        name: "Pain during swallowing (Hurting when swallowing)",
        options: [
          {
            name: "Front of the neck",
            items: [
              "Front of the neck (Swelling in the front part of the neck)",
            ],
          },
          {
            name: "Sides of the neck ",
            items: ["Sides of the neck (Swelling on the sides of the neck)"],
          },
          {
            name: "Back of the neck ",
            items: ["Back of the neck (Swelling at the back of the neck)"],
          },
        ],
      },
      {
        name: "Numbness or Tingling in the Neck (Loss of feeling or pins and needles in the neck)",
      },
      {
        name: "Limited Range of Motion (Difficulty moving neck fully)",
      },
      {
        name: "Trauma or injury around the neck (Hurt or damage to the neck)",
      },
    ],
  },
  // Chest
  {
    symptomCategory: "Chest",
    data: [
      { name: "Chest Pain (Hurting in the chest area)" },
      {
        name: "Shortness of Breath (Trouble breathing or feeling like you can't get enough air)",
      },
      { name: "Cough (Persistent coughing)" },
      {
        name: "Palpitations or Irregular Heartbeat (Heart feels like it's beating fast or irregularly)",
      },
      { name: "Wheezing (Whistling or high-pitched breathing sound)" },
      {
        name: "Chest Tightness or Pressure (Feeling like something heavy is pressing on your chest)",
      },
      { name: "Swelling in the Chest Area (Chest looks or feels swollen)" },
      { name: "Injury or trauma of the chest (Hurt or damage to the chest)" },
    ],
  },
  // Breast
  {
    symptomCategory: "Breast",
    data: [
      { name: "Pain in the breast (Hurting in or around the breast)" },
      { name: "Swelling in the breast (Breast looks or feels swollen)" },
      { name: "Lump in the breast (A bump or mass felt in the breast)" },
      { name: "Discharge from the breast (Fluid leaking from the breast)" },
      { name: "Redness in the breast (Breast looks red or irritated)" },
      {
        name: "Changes in the skin of the breast (Breast skin looks different or unusual)",
      },
      { name: "Bloody nipple discharge (Blood coming from the nipple)" },
      { name: "Ulcer in the breast (Sore or wound in the breast)" },
      { name: "Ulcer in the nipple (Sore or wound on the nipple)" },
      { name: "Nipple redness (Red or irritated nipple)" },
      { name: "Fluid leaking from nipple (Fluid coming from the nipple)" },
      { name: "Breast getting hard like stone (Breast feels hard or firm)" },
      {
        name: "Breast getting bigger (Breast appears to be increasing in size)",
      },
      { name: "Breast not developing (Breast not growing as expected)" },
      {
        name: "Abnormal milk coming from breast (Milk coming from the breast when not breastfeeding)",
      },
      { name: "Injury or trauma to the breast (Hurt or damage to the breast)" },
    ],
  },
  // Abdomen
  {
    symptomCategory: "Abdomen",
    data: [
      {
        name: "Abdominal Pain (Hurting in the stomach or belly area)",
        options: [
          {
            name: "How would you describe the pain ?",
            items: [
              "Sharp (Sudden and intense pain)",
              "Dull (Mild, aching pain)",
              "Cramping (Pain with muscle spasms or cramps)",
              "Aching (Constant, dull pain)",
              "Burning (Pain that feels like burning)",
            ],
          },
          {
            name: "Where is the pain located ?",
            items: [
              "Upper and right side of abdomen (Pain in the upper right part of the belly)",
              "Upper and left side of the abdomen (Pain in the upper left part of the belly)",
              "Lower and right side of the abdomen (Pain in the lower right part of the belly)",
              "Lower and left side of the abdomen (Pain in the lower left part of the belly)",
              "The center of the abdomen (Pain in the middle of the belly)",
            ],
          },
          {
            name: "Does the pain radiate to other areas ?",
            items: [
              "To the back (Pain moves towards the back)",
              "To the flank (Pain felt on the sides of the belly or back)",
              "Shifting to the lower abdomen (Pain moves down to the lower belly)",
              "To the chest (Pain moves up to the chest)",
              "To the shoulder (Pain moves up to the shoulder)",
            ],
          },
        ],
      },
      { name: "Nausea (Feeling like you want to throw up)" },
      {
        name: "Vomiting (Throwing up)",
        options: [
          {
            name: "What does your vomiting look like ?",
            items: [
              "Yellow-green vomiting (Vomiting yellow or green fluid)",
              "Vomiting food content (Throwing up food you ate)",
              "Coffee ground-like vomiting (Vomiting looks like coffee grounds)",
              "Bloody vomiting (Throwing up blood)",
            ],
          },
        ],
      },
      {
        name: "Bloating of the abdomen (Feeling of fullness or swelling in the stomach area)",
      },
      { name: "Diarrhea (Frequent loose or watery stools)" },
      { name: "Constipation (Difficulty or infrequent bowel movements)" },
      { name: "Blood in Stool (Blood seen in bowel movements)" },
      { name: "Bleeding from anus (Blood coming from the anus)" },
      {
        name: "Heartburn (Burning feeling in the chest or throat after eating)",
      },
      {
        name: "Abdominal Swelling (Stomach or belly looks or feels swollen)",
        options: [
          {
            name: "Where is the swelling located ?",
            items: [
              "Inguinal (Swelling near the groin)",
              "Umbilical (Swelling around the belly button)",
              "Epigastric (Swelling in the upper stomach area)",
              "Femoral (Swelling near the upper thigh)",
              "Scrotal (Swelling in the scrotum)",
              "Around incision site (Swelling near a surgical wound)",
              "Generalized abdominal swelling (Swelling all over the stomach area)",
            ],
          },
        ],
      },
      {
        name: "Yellowing of the Skin or Eyes (Jaundice) (Skin or eyes look yellowish)",
      },
      { name: "Loss of Appetite (Not feeling hungry or not wanting to eat)" },
      { name: "Lot of burping (Frequent burping)" },
      { name: "Lot of hiccups (Frequent or continuous hiccups)" },
      {
        name: "Can’t digest food easily (Difficulty digesting food or feeling uncomfortable after eating)",
      },
      {
        name: "Abdominal injury or trauma (Hurt or damage to the stomach or belly area)",
      },
    ],
  },
  // Arm
  {
    symptomCategory: "Arm",
    data: [
      { name: "Arm Pain (Hurting in or around the arm)" },
      { name: "Swelling in the Arm (Arm looks or feels swollen)" },
      {
        name: "Numbness or Tingling in the Arm (Loss of feeling or pins and needles in the arm)",
      },
      { name: "Weakness in the Arm (Arm feels weak or hard to move)" },
      { name: "Arm Jerking (Sudden, uncontrolled movements of the arm)" },
      { name: "Tremor or Shaking of the Arm (Involuntary shaking of the arm)" },
      {
        name: "Bruising or Discoloration of the Arm (Dark spots or changes in arm color)",
      },
      { name: "Ulcer in the Arm (Sore or wound on the arm)" },
      { name: "Difficulty Moving the Arm (Trouble moving the arm)" },
      {
        name: "Joint Pain (Shoulder, Elbow, Wrist) (Hurting in the joints of the arm)",
      },
      {
        name: "Skin Issues on the Arm (Problems with the skin on the arm)",
        options: [
          {
            name: "What kind of skin changes are on your arm ?",
            items: [
              "Rash (Red, irritated spots or patches)",
              "Itching (Itchy feeling on the skin)",
              "Redness (Red or irritated skin)",
              "Blisters (Small fluid-filled bumps on the skin)",
              "Dryness (Dry, flaky skin)",
            ],
          },
        ],
      },
      { name: "Pain in the Finger (Hurting in the fingers)" },
      { name: "Difficulty Moving the Finger (Trouble moving the fingers)" },
      {
        name: "Numbness or Tingling in the Finger (Loss of feeling or pins and needles in the fingers)",
      },
      {
        name: "Color Change in the Finger (Fingers look or feel a different color)",
      },
      { name: "Swelling of the Finger (Fingers look or feel swollen)" },
      {
        name: "Tremor or Shaking of the Hand (Involuntary shaking of the hand)",
      },
      { name: "Nail Color Change (Nails look a different color)" },
      { name: "Nail Loss (Losing fingernails)" },
      { name: "Nail Dimorphic (Nails look unusual or different)" },
      {
        name: "Injury or Trauma to the Hand or Arm (Hurt or damage to the hand or arm)",
      },
      {
        name: "Fractures or Broken Bones (Broken or cracked bones in the arm or hand)",
      },
    ],
  },
  // Back
  {
    symptomCategory: "Back",
    data: [
      { name: "Back Pain (Hurting in the back)" },
      {
        name: "Back Pain During Standing or Moving (Pain when standing or moving)",
      },
      { name: "Back Pain When Lifting Objects (Hurting when lifting things)" },
      { name: "Stiffness in the Back (Difficulty moving the back)" },
      {
        name: "Numbness or Tingling (Loss of feeling or pins and needles in the back)",
      },
      { name: "Any Deformity in the Back Area (Back looks or feels abnormal)" },
      {
        name: "Bruising and Swelling in the Back (Dark spots or swelling in the back)",
      },
      { name: "Swelling in the Back (Back looks or feels swollen)" },
      {
        name: "Back Muscle Spasms (Sudden tightening or cramping in the back muscles)",
      },
      {
        name: "Weakness in the Back or Legs (Back or legs feel weak or hard to move)",
      },
      {
        name: "Back Bruising or Discoloration (Dark spots or changes in back color)",
      },
      {
        name: "Difficulty Standing or Sitting for Long Periods (Trouble staying in one position for a long time)",
      },
      {
        name: "Back Fractures or Bone Injuries (Broken or cracked bones in the back)",
      },
      { name: "Any Injury or Trauma to the Back (Hurt or damage to the back)" },
    ],
  },

  // Genitalia (Male)
  {
    symptomCategory: "Genitalia",
    data: [
      { name: "Pelvic Pain (Hurting in the pelvic area)" },
      { name: "Hip Bone Tenderness (Hip feels sore when touched)" },
      {
        name: "Hip Feels Like It's Popping Out of the Socket (Hip feels unstable)",
      },
      {
        name: "Difficulty Walking or Standing From a Chair (Trouble walking or standing up)",
      },
      { name: "Pain During Movement (Hurting when moving)" },
      { name: "Limited Range of Motion (Difficulty moving the pelvis)" },
      {
        name: "Stiffness in the Pelvic Area (Pelvis feels tight or hard to move)",
      },
      {
        name: "Bruising and Swelling (Dark spots or swelling in the pelvic area)",
      },
      { name: "Visible Deformity of the Pelvis (Pelvis looks abnormal)" },
      {
        name: "Numbness or Tingling in the Groin or Legs (Loss of feeling or pins and needles in the groin or legs)",
      },
      { name: "Groin Pain (Hurting in the groin area)" },
      { name: "Swelling in the Groin Area (Groin looks or feels swollen)" },
      {
        name: "Urinary Symptoms (Problems with urination)",
        options: [
          {
            name: "What urinary issues are you experiencing ?",
            items: [
              "Urinary Incontinence (Trouble controlling urine)",
              "Immediate Urge to Pee (Feeling the need to pee right away)",
              "Frequently Need to Pee (Going to the bathroom often)",
              "Poor Flow During Urination (Weak stream of urine)",
              "Intermittent Stream During Urination (Urine flow stops and starts)",
              "Dribbling (Urine leaking after finishing)",
              "Sensation of Poor Bladder Emptying (Feeling like the bladder doesn't fully empty)",
              "Bladder Distention (Bladder feels swollen or full)",
              "Difficulty Passing Urine (Trouble peeing)",
              "Difficulty Initiating Urine (Trouble starting to pee)",
              "Blood in Urine (Seeing blood when peeing)",
              "Decrease in Urine Volume (Peeing less than usual)",
              "Increase in Urine Volume (Peeing more than usual)",
              "Absence of Urine (Not able to pee)",
              "Pain During Urination (Hurting when peeing)",
              "Small Stones in Urine (Seeing tiny stones when peeing)",
            ],
          },
        ],
      },
    ],
  },
  // Pelvis (Female)
  {
    symptomCategory: "Pelvis",
    data: [
      { name: "Abnormal Swelling of the Penis (Penis looks or feels swollen)" },
      { name: "Scrotal Swelling (Swollen area in the scrotum)" },
      {
        name: "Abnormal Discharge From the Penis (Fluid from the penis, possibly with a bad smell)",
      },
      {
        name: "Pain and Tenderness Around the Scrotum (Hurting or sore scrotum)",
      },
      {
        name: "Itching Around the Genitalia (Itchy feeling in the genital area)",
      },
      { name: "Lump in the Genital Area (Bump or lump near the genitals)" },
      {
        name: "Lump Between Genitalia and Anus (Bump or lump between the genitals and anus)",
      },
      { name: "Ulcer on the Penis (Sore or wound on the penis)" },
      { name: "Rash on the Penis (Red or irritated spots on the penis)" },
      { name: "Swelling Around the Penis (Swollen area near the penis)" },
      { name: "Penis Pulled In (Penis seems to be pulled inward)" },
      { name: "Uncircumcised Penis (Penis with foreskin intact)" },
      { name: "Empty or Undescended Testicle (Testicle not in the scrotum)" },
      { name: "Genital Numbness (Loss of feeling in the genitals)" },
      { name: "Ambiguous Genitalia (Genital appearance is unclear)" },
      {
        name: "Visible Lice and Nits (Seeing lice or nits in the genital area)",
      },
      {
        name: "Sexual-Related Symptoms (Issues related to sexual function)",
        options: [
          {
            name: "What sexual problems are you experiencing ?",
            items: [
              "Painful Erection (Hurting when the penis is erect)",
              "Pain During Sex (Hurting during sexual activity)",
              "Increased Sexual Desire (Feeling more desire for sex)",
              "Decreased Sexual Desire (Feeling less desire for sex)",
              "Ejaculates Sooner During Sex (Reaching climax too quickly)",
              "Erection That Won’t Go Down (Erection lasting too long)",
              "Infertility (Trouble having children)",
            ],
          },
        ],
      },
    ],
  },
  // Buttock
  {
    symptomCategory: "Buttock",
    data: [
      { name: "Pain in the Buttock Area (Hurting in the buttocks)" },
      {
        name: "Pain That Worsens With Movement or Sitting (Hurting more when you move or sit)",
      },
      { name: "Swelling in the Buttock Area (Buttocks are swollen or puffy)" },
      {
        name: "Numbness or Tingling in the Buttocks (Loss of feeling or pins and needles sensation)",
      },
      {
        name: "External Pain Around Your Anal Area (Hurting outside the anus)",
      },
      { name: "Internal Pain Around Your Anal Area (Hurting inside the anus)" },
      {
        name: "Burning Sensation During Defecation (Feels like cutting or tearing when pooping)",
      },
      { name: "Itching Around the Anus (Feeling itchy near the anus)" },
      { name: "Ulcer Around the Anus (Sore or wound near the anus)" },
      { name: "Abscess Near the Anus (A painful, swollen bump near the anus)" },
      {
        name: "Bright-Colored Bleeding From the Anus (Fresh blood when pooping)",
      },
      {
        name: "Dark Bleeding Mixed With Stool (Dark blood mixed with your poop)",
      },
      { name: "Discharge From the Anus (Fluid leaking from the anus)" },
      {
        name: "Protrusion of Mass Per Anal (A lump or mass sticking out of the anus)",
      },
      {
        name: "Visible Tear or Cut Near the Anus (Seeing a cut near the anus)",
      },
      {
        name: "Swelling or Lump Around the Anus (Swollen bump around the anus)",
      },
      {
        name: "Swelling From the Anus That Goes Away on Its Own (A swollen lump that disappears by itself)",
      },
      {
        name: "Swelling From the Anus That You Push Back Manually (A lump you can push back into place)",
      },
      {
        name: "Swelling From the Anus That Is Always Present and Cannot Be Pushed Back (A lump that stays and cannot be pushed back)",
      },
      {
        name: "Recent Trauma or Surgery in the Anal Area (Recent injury or surgery near the anus)",
      },
      { name: "Unable to Pass Gas or Stool (Can't release gas or poop)" },
      {
        name: "Sharp Pain During or After Bowel Movement (Severe hurting when or after pooping)",
      },
      {
        name: "Incontinence or Loss of Bowel Control (Can’t control bowel movements)",
      },
      { name: "Foul-Smelling Stool (Poop that smells really bad)" },
      { name: "Diarrhea (Frequent, watery stool)" },
      { name: "Constipation (Trouble pooping or not pooping often)" },
      { name: "Hard Stool (Stool is difficult to pass)" },
      { name: "Strain During Defecation (Pushing hard to poop)" },
      { name: "Stool Color Change (Poop looks a different color)" },
      { name: "Worms in Stool or Anus (Seeing worms in your poop or anus)" },
      { name: "Can’t Poop Completely (Feeling like you haven’t fully pooped)" },
      { name: "Want to Poop All the Time (Constant urge to poop)" },
    ],
  },
  // Leg
  {
    symptomCategory: "Leg",
    data: [
      {
        name: "Leg Pain (Hurting in your leg)",
        options: [
          {
            name: "Where is the pain located ?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
            ],
          },
        ],
      },
      { name: "Leg Pain With Movement (Hurting when moving your leg)" },
      { name: "Ankle Pain (Hurting in the ankle)" },
      { name: "Ankle Redness (Ankle looks red)" },
      {
        name: "Swelling (Leg is swollen or puffy)",
        options: [
          {
            name: "Where is the swelling located ?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
              "Toe (Fingers on the foot)",
            ],
          },
        ],
      },
      { name: "Ulcer on the Leg (Open sore or wound on the leg)" },
      {
        name: "Numbness or Tingling (Loss of feeling or pins and needles sensation)",
      },
      { name: "Weakness of One Leg (One leg feels weak)" },
      { name: "Weakness of Both Legs (Both legs feel weak)" },
      {
        name: "Weakness of the Leg Including the Body (Leg weakness along with body weakness)",
      },
      {
        name: "Joint Pain (Hurting in the leg joints)",
        options: [
          {
            name: "Which part of the leg has joint pain ?",
            items: [
              "Knee (Middle joint in the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Joint connecting the foot and leg)",
            ],
          },
        ],
      },
      { name: "Cramping in the Leg (Muscle cramps in the leg)" },
      {
        name: "Visible and Swollen Blood Vessels in the Leg (Veins look bigger or swollen)",
      },
      {
        name: "Skin Changes on the Leg (Skin looks or feels different)",
        options: [
          {
            name: "What kind of skin changes are on your leg ?",
            items: [
              "Rash (Red, irritated skin)",
              "Itching (Skin feels itchy)",
              "Redness (Leg looks red)",
              "Blisters (Fluid-filled bumps on the skin)",
              "Dryness (Skin feels dry and flaky)",
            ],
          },
        ],
      },
      { name: "Discoloration of the Leg (Leg looks a different color)" },
      { name: "Extreme Coldness in the Leg (Leg feels very cold)" },
      { name: "Ulcer on the Toe (Open sore or wound on the toe)" },
      { name: "Swelling of the Toe (Toe is swollen or puffy)" },
      { name: "Injuries or Trauma to the Leg (Recent injury to the leg)" },
    ],
  },
  // Skin Symptoms
  {
    symptomCategory: "Skin",
    data: [
      {
        name: "Skin Irritation",
        options: [
          { name: "Rash", items: ["Red, bumpy skin irritation"] },
          {
            name: "Itching",
            items: ["Feeling of needing to scratch the skin"],
          },
          { name: "Redness", items: ["Skin looks red"] },
          { name: "Blisters", items: ["Fluid-filled bumps on the skin"] },
          {
            name: "Irritated Skin",
            items: ["Skin feels inflamed or sensitive"],
          },
        ],
      },
      {
        name: "Skin Discoloration",
        options: [
          { name: "Yellow Discoloration", items: ["Skin looks yellowish"] },
          {
            name: "Loss of Skin Color",
            items: ["Skin looks lighter in patches"],
          },
          { name: "Color Changes", items: ["Skin looks different in color"] },
        ],
      },
      {
        name: "Skin Texture Changes",
        options: [
          { name: "Elevated Skin Patch", items: ["A raised area on the skin"] },
          { name: "Flaky Skin Patch", items: ["Skin is peeling or flaking"] },
          {
            name: "Scaling, Peeling, and Flaking",
            items: ["Skin is shedding or coming off"],
          },
          { name: "Dryness", items: ["Skin feels rough or flaky"] },
          {
            name: "Burn",
            items: ["Skin feels like it's been burned or exposed to heat"],
          },
          { name: "Oily Skin", items: ["Skin feels greasy or shiny"] },
          { name: "Swelling", items: ["Skin is puffy or enlarged"] },
          { name: "Bruising", items: ["Skin has black-and-blue marks"] },
          { name: "Sores or Ulcers", items: ["Open sores on the skin"] },
        ],
      },
      {
        name: "Injury or Trauma to the Skin",
        options: [{ name: "Recent Injury", items: ["Recent skin injury"] }],
      },
    ],
  },
];
const commonOption = [
  {
    name: "Where is the problem located?",
    items: [
      "Face",
      "Neck",
      "Chest",
      "Trunk (Upper body)",
      "Arm",
      "Back",
      "Leg",
      "The Whole Body (All over the skin)",
    ],
  },
];
const commonAdultSymptomsWithBodyParts = [
  {
    name: "Head",
    symptom: [
      {
        name: "Headache",
        option: [
          {
            name: "Characteristics",
            items: [
              "Throbbing headache (Pulsing pain)",
              "Pulsating headache (Beating or pulsing pain)",
              "Dull headache (Mild, aching pain)",
              "Sharp headache (Sudden, intense pain)",
            ],
          },
        ],
      },

      {
        name: "Sudden severe headache (Very bad headache that starts suddenly)",
      },
      {
        name: "Head injury or trauma (Hit or injury to the head)",
      },
      {
        name: "Head shaking (Involuntary shaking of the head)",
      },
      {
        name: "Dizziness/Vertigo (Feeling unsteady or like the room is spinning)",
      },
      {
        name: "Loss of balance (Trouble staying steady or walking)",
      },
      {
        name: "Behavioral change (Changes in mood or behavior)",
      },
      {
        name: "Insomnia (Trouble sleeping)",
      },
      {
        name: "Fainting (Sudden loss of consciousness)",
      },
      {
        name: "Seizure (Sudden shaking or jerking movements)",
      },
    ],
  },
  {
    name: "Eye",
    symptom: [
      {
        name: "Pain (Hurting in or around the eye)",
      },
      {
        name: "Itching (Feeling itchy in the eye)",
      },
      {
        name: "Redness of the eye (Red or irritated eyes)",
      },
      {
        name: "Yellow discoloration of the eye (Yellowish eyes)",
      },
      {
        name: "Blurry vision (Trouble seeing clearly)",
      },
      {
        name: "Double vision (Seeing two images of the same thing)",
      },
      {
        name: "Unable to see from far (Difficulty seeing distant things)",
      },
      {
        name: "Unable to see from near (Difficulty seeing things close up)",
      },
      {
        name: "Recent dark circle around the eye (New dark circles around the eyes)",
      },
      {
        name: "Sensitivity to light (Eyes hurt or squint in bright light)",
      },
      {
        name: "Dryness of the eye (Eyes feel dry)",
      },
      {
        name: "Excessive tearing (Eyes watering too much)",
      },
      {
        name: "Eye discharge (Fluid coming out of the eyes)",
      },
      {
        name: "Swelling around the eye (Puffy or swollen area around the eyes)",
      },
      {
        name: "Injury to the eye (Hurt or damage to the eye)",
      },
    ],
  },
  {
    name: "Ear",
    symptom: [
      {
        name: "Ear pain (Hurting in or around the ear)",
      },
      {
        name: "Hearing loss (Trouble hearing)",
      },
      {
        name: "Ringing in the ears (Tinnitus) (Buzzing or ringing sound in the ears)",
      },
      {
        name: "Ear discharge (Fluid coming out of the ear)",
      },
      {
        name: "Ulcer in or around the ear (Sore or wound in the ear)",
      },
      {
        name: "Fullness or pressure (Feeling of fullness or pressure in the ear)",
      },
      {
        name: "Itching (Itchy feeling in the ear)",
      },
      {
        name: "Allergic reaction to the ear (Ear reaction from an allergy)",
      },
      {
        name: "Swelling on the ear (Ear is puffy or swollen)",
      },
      {
        name: "Swelling behind the ear (Puffy area behind the ear)",
      },
      {
        name: "Foreign body in the ear (Something stuck in the ear)",
      },
      {
        name: "Injury to the ear (Hurt or damage to the ear)",
      },
    ],
  },
  {
    name: "Nose",
    symptom: [
      {
        name: "Runny nose (Fluid coming from the nose)",
      },
      {
        name: "Congestion (Stuffy nose)",
      },
      {
        name: "Sneezing",
      },
      {
        name: "Nose bleeding (Blood coming from the nose)",
      },
      {
        name: "Ulcer in or around the nose (Sore or wound in or around the nose)",
      },
      {
        name: "Loss of smell (Can't smell things properly)",
      },
      {
        name: "Pain (Hurting in or around the nose)",
      },
      {
        name: "Foreign body in the nose (Something stuck in the nose)",
      },
      {
        name: "Itching (Itchy feeling in the nose)",
      },
      {
        name: "Injury to the nose (Hurt or damage to the nose)",
      },
    ],
  },
  {
    name: "Mouth and Throat",
    symptom: [
      {
        name: "Sore throat (Pain in the throat)",
      },
      {
        name: "Difficulty swallowing (Hard to swallow)",
      },
      {
        name: "Voice change (Hoarseness) (Voice sounds different or raspy)",
      },
      {
        name: "Dry mouth (Mouth feels dry)",
      },
      {
        name: "Mouth sores (Ulcer in the mouth) (Sores or ulcers in the mouth)",
      },
      {
        name: "White patches in the mouth (White spots inside the mouth)",
      },
      {
        name: "Swollen gum (Puffy or swollen gums)",
      },
      {
        name: "Bleeding gum (Gums bleed easily)",
      },
      {
        name: "Pain in the gum (Hurting gums)",
      },
      {
        name: "Pain in the tongue (Hurting tongue)",
      },
      {
        name: "White patches on the tongue (White spots on the tongue)",
      },
      {
        name: "Swollen tongue (Puffy or enlarged tongue)",
      },
      {
        name: "Ulcer on the tongue (Sore on the tongue)",
      },
      {
        name: "Change in taste (Food tastes different)",
      },
      {
        name: "Loss of taste (Can't taste things properly)",
      },
      {
        name: "Swollen lips (Puffy or swollen lips)",
      },
      {
        name: "Bad breath (Unpleasant smell from the mouth)",
      },
      {
        name: "Jaw pain (Hurting in the jaw)",
      },
      {
        name: "Toothache (Pain in the teeth)",
      },
    ],
  },
  {
    name: "Face",
    symptom: [
      {
        name: "Facial pain (Hurting in or around the face)",
      },
      {
        name: "Drooping of the face on one side (One side of the face looks or feels lower)",
      },
      {
        name: "Feeling numbness (Loss of feeling in the face)",
      },
      {
        name: "Can't move the face (Trouble moving facial muscles)",
      },
      {
        name: "Swelling of the face (Puffy or swollen face)",
      },
      {
        name: "Yellow face (Yellowish skin on the face)",
      },
      {
        name: "Twitching of the face (Involuntary movements in the face)",
      },
      {
        name: "Pale face (Face looks unusually pale)",
      },
      {
        name: "Itchy face (Itchy skin on the face)",
      },
      {
        name: "Red patch on the cheek (Red spots or area on the cheek)",
      },
      {
        name: "Black patch on the cheek (Dark spots on the cheek)",
      },
      {
        name: "Rash on the face (Red or irritated spots on the face)",
      },
      {
        name: "Acne on the face (Pimples or bumps on the face)",
      },
      {
        name: "Injury to the face (Hurt or damage to the face)",
      },
    ],
  },
  {
    name: "Scalp",
    symptom: [
      {
        name: "Itching (Itchy scalp)",
      },
      {
        name: "Dryness (Dry scalp)",
      },
      {
        name: "Pain (Hurting on the scalp)",
      },
      {
        name: "Ulcer on the scalp (Sore or wound on the scalp)",
      },
      {
        name: "Redness (Red or irritated scalp)",
      },
      {
        name: "Swelling (Puffy or swollen scalp)",
      },
      {
        name: "Lumps (Bumps or lumps on the scalp)",
      },
      {
        name: "Hair loss (Losing hair)",
      },
      {
        name: "Visible rash or scaling (Red, scaly patches on the scalp)",
      },
    ],
  },
  {
    name: "Neck",
    symptom: [
      {
        name: "Neck Pain (Hurting in or around the neck)",
      },
      {
        name: "Neck Stiffness (Difficulty moving the neck)",
      },
      {
        name: "Neck pain during movement (Pain when moving the neck)",
      },
      {
        name: "Swelling in the neck (Neck looks or feels swollen)",
        option: [
          {
            name: "Where is the swelling?",
            items: [
              "Front of the neck (Swelling in the front part of the neck)",
              "Sides of the neck (Swelling on the sides of the neck)",
              "Back of the neck (Swelling at the back of the neck)",
            ],
          },
        ],
      },
      {
        name: "Pain during swallowing (Hurting when swallowing)",
      },
      {
        name: "Numbness or Tingling in the Neck (Loss of feeling or pins and needles in the neck)",
      },
      {
        name: "Limited Range of Motion (Difficulty moving neck fully)",
      },
      {
        name: "Trauma or injury around the neck (Hurt or damage to the neck)",
      },
    ],
  },
  {
    name: "Chest",
    symptom: [
      {
        name: "Chest Pain (Hurting in the chest area)",
      },
      {
        name: "Shortness of Breath (Trouble breathing or feeling like you can't get enough air)",
      },
      {
        name: "Cough (Persistent coughing)",
      },
      {
        name: "Palpitations or Irregular Heartbeat (Heart feels like it's beating fast or irregularly)",
      },
      {
        name: "Wheezing (Whistling or high-pitched breathing sound)",
      },
      {
        name: "Chest Tightness or Pressure (Feeling like something heavy is pressing on your chest)",
      },
      {
        name: "Swelling in the Chest Area (Chest looks or feels swollen)",
      },

      {
        name: "Injury or trauma of the chest (Hurt or damage to the chest)",
      },
    ],
  },
  {
    name: "Abdomen",
    symptom: [
      {
        name: "Abdominal Pain (Hurting in the stomach or belly area)",
        option: [
          {
            name: "How would you describe the pain?",
            items: [
              "Sharp (Sudden and intense pain)",
              "Dull (Mild, aching pain)",
              "Cramping (Pain with muscle spasms or cramps)",
              "Aching (Constant, dull pain)",
              "Burning (Pain that feels like burning)",
            ],
          },
          {
            name: "Where is the pain located?",
            items: [
              "Upper and right side of abdomen (Pain in the upper right part of the belly)",
              "Upper and left side of the abdomen (Pain in the upper left part of the belly)",
              "Lower and right side of the abdomen (Pain in the lower right part of the belly)",
              "Lower and left side of the abdomen (Pain in the lower left part of the belly)",
              "The center of the abdomen (Pain in the middle of the belly)",
            ],
          },
          {
            name: "Does the pain radiate to other areas?",
            items: [
              "To the back (Pain moves towards the back)",
              "To the flank (Pain felt on the sides of the belly or back)",
              "Shifting to the lower abdomen (Pain moves down to the lower belly)",
              "To the chest (Pain moves up to the chest)",
              "To the shoulder (Pain moves up to the shoulder)",
            ],
          },
        ],
      },
      {
        name: "Nausea (Feeling like you want to throw up)",
      },
      {
        name: "Vomiting (Throwing up)",
        option: [
          {
            name: "What does your vomiting look like?",
            items: [
              "Yellow-green vomiting (Vomiting yellow or green fluid)",
              "Vomiting food content (Throwing up food you ate)",
              "Coffee ground-like vomiting (Vomiting looks like coffee grounds)",
              "Bloody vomiting (Throwing up blood)",
            ],
          },
        ],
      },
      {
        name: "Bloating of the abdomen (Feeling of fullness or swelling in the stomach area)",
      },
      {
        name: "Diarrhea (Frequent loose or watery stools)",
      },
      {
        name: "Constipation (Difficulty or infrequent bowel movements)",
      },
      {
        name: "Blood in Stool (Blood seen in bowel movements)",
      },
      {
        name: "Bleeding from anus (Blood coming from the anus)",
      },
      {
        name: "Heartburn (Burning feeling in the chest or throat after eating)",
      },
      {
        name: "Abdominal Swelling (Stomach or belly looks or feels swollen)",
        option: [
          {
            name: "Where is the swelling located?",
            items: [
              "Inguinal (Swelling near the groin)",
              "Umbilical (Swelling around the belly button)",
              "Epigastric (Swelling in the upper stomach area)",
              "Femoral (Swelling near the upper thigh)",
              "Scrotal (Swelling in the scrotum)",
              "Around incision site (Swelling near a surgical wound)",
              "Generalized abdominal swelling (Swelling all over the stomach area)",
            ],
          },
        ],
      },
      {
        name: "Yellowing of the Skin or Eyes (Jaundice) (Skin or eyes look yellowish)",
      },
      {
        name: "Loss of Appetite (Not feeling hungry or not wanting to eat)",
      },
      {
        name: "Lot of burping (Frequent burping)",
      },
      {
        name: "Lot of hiccups (Frequent or continuous hiccups)",
      },
      {
        name: "Can’t digest food easily (Difficulty digesting food or feeling uncomfortable after eating)",
      },
      {
        name: "Abdominal injury or trauma (Hurt or damage to the stomach or belly area)",
      },
    ],
  },
  {
    name: "Arm",
    symptom: [
      {
        name: "Arm Pain (Hurting in or around the arm)",
      },
      {
        name: "Swelling in the Arm (Arm looks or feels swollen)",
      },
      {
        name: "Numbness or Tingling in the Arm (Loss of feeling or pins and needles in the arm)",
      },
      {
        name: "Weakness in the Arm (Arm feels weak or hard to move)",
      },
      {
        name: "Arm Jerking (Sudden, uncontrolled movements of the arm)",
      },
      {
        name: "Tremor or Shaking of the Arm (Involuntary shaking of the arm)",
      },
      {
        name: "Bruising or Discoloration of the Arm (Dark spots or changes in arm color)",
      },
      {
        name: "Ulcer in the Arm (Sore or wound on the arm)",
      },
      {
        name: "Difficulty Moving the Arm (Trouble moving the arm)",
      },
      {
        name: "Joint Pain (Shoulder, Elbow, Wrist) (Hurting in the joints of the arm)",
      },
      {
        name: "Skin Issues on the Arm (Problems with the skin on the arm)",
        option: [
          {
            name: "What kind of skin changes are on your arm?",
            items: [
              "Rash (Red, irritated spots or patches)",
              "Itching (Itchy feeling on the skin)",
              "Redness (Red or irritated skin)",
              "Blisters (Small fluid-filled bumps on the skin)",
              "Dryness (Dry, flaky skin)",
            ],
          },
        ],
      },
      {
        name: "Pain in the Finger (Hurting in the fingers)",
      },
      {
        name: "Difficulty Moving the Finger (Trouble moving the fingers)",
      },
      {
        name: "Numbness or Tingling in the Finger (Loss of feeling or pins and needles in the fingers)",
      },
      {
        name: "Color Change in the Finger (Fingers look or feel a different color)",
      },
      {
        name: "Swelling of the Finger (Fingers look or feel swollen)",
      },
      {
        name: "Tremor or Shaking of the Hand (Involuntary shaking of the hand)",
      },
      {
        name: "Nail Color Change (Nails look a different color)",
      },
      {
        name: "Nail Loss (Losing fingernails)",
      },
      {
        name: "Nail Dimorphic (Nails look unusual or different)",
      },
      {
        name: "Injury or Trauma to the Hand or Arm (Hurt or damage to the hand or arm)",
      },
      {
        name: "Fractures or Broken Bones (Broken or cracked bones in the arm or hand)",
      },
    ],
  },
  {
    name: "Back",
    symptom: [
      {
        name: "Back Pain (Hurting in the back)",
      },
      {
        name: "Back Pain During Standing or Moving (Pain when standing or moving)",
      },
      {
        name: "Back Pain When Lifting Objects (Hurting when lifting things)",
      },
      {
        name: "Stiffness in the Back (Difficulty moving the back)",
      },
      {
        name: "Numbness or Tingling (Loss of feeling or pins and needles in the back)",
      },
      {
        name: "Any Deformity in the Back Area (Back looks or feels abnormal)",
      },
      {
        name: "Bruising and Swelling in the Back (Dark spots or swelling in the back)",
      },
      {
        name: "Swelling in the Back (Back looks or feels swollen)",
      },
      {
        name: "Back Muscle Spasms (Sudden tightening or cramping in the back muscles)",
      },
      {
        name: "Weakness in the Back or Legs (Back or legs feel weak or hard to move)",
      },
      {
        name: "Back Bruising or Discoloration (Dark spots or changes in back color)",
      },
      {
        name: "Difficulty Standing or Sitting for Long Periods (Trouble staying in one position for a long time)",
      },
      {
        name: "Back Fractures or Bone Injuries (Broken or cracked bones in the back)",
      },
      {
        name: "Any Injury or Trauma to the Back (Hurt or damage to the back)",
      },
    ],
  },
  {
    name: "Buttock",
    symptom: [
      { name: "Pain in the Buttock Area (Hurting in the buttocks)" },
      {
        name: "Pain That Worsens With Movement or Sitting (Hurting more when you move or sit)",
      },
      {
        name: "Swelling in the Buttock Area (Buttocks are swollen or puffy)",
      },
      {
        name: "Numbness or Tingling in the Buttocks (Loss of feeling or pins and needles sensation)",
      },
      {
        name: "External Pain Around Your Anal Area (Hurting outside the anus)",
      },
      {
        name: "Internal Pain Around Your Anal Area (Hurting inside the anus)",
      },
      {
        name: "Burning Sensation During Defecation (Feels like cutting or tearing when pooping)",
      },
      { name: "Itching Around the Anus (Feeling itchy near the anus)" },
      { name: "Ulcer Around the Anus (Sore or wound near the anus)" },
      {
        name: "Abscess Near the Anus (A painful, swollen bump near the anus)",
      },
      {
        name: "Bright-Colored Bleeding From the Anus (Fresh blood when pooping)",
      },
      {
        name: "Dark Bleeding Mixed With Stool (Dark blood mixed with your poop)",
      },
      { name: "Discharge From the Anus (Fluid leaking from the anus)" },
      {
        name: "Protrusion of Mass Per Anal (A lump or mass sticking out of the anus)",
      },
      {
        name: "Visible Tear or Cut Near the Anus (Seeing a cut near the anus)",
      },
      {
        name: "Swelling or Lump Around the Anus (Swollen bump around the anus)",
      },
      {
        name: "Swelling From the Anus That Goes Away on Its Own (A swollen lump that disappears by itself)",
      },
      {
        name: "Swelling From the Anus That You Push Back Manually (A lump you can push back into place)",
      },
      {
        name: "Swelling From the Anus That Is Always Present and Cannot Be Pushed Back (A lump that stays and cannot be pushed back)",
      },
      {
        name: "Recent Trauma or Surgery in the Anal Area (Recent injury or surgery near the anus)",
      },
      { name: "Unable to Pass Gas or Stool (Can't release gas or poop)" },
      {
        name: "Sharp Pain During or After Bowel Movement (Severe hurting when or after pooping)",
      },
      {
        name: "Incontinence or Loss of Bowel Control (Can’t control bowel movements)",
      },
      { name: "Foul-Smelling Stool (Poop that smells really bad)" },
      { name: "Diarrhea (Frequent, watery stool)" },
      { name: "Constipation (Trouble pooping or not pooping often)" },
      { name: "Hard Stool (Stool is difficult to pass)" },
      { name: "Strain During Defecation (Pushing hard to poop)" },
      { name: "Stool Color Change (Poop looks a different color)" },
      {
        name: "Worms in Stool or Anus (Seeing worms in your poop or anus)",
      },
      {
        name: "Can’t Poop Completely (Feeling like you haven’t fully pooped)",
      },
      { name: "Want to Poop All the Time (Constant urge to poop)" },
    ],
  },
  {
    name: "Leg",
    symptom: [
      { name: "Leg Pain With Movement (Hurting when moving your leg)" },
      { name: "Ankle Pain (Hurting in the ankle)" },
      { name: "Ankle Redness (Ankle looks red)" },
      { name: "Ulcer on the Leg (Open sore or wound on the leg)" },
      {
        name: "Numbness or Tingling (Loss of feeling or pins and needles sensation)",
      },
      { name: "Weakness of One Leg (One leg feels weak)" },
      { name: "Weakness of Both Legs (Both legs feel weak)" },
      {
        name: "Weakness of the Leg Including the Body (Leg weakness along with body weakness)",
      },
      { name: "Cramping in the Leg (Muscle cramps in the leg)" },
      {
        name: "Visible and Swollen Blood Vessels in the Leg (Veins look bigger or swollen)",
      },
      { name: "Discoloration of the Leg (Leg looks a different color)" },
      { name: "Extreme Coldness in the Leg (Leg feels very cold)" },
      { name: "Ulcer on the Toe (Open sore or wound on the toe)" },
      { name: "Swelling of the Toe (Toe is swollen or puffy)" },
      { name: "Injuries or Trauma to the Leg (Recent injury to the leg)" },
      {
        name: "Leg Pain (Hurting in your leg)",
        option: [
          {
            name: "Where is the pain located?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
            ],
          },
        ],
      },
      {
        name: "Swelling (Leg is swollen or puffy)",
        option: [
          {
            name: "Where is the swelling located?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
              "Toe (Fingers on the foot)",
            ],
          },
        ],
      },
      {
        name: "Joint Pain (Hurting in the leg joints)",
        option: [
          {
            name: "Which part of the leg has joint pain?",
            items: [
              "Knee (Middle joint in the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Joint connecting the foot and leg)",
            ],
          },
        ],
      },
      {
        name: "Skin Changes on the Leg (Skin looks or feels different)",
        option: [
          {
            name: "What kind of skin changes are on your leg?",
            items: [
              "Rash (Red, irritated skin)",
              "Itching (Skin feels itchy)",
              "Redness (Leg looks red)",
              "Blisters (Fluid-filled bumps on the skin)",
              "Dryness (Skin feels dry and flaky)",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Skin",
    symptom: [
      { name: "Rash (Red, bumpy skin irritation)" },
      { name: "Itching (Feeling of needing to scratch the skin)" },
      { name: "Redness (Skin looks red)" },
      { name: "Blisters (Fluid-filled bumps on the skin)" },
      { name: "Irritated Skin (Skin feels inflamed or sensitive)" },
      { name: "Yellow Discoloration of the Skin (Skin looks yellowish)" },
      { name: "Elevated Skin Patch (A raised area on the skin)" },
      { name: "Flaky Skin Patch (Skin is peeling or flaking)" },
      {
        name: "Scaling, Peeling, and Flaking of Skin (Skin is shedding or coming off)",
      },
      {
        name: "Hive-like Rash on the Skin (Bumpy, raised, red spots similar to hives)",
      },
      { name: "Loss of Skin Color (Skin looks lighter in patches)" },
      { name: "Dryness (Skin feels rough or flaky)" },
      {
        name: "Burn (Skin feels like it's been burned or exposed to heat)",
      },
      { name: "Oily Skin (Skin feels greasy or shiny)" },
      { name: "Swelling (Skin is puffy or enlarged)" },
      { name: "Bruising (Skin has black-and-blue marks)" },
      { name: "Sores or Ulcers (Open sores on the skin)" },
      { name: "Color Changes (Skin looks different in color)" },
      { name: "Injury or Trauma to the Skin (Recent skin injury)" },
    ].map((symptom) => ({ ...symptom, option: commonOption })),
  },
];
const commonBabySymptomsWithBodyParts = [
  {
    name: "Head",
    symptom: [
      { name: "Head Injury or Trauma (Hit or injury to the head)" },
      { name: "Head Nodding (Involuntary shaking of the head)" },
      { name: "Loss of Balance (Trouble staying steady or walking)" },
      { name: "Irritability" },
      { name: "Fainting (Sudden loss of consciousness)" },
      { name: "Seizure (Sudden shaking or jerking movements)" },
    ],
  },
  {
    name: "Eye",
    symptom: [
      { name: "Itching (Feeling itchy in the eye)" },
      { name: "Redness of the Eye (Red or irritated eyes)" },
      { name: "Yellow Discoloration of the Eye (Yellowish eyes)" },
      {
        name: "Recent Dark Circle Around the Eye (New dark circles around the eyes)",
      },
      { name: "Excessive Tearing (Eyes watering too much)" },
      { name: "Eye Discharge (Fluid coming out of the eyes)" },
      {
        name: "Swelling Around the Eye (Puffy or swollen area around the eyes)",
      },
      { name: "Injury to the Eye (Hurt or damage to the eye)" },
    ],
  },
  {
    name: "Ear",
    symptom: [
      { name: "Hearing Loss (Trouble hearing)" },
      { name: "Ear Discharge (Fluid coming out of the ear)" },
      { name: "Ulcer in or Around the Ear (Sore or wound in the ear)" },
      { name: "Itching (Itchy feeling in the ear)" },
      { name: "Swelling on the Ear (Ear is puffy or swollen)" },
      { name: "Swelling Behind the Ear (Puffy area behind the ear)" },
      { name: "Foreign Body in the Ear (Something stuck in the ear)" },
      { name: "Injury to the Ear (Hurt or damage to the ear)" },
    ],
  },
  {
    name: "Nose",
    symptom: [
      { name: "Runny Nose (Fluid coming from the nose)" },
      { name: "Congestion (Stuffy nose)" },
      { name: "Sneezing" },
      { name: "Nose Bleeding (Blood coming from the nose)" },
      {
        name: "Ulcer in or Around the Nose (Sore or wound in or around the nose)",
      },
      { name: "Foreign Body in the Nose (Something stuck in the nose)" },
      { name: "Itching (Itchy feeling in the nose)" },
      { name: "Injury to the Nose (Hurt or damage to the nose)" },
    ],
  },
  {
    name: "Mouth and Throat",
    symptom: [
      { name: "Difficulty Swallowing (Hard to swallow)" },
      { name: "Dry Mouth (Mouth feels dry)" },
      {
        name: "Mouth Sores (Ulcer in the mouth) (Sores or ulcers in the mouth)",
      },
      { name: "White Patches in the Mouth (White spots inside the mouth)" },
      { name: "Bleeding Gum (Gums bleed easily)" },
      { name: "White Patches on the Tongue (White spots on the tongue)" },
      { name: "Swollen Tongue (Puffy or enlarged tongue)" },
      { name: "Ulcer on the Tongue (Sore on the tongue)" },
      { name: "Swollen Lips (Puffy or swollen lips)" },
    ],
  },
  {
    name: "Face",
    symptom: [
      { name: "Swelling of the Face (Puffy or swollen face)" },
      { name: "Yellow Face (Yellowish skin on the face)" },
      { name: "Twitching of the Face (Involuntary movements in the face)" },
      { name: "Pale Face (Face looks unusually pale)" },
      { name: "Itchy Face (Itchy skin on the face)" },
      { name: "Red Patch on the Cheek (Red spots or area on the cheek)" },
      { name: "Black Patch on the Cheek (Dark spots on the cheek)" },
      { name: "Rash on the Face (Red or irritated spots on the face)" },
      { name: "Injury to the Face (Hurt or damage to the face)" },
    ],
  },
  {
    name: "Scalp",
    symptom: [
      { name: "Itching (Itchy scalp)" },
      { name: "Dryness (Dry scalp)" },
      { name: "Ulcer on the Scalp (Sore or wound on the scalp)" },
      { name: "Redness (Red or irritated scalp)" },
      { name: "Swelling (Puffy or swollen scalp)" },
      { name: "Lumps (Bumps or lumps on the scalp)" },
      { name: "Hair Loss (Losing hair)" },
      { name: "Visible Rash or Scaling (Red, scaly patches on the scalp)" },
    ],
  },
  {
    name: "Neck",
    symptom: [
      { name: "Neck Stiffness (Difficulty moving the neck)" },
      { name: "Neck Pain During Movement (Pain when moving the neck)" },
      {
        name: "Swelling in the Neck (Neck looks or feels swollen)",
        option: [
          {
            name: "Where is the swelling?",
            items: [
              "Front of the Neck (Swelling in the front part of the neck)",
              "Sides of the Neck (Swelling on the sides of the neck)",
              "Back of the Neck (Swelling at the back of the neck)",
            ],
          },
        ],
      },
      { name: "Limited Range of Motion (Difficulty moving neck fully)" },
      { name: "Trauma or Injury Around the Neck (Hurt or damage to the neck)" },
    ],
  },
  {
    name: "Chest",
    symptom: [
      { name: "Fast Breathing" },
      { name: "Cough (Persistent coughing)" },
      {
        name: "Palpitations or Irregular Heartbeat (Heart feels like it's beating fast or irregularly)",
      },
      { name: "Wheezing (Whistling or high-pitched breathing sound)" },
      { name: "Swelling in the Chest Area (Chest looks or feels swollen)" },
      { name: "Breast Swelling (Swelling of the breast from the normal size)" },
      { name: "Breast Discharge (Any fluid coming out from the breast)" },
      { name: "Injury or Trauma of the Chest (Hurt or damage to the chest)" },
    ],
  },
  {
    name: "Abdomen",
    symptom: [
      {
        name: "Vomiting (Throwing up)",
        option: [
          {
            name: "What does the vomiting look like?",
            items: [
              "Yellow-green Vomiting (Vomiting yellow or green fluid)",
              "Vomiting Food Content (Throwing up food you ate)",
              "Coffee Ground-like Vomiting (Vomiting looks like coffee grounds)",
              "Bloody Vomiting (Throwing up blood)",
            ],
          },
        ],
      },
      { name: "Diarrhea (Frequent loose or watery stools)" },
      { name: "Constipation (Difficulty or infrequent bowel movements)" },
      { name: "Blood in Stool (Blood seen in bowel movements)" },
      { name: "Abdominal Swelling (Stomach or belly looks or feels swollen)" },
      {
        name: "Yellowing of the Skin or Eyes (Jaundice) (Skin or eyes look yellowish)",
      },
      {
        name: "Poor Feeding (Not wanting to breastfeed or formula milk or eat)",
      },
      { name: "Lot of Burping (Frequent burping)" },
      { name: "Lot of Hiccups (Frequent or continuous hiccups)" },
      {
        name: "Abdominal Injury or Trauma (Hurt or damage to the stomach or belly area)",
      },
      { name: "Unable to Pass Gas or Stool (Can't release gas or poop)" },
      { name: "Foul-Smelling Stool (Poop that smells really bad)" },
      {
        name: "Strain During Defecation (Pushing hard to poop or crying when trying to poop)",
      },
      { name: "Stool Color Change (Poop looks a different color)" },
    ],
  },
  {
    name: "Arm",
    symptom: [
      { name: "Swelling in the Arm (Arm looks or feels swollen)" },
      { name: "Weakness in the Arm (Arm feels weak or hard to move)" },
      { name: "Arm Jerking (Sudden, uncontrolled movements of the arm)" },
      { name: "Tremor or Shaking of the Arm (Involuntary shaking of the arm)" },
      {
        name: "Bruising or Discoloration of the Arm (Dark spots or changes in arm color)",
      },
      { name: "Ulcer in the Arm (Sore or wound on the arm)" },
      { name: "Skin Issues on the Arm (Problems with the skin on the arm)" },
      {
        name: "Color Change in the Finger (Fingers look or feel a different color)",
      },
      { name: "Swelling of the Finger (Fingers look or feel swollen)" },
      { name: "Nail Color Change (Nails look a different color)" },
      { name: "Nail Loss (Losing fingernails)" },
      { name: "Nail Dimorphic (Nails look unusual or different)" },
      {
        name: "Injury or Trauma to the Hand or Arm (Hurt or damage to the hand or arm)",
      },
      {
        name: "Fractures or Broken Bones (Broken or cracked bones in the arm or hand)",
      },
      {
        name: "Skin Issues on the Arm",
        option: [
          {
            name: "What kind of skin changes are on your child arm?",
            items: [
              "Rash (Red, irritated spots or patches)",
              "Itching (Itchy feeling on the skin)",
              "Redness (Red or irritated skin)",
              "Blisters (Small fluid-filled bumps on the skin)",
              "Dryness (Dry, flaky skin)",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Back",
    symptom: [
      {
        name: "Any Deformity in the Back Area (Back looks or feels abnormal)",
      },
      {
        name: "Bruising and Swelling in the Back (Dark spots or swelling in the back)",
      },
      {
        name: "Swelling in the Back (Back looks or feels swollen)",
      },
      {
        name: "Weakness in the Back or Legs (Back or legs feel weak or hard to move)",
      },
      {
        name: "Back Bruising or Discoloration (Dark spots or changes in back color)",
      },
      {
        name: "Difficulty Standing or Sitting (Trouble staying in one position)",
      },
      {
        name: "Back Fractures or Bone Injuries (Broken or cracked bones in the back)",
      },
      {
        name: "Any Injury or Trauma to the Back (Hurt or damage to the back)",
      },
    ],
  },
  {
    name: "Leg",
    symptom: [
      { name: "Leg Pain With Movement (Hurting when moving your child leg)" },
      { name: "Ulcer on the Leg (Open sore or wound on the leg)" },
      { name: "Weakness of Both Legs (Unable to move both legs)" },
      {
        name: "Weakness of the Leg Including the Body (Unable to move legs along with the body)",
      },
      { name: "Discoloration of the Leg (Leg looks a different color)" },
      { name: "Injuries or Trauma to the Leg (Recent injury to the leg)" },
      {
        name: "Swelling of the leg  (Leg is swollen or puffy)",
        option: [
          {
            name: "Where is the swelling located?",
            items: [
              "Below the Knee (Below the joint in the middle of the leg)",
              "Above the Knee (Above the joint in the middle of the leg)",
            ],
          },
        ],
      },
      {
        name: "Skin Changes on the Leg (Skin looks or feels different)",
        option: [
          {
            name: "What kind of skin changes are on your child leg?",
            items: [
              "Rash (Red, irritated skin)",
              "Itching (Skin feels itchy)",
              "Redness (Leg looks red)",
              "Blisters (Fluid-filled bumps on the skin)",
              "Dryness (Skin feels dry and flaky)",
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Skin",
    symptom: [
      { name: "Rash (Red, bumpy skin irritation)" },
      { name: "Itching (Feeling of needing to scratch the skin)" },
      { name: "Redness (Skin looks red)" },
      { name: "Blisters (Fluid-filled bumps on the skin)" },
      { name: "Irritated Skin (Skin feels inflamed or sensitive)" },
      { name: "Yellow Discoloration of the Skin (Skin looks yellowish)" },
      { name: "Elevated Skin Patch (A raised area on the skin)" },
      { name: "Flaky Skin Patch (Skin is peeling or flaking)" },
      {
        name: "Scaling, Peeling, and Flaking of Skin (Skin is shedding or coming off)",
      },
      {
        name: "Hive-like Rash on the Skin (Bumpy, raised, red spots similar to hives)",
      },
      { name: "Loss of Skin Color (Skin looks lighter in patches)" },
      { name: "Dryness (Skin feels rough or flaky)" },
      { name: "Burn (Skin feels like it's been burned or exposed to heat)" },
      { name: "Oily Skin (Skin feels greasy or shiny)" },
      { name: "Swelling (Skin is puffy or enlarged)" },
      { name: "Bruising (Skin has black-and-blue marks)" },
      { name: "Sores or Ulcers (Open sores on the skin)" },
      { name: "Color Changes (Skin looks different in color)" },
      { name: "Injury or Trauma to the Skin (Recent skin injury)" },
    ].map((symptom) => ({ ...symptom, option: commonOption })),
  },
];
const commonPediatricsSymptomsWithBodyParts = [
  {
    name: "Head",
    symptom: [
      { name: "Head Injury or Trauma (Hit or injury to the head)" },
      { name: "Head Shaking (Involuntary shaking of the head)" },
      {
        name: "Dizziness/Vertigo (Feeling unsteady or like the room is spinning)",
      },
      { name: "Loss of Balance (Trouble staying steady or walking)" },
      { name: "Behavioral Change (Changes in mood or behavior)" },
      { name: "Fainting (Sudden loss of consciousness)" },
      { name: "Seizure (Sudden shaking or jerking movements)" },
    ],
  },
  {
    name: "Eye",
    symptom: [
      { name: "Pain (Hurting in or around the eye)" },
      { name: "Itching (Feeling itchy in the eye)" },
      { name: "Redness of the Eye (Red or irritated eyes)" },
      { name: "Yellow Discoloration of the Eye (Yellowish eyes)" },
      { name: "Unable to See from Far (Difficulty seeing distant things)" },
      { name: "Unable to See from Near (Difficulty seeing things close up)" },
      {
        name: "Recent Dark Circle Around the Eye (New dark circles around the eyes)",
      },
      { name: "Sensitivity to Light (Eyes hurt or squint in bright light)" },
      { name: "Excessive Tearing (Eyes watering too much)" },
      { name: "Eye Discharge (Fluid coming out of the eyes)" },
      {
        name: "Swelling Around the Eye (Puffy or swollen area around the eyes)",
      },
      { name: "Injury to the Eye (Hurt or damage to the eye)" },
    ],
  },
  {
    name: "Ear",
    symptom: [
      { name: "Ear Pain (Hurting in or around the ear)" },
      { name: "Hearing Loss (Trouble hearing)" },
      { name: "Ear Discharge (Fluid coming out of the ear)" },
      { name: "Ulcer in or Around the Ear (Sore or wound in the ear)" },
      { name: "Itching (Itchy feeling in the ear)" },
      { name: "Swelling on the Ear (Ear is puffy or swollen)" },
      { name: "Swelling Behind the Ear (Puffy area behind the ear)" },
      { name: "Foreign Body in the Ear (Something stuck in the ear)" },
      { name: "Injury to the Ear (Hurt or damage to the ear)" },
    ],
  },
  {
    name: "Nose",
    symptom: [
      { name: "Runny Nose (Fluid coming from the nose)" },
      { name: "Congestion (Stuffy nose)" },
      { name: "Sneezing" },
      { name: "Nose Bleeding (Blood coming from the nose)" },
      {
        name: "Ulcer in or Around the Nose (Sore or wound in or around the nose)",
      },
      { name: "Pain (Hurting in or around the nose)" },
      { name: "Foreign Body in the Nose (Something stuck in the nose)" },
      { name: "Itching (Itchy feeling in the nose)" },
      { name: "Injury to the Nose (Hurt or damage to the nose)" },
    ],
  },
  {
    name: "Mouth and Throat",
    symptom: [
      { name: "Sore Throat (Pain in the throat)" },
      { name: "Difficulty Swallowing (Hard to swallow)" },
      { name: "Voice Change (Hoarseness) (Voice sounds different or raspy)" },
      {
        name: "Mouth Sores (Ulcer in the mouth) (Sores or ulcers in the mouth)",
      },
      { name: "White Patches in the Mouth (White spots inside the mouth)" },
      { name: "Swollen Gum (Puffy or swollen gums)" },
      { name: "Bleeding Gum (Gums bleed easily)" },
      { name: "White Patches on the Tongue (White spots on the tongue)" },
      { name: "Swollen Tongue (Puffy or enlarged tongue)" },
      { name: "Ulcer on the Tongue (Sore on the tongue)" },
      { name: "Swollen Lips (Puffy or swollen lips)" },
      { name: "Toothache (Pain in the teeth)" },
    ],
  },
  {
    name: "Face",
    symptom: [
      { name: "Swelling of the Face (Puffy or swollen face)" },
      { name: "Yellow Face (Yellowish skin on the face)" },
      { name: "Pale Face (Face looks unusually pale)" },
      { name: "Itchy Face (Itchy skin on the face)" },
      { name: "Red Patch on the Cheek (Red spots or area on the cheek)" },
      { name: "Black Patch on the Cheek (Dark spots on the cheek)" },
      { name: "Rash on the Face (Red or irritated spots on the face)" },
      { name: "Injury to the Face (Hurt or damage to the face)" },
    ],
  },
  {
    name: "Scalp",
    symptom: [
      { name: "Itching (Itchy scalp)" },
      { name: "Dryness (Dry scalp)" },
      { name: "Pain (Hurting on the scalp)" },
      { name: "Ulcer on the Scalp (Sore or wound on the scalp)" },
      { name: "Redness (Red or irritated scalp)" },
      { name: "Swelling (Puffy or swollen scalp)" },
      { name: "Lumps (Bumps or lumps on the scalp)" },
      { name: "Hair Loss (Losing hair)" },
      { name: "Visible Rash or Scaling (Red, scaly patches on the scalp)" },
    ],
  },
  {
    name: "Neck",
    symptom: [
      { name: "Neck Pain (Hurting in or around the neck)" },
      { name: "Neck Stiffness (Difficulty moving the neck)" },
      { name: "Neck Pain During Movement (Pain when moving the neck)" },
      {
        name: "Swelling in the Neck (Neck looks or feels swollen)",
        option: [
          {
            name: "Where is the swelling?",
            items: [
              "Front of the Neck (Swelling in the front part of the neck)",
              "Sides of the Neck (Swelling on the sides of the neck)",
              "Back of the Neck (Swelling at the back of the neck)",
            ],
          },
        ],
      },
      { name: "Pain During Swallowing (Hurting when swallowing)" },
      { name: "Trauma or Injury Around the Neck (Hurt or damage to the neck)" },
    ],
  },
  {
    name: "Chest",
    symptom: [
      { name: "Chest Pain (Hurting in the chest area)" },
      {
        name: "Fast Breathing or Shortness of Breath (Trouble breathing or feeling like you can't get enough air)",
      },
      { name: "Cough (Persistent coughing)" },
      { name: "Wheezing (Whistling or high-pitched breathing sound)" },
      { name: "Injury or Trauma of the Chest (Hurt or damage to the chest)" },
    ],
  },
  {
    name: "Abdomen",
    symptom: [
      {
        name: "Abdominal Pain (Hurting in the stomach or belly area)",
        option: [
          {
            name: "Where is the pain located?",
            items: [
              "Upper and Right Side of Abdomen (Pain in the upper right part of the belly)",
              "Upper and Left Side of the Abdomen (Pain in the upper left part of the belly)",
              "Lower and Right Side of the Abdomen (Pain in the lower right part of the belly)",
              "Lower and Left Side of the Abdomen (Pain in the lower left part of the belly)",
              "The Center of the Abdomen (Pain in the middle of the belly)",
            ],
          },
          {
            name: "What does your child vomiting look like?",
            items: [
              "Yellow-Green Vomiting (Vomiting yellow or green fluid)",
              "Vomiting Food Content (Throwing up food you ate)",
              "Coffee Ground-Like Vomiting (Vomiting looks like coffee grounds)",
              "Bloody Vomiting (Throwing up blood)",
            ],
          },
          {
            name: "Where is the swelling located?",
            items: [
              "Inguinal (Swelling near the groin)",
              "Umbilical (Swelling around the belly button)",
              "Epigastric (Swelling in the upper stomach area)",
              "Femoral (Swelling near the upper thigh)",
              "Scrotal (Swelling in the scrotum)",
              "Around Incision Site (Swelling near a surgical wound)",
              "Generalized Abdominal Swelling (Swelling all over the stomach area)",
            ],
          },
        ],
      },
      { name: "Nausea (Feeling like you want to throw up)" },
      { name: "Vomiting (Throwing up)" },
      {
        name: "Bloating of the Abdomen (Feeling of fullness or swelling in the stomach area)",
      },
      { name: "Diarrhea (Frequent loose or watery stools)" },
      { name: "Constipation (Difficulty or infrequent bowel movements)" },
      { name: "Blood in Stool (Blood seen in bowel movements)" },
      { name: "Bleeding from Anus (Blood coming from the anus)" },
      { name: "Abdominal Swelling (Stomach or belly looks or feels swollen)" },
      {
        name: "Yellowing of the Skin or Eyes (Jaundice) (Skin or eyes look yellowish)",
      },
      { name: "Loss of Appetite (Not feeling hungry or not wanting to eat)" },
      { name: "Lot of Burping (Frequent burping)" },
      { name: "Lot of Hiccups (Frequent or continuous hiccups)" },
      {
        name: "Abdominal Injury or Trauma (Hurt or damage to the stomach or belly area)",
      },
    ],
  },
  {
    name: "Arm",
    symptom: [
      { name: "Arm Pain (Hurting in or around the arm)" },
      { name: "Swelling in the Arm (Arm looks or feels swollen)" },
      { name: "Weakness in the Arm (Arm feels weak or hard to move)" },
      { name: "Arm Jerking (Sudden, uncontrolled movements of the arm)" },
      { name: "Tremor or Shaking of the Arm (Involuntary shaking of the arm)" },
      {
        name: "Bruising or Discoloration of the Arm (Dark spots or changes in arm color)",
      },
      { name: "Ulcer in the Arm (Sore or wound on the arm)" },
      { name: "Difficulty Moving the Arm (Trouble moving the arm)" },
      {
        name: "Joint Pain (Shoulder, Elbow, Wrist) (Hurting in the joints of the arm)",
      },
      {
        name: "Skin Issues on the Arm (Problems with the skin on the arm)",
        option: [
          {
            name: "What kind of skin changes are on your child arm?",
            items: [
              "Rash (Red, irritated spots or patches)",
              "Itching (Itchy feeling on the skin)",
              "Redness (Red or irritated skin)",
              "Blisters (Small fluid-filled bumps on the skin)",
              "Dryness (Dry, flaky skin)",
            ],
          },
        ],
      },
      { name: "Pain in the Finger (Hurting in the fingers)" },
      { name: "Difficulty Moving the Finger (Trouble moving the fingers)" },
      {
        name: "Color Change in the Finger (Fingers look or feel a different color)",
      },
      { name: "Swelling of the Finger (Fingers look or feel swollen)" },
      {
        name: "Tremor or Shaking of the Hand (Involuntary shaking of the hand)",
      },
      { name: "Nail Color Change (Nails look a different color)" },
      { name: "Nail Loss (Losing fingernails)" },
      { name: "Nail Dimorphic (Nails look unusual or different)" },
      {
        name: "Injury or Trauma to the Hand or Arm (Hurt or damage to the hand or arm)",
      },
      {
        name: "Fractures or Broken Bones (Broken or cracked bones in the arm or hand)",
      },
    ],
  },
  {
    name: "Back",
    symptom: [
      { name: "Back Pain (Hurting in the back)" },
      { name: "Any Deformity in the Back Area (Back looks or feels abnormal)" },
      {
        name: "Bruising and Swelling in the Back (Dark spots or swelling in the back)",
      },
      { name: "Swelling in the Back (Back looks or feels swollen)" },
      {
        name: "Weakness in the Back or Legs (Back or legs feel weak or hard to move)",
      },
      {
        name: "Difficulty Standing or Sitting for Long Periods (Trouble staying in one position for a long time)",
      },
      {
        name: "Back Fractures or Bone Injuries (Broken or cracked bones in the back)",
      },
      { name: "Any Injury or Trauma to the Back (Hurt or damage to the back)" },
    ],
  },
  {
    name: "Buttock",
    symptom: [
      { name: "Pain in the Buttock Area (Hurting in the buttocks)" },
      { name: "Itching Around the Anus (Feeling itchy near the anus)" },
      { name: "Ulcer Around the Anus (Sore or wound near the anus)" },
      {
        name: "Bright-Colored Bleeding From the Anus (Fresh blood when pooping)",
      },
      {
        name: "Dark Bleeding Mixed With Stool (Dark blood mixed with your child poop)",
      },
      {
        name: "Recent Trauma or Surgery in the Anal Area (Recent injury or surgery near the anus)",
      },
      { name: "Unable to Pass Gas or Stool (Can't release gas or poop)" },
      {
        name: "Incontinence or Loss of Bowel Control (Can’t control bowel movements)",
      },
      { name: "Foul-Smelling Stool (Poop that smells really bad)" },
      { name: "Diarrhea (Frequent, watery stool)" },
      { name: "Constipation (Trouble pooping or not pooping often)" },
      { name: "Strain During Defecation (Pushing hard to poop)" },
      { name: "Stool Color Change (Poop looks a different color)" },
      {
        name: "Worms in Stool or Anus (Seeing worms in your child poop or anus)",
      },
    ],
  },
  {
    name: "Leg",
    symptom: [
      {
        name: "Leg Pain (Hurting in your child leg)",
        option: [
          {
            name: "Where is the pain located?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
            ],
          },

          {
            name: "Which part of the leg has joint pain?",
            items: [
              "Knee (Middle joint in the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Joint connecting the foot and leg)",
            ],
          },
        ],
      },
      {
        name: "Swelling (Leg is swollen or puffy)",
        option: [
          {
            name: "Where is the swelling located?",
            items: [
              "Thigh (Upper part of the leg)",
              "Knee (Joint in the middle of the leg)",
              "Calf (Back of the lower leg)",
              "Ankle (Where the leg meets the foot)",
              "Foot (Bottom part of the leg)",
              "Toe (Fingers on the foot)",
            ],
          },
        ],
      },
      { name: "Ulcer on the Leg (Open sore or wound on the leg)" },
      { name: "Weakness of One Leg (One leg feels weak)" },
      { name: "Weakness of Both Legs (Both legs feel weak)" },
      {
        name: "Weakness of the Leg Including the Body (Leg weakness along with body weakness)",
      },
      { name: "Joint Pain (Hurting in the leg joints)", option: [] },
      {
        name: "Skin Changes on the Leg (Skin looks or feels different)",
        option: [
          {
            name: "What kind of skin changes are on your child leg?",
            items: [
              "Rash (Red, irritated skin)",
              "Itching (Skin feels itchy)",
              "Redness (Leg looks red)",
              "Blisters (Fluid-filled bumps on the skin)",
              "Dryness (Skin feels dry and flaky)",
            ],
          },
        ],
      },
      { name: "Discoloration of the Leg (Leg looks a different color)" },
      { name: "Injuries or Trauma to the Leg (Recent injury to the leg)" },
    ],
  },
  {
    name: "Skin",
    symptom: [
      { name: "Rash (Red, bumpy skin irritation)" },
      { name: "Itching (Feeling of needing to scratch the skin)" },
      { name: "Redness (Skin looks red)" },
      { name: "Blisters (Fluid-filled bumps on the skin)" },
      { name: "Irritated Skin (Skin feels inflamed or sensitive)" },
      { name: "Yellow Discoloration of the Skin (Skin looks yellowish)" },
      { name: "Elevated Skin Patch (A raised area on the skin)" },
      { name: "Flaky Skin Patch (Skin is peeling or flaking)" },
      {
        name: "Scaling, Peeling, and Flaking of Skin (Skin is shedding or coming off)",
      },
      {
        name: "Hive-like Rash on the Skin (Bumpy, raised, red spots similar to hives)",
      },
      { name: "Loss of Skin Color (Skin looks lighter in patches)" },
      { name: "Dryness (Skin feels rough or flaky)" },
      { name: "Burn (Skin feels like it's been burned or exposed to heat)" },
      { name: "Swelling (Skin is puffy or enlarged)" },
      { name: "Bruising (Skin has black-and-blue marks)" },
      { name: "Sores or Ulcers (Open sores on the skin)" },
      { name: "Color Changes (Skin looks different in color)" },
      { name: "Injury or Trauma to the Skin (Recent skin injury)" },
    ].map((symptom) => ({ ...symptom, option: commonOption })),
  },
];
export const symptomGuidanceData = [
  {
    name: "Male Adult Symptoms",
    bodyParts: [
      ...commonAdultSymptomsWithBodyParts,
      {
        name: "Pelvis",
        symptom: [
          { name: "Pelvic Pain (Hurting in the pelvic area)" },
          { name: "Hip Bone Tenderness (Hip feels sore when touched)" },
          {
            name: "Hip Feels Like It's Popping Out of the Socket (Hip feels unstable)",
          },
          {
            name: "Difficulty Walking or Standing From a Chair (Trouble walking or standing up)",
          },
          { name: "Pain During Movement (Hurting when moving)" },
          { name: "Limited Range of Motion (Difficulty moving the pelvis)" },
          {
            name: "Stiffness in the Pelvic Area (Pelvis feels tight or hard to move)",
          },
          {
            name: "Bruising and Swelling (Dark spots or swelling in the pelvic area)",
          },
          { name: "Visible Deformity of the Pelvis (Pelvis looks abnormal)" },
          {
            name: "Numbness or Tingling in the Groin or Legs (Loss of feeling or pins and needles in the groin or legs)",
          },
          { name: "Groin Pain (Hurting in the groin area)" },
          { name: "Swelling in the Groin Area (Groin looks or feels swollen)" },
          {
            name: "Urinary Symptoms (Problems with urination)",
            option: [
              {
                name: "What urinary issues are you experiencing?",
                items: [
                  "Urinary Incontinence (Trouble controlling urine)",
                  "Immediate Urge to Pee (Feeling the need to pee right away)",
                  "Frequently Need to Pee (Going to the bathroom often)",
                  "Poor Flow During Urination (Weak stream of urine)",
                  "Intermittent Stream During Urination (Urine flow stops and starts)",
                  "Dribbling (Urine leaking after finishing)",
                  "Sensation of Poor Bladder Emptying (Feeling like the bladder doesn't fully empty)",
                  "Bladder Distention (Bladder feels swollen or full)",
                  "Difficulty Passing Urine (Trouble peeing)",
                  "Difficulty Initiating Urine (Trouble starting to pee)",
                  "Blood in Urine (Seeing blood when peeing)",
                  "Decrease in Urine Volume (Peeing less than usual)",
                  "Increase in Urine Volume (Peeing more than usual)",
                  "Absence of Urine (Not able to pee)",
                  "Pain During Urination (Hurting when peeing)",
                  "Small Stones in Urine (Seeing tiny stones when peeing)",
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Genitalia",
        symptom: [
          {
            name: "Abnormal Swelling of the Penis (Penis looks or feels swollen)",
          },
          { name: "Scrotal Swelling (Swollen area in the scrotum)" },
          {
            name: "Abnormal Discharge From the Penis (Fluid from the penis, possibly with a bad smell)",
          },
          {
            name: "Pain and Tenderness Around the Scrotum (Hurting or sore scrotum)",
          },
          {
            name: "Itching Around the Genitalia (Itchy feeling in the genital area)",
          },
          { name: "Lump in the Genital Area (Bump or lump near the genitals)" },
          {
            name: "Lump Between Genitalia and Anus (Bump or lump between the genitals and anus)",
          },
          { name: "Ulcer on the Penis (Sore or wound on the penis)" },
          { name: "Rash on the Penis (Red or irritated spots on the penis)" },
          { name: "Swelling Around the Penis (Swollen area near the penis)" },
          { name: "Penis Pulled In (Penis seems to be pulled inward)" },
          { name: "Uncircumcised Penis (Penis with foreskin intact)" },
          {
            name: "Empty or Undescended Testicle (Testicle not in the scrotum)",
          },
          { name: "Genital Numbness (Loss of feeling in the genitals)" },
          { name: "Ambiguous Genitalia (Genital appearance is unclear)" },
          {
            name: "Visible Lice and Nits (Seeing lice or nits in the genital area)",
          },
          {
            name: "Sexual-Related Symptoms (Issues related to sexual function)",
            option: [
              {
                name: "What sexual problems are you experiencing?",
                items: [
                  "Painful Erection (Hurting when the penis is erect)",
                  "Pain During Sex (Hurting during sexual activity)",
                  "Increased Sexual Desire (Feeling more desire for sex)",
                  "Decreased Sexual Desire (Feeling less desire for sex)",
                  "Ejaculates Sooner During Sex (Reaching climax too quickly)",
                  "Erection That Won’t Go Down (Erection lasting too long)",
                  "Infertility (Trouble having children)",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Female Adult Symptoms",
    bodyParts: [
      ...commonAdultSymptomsWithBodyParts,
      {
        name: "Breast",
        symptom: [
          {
            name: "Pain in the breast (Hurting in or around the breast)",
          },

          { name: "Swelling in the breast (Breast looks or feels swollen)" },
          { name: "Lump in the breast (A bump or mass felt in the breast)" },
          { name: "Discharge from the breast (Fluid leaking from the breast)" },
          { name: "Redness in the breast (Breast looks red or irritated)" },
          {
            name: "Changes in the skin of the breast (Breast skin looks different or unusual)",
          },
          { name: "Bloody nipple discharge (Blood coming from the nipple)" },
          { name: "Ulcer in the breast (Sore or wound in the breast)" },
          { name: "Ulcer in the nipple (Sore or wound on the nipple)" },
          { name: "Nipple redness (Red or irritated nipple)" },
          { name: "Fluid leaking from nipple (Fluid coming from the nipple)" },
          {
            name: "Breast getting hard like stone (Breast feels hard or firm)",
          },
          {
            name: "Breast getting bigger (Breast appears to be increasing in size)",
          },
          { name: "Breast not developing (Breast not growing as expected)" },
          {
            name: "Abnormal milk coming from breast (Milk coming from the breast when not breastfeeding)",
          },
          {
            name: "Injury or trauma to the breast (Hurt or damage to the breast)",
          },
        ],
      },
      {
        name: "Pelvis",
        symptom: [
          {
            name: "Pelvic Pain (Hurting in the pelvic area)",
          },
          {
            name: "Hip Bone Tenderness (Hip feels sore when touched)",
          },
          {
            name: "Hip Feels Like It's Popping Out of the Socket (Hip feels unstable)",
          },
          {
            name: "Difficulty Walking or Standing From a Chair (Trouble walking or standing up)",
          },
          {
            name: "Pain During Movement (Hurting when moving)",
          },
          {
            name: "Limited Range of Motion (Difficulty moving the pelvis)",
          },
          {
            name: "Severe Pain in the Pelvic Region (Intense hurting in the pelvic area)",
          },
          {
            name: "Stiffness in the Pelvic Area (Pelvis feels tight or hard to move)",
          },
          {
            name: "Bruising and Swelling (Dark spots or swelling in the pelvic area)",
          },
          {
            name: "Visible Deformity of the Pelvis (Pelvis looks abnormal)",
          },
          {
            name: "Numbness or Tingling in the Groin or Legs (Loss of feeling or pins and needles in the groin or legs)",
          },
          {
            name: "Groin Pain (Hurting in the groin area)",
          },
          {
            name: "Swelling in the Groin Area (Groin looks or feels swollen)",
          },
          {
            name: "Urinary Symptoms (Problems with urination)",
            option: [
              {
                name: "What urinary issues are you experiencing?",
                items: [
                  "Urinary Incontinence (Trouble controlling urine)",
                  "Urine Leakage (Urine leaking unintentionally)",
                  "Immediate Urge to Pee (Feeling the need to pee right away)",
                  "Frequently Need to Pee (Going to the bathroom often)",
                  "Poor Flow During Urination (Weak stream of urine)",
                  "Intermittent Stream During Urination (Urine flow stops and starts)",
                  "Dribbling (Urine leaking after finishing)",
                  "Sensation of Poor Bladder Emptying (Feeling like the bladder doesn't fully empty)",
                  "Bladder Distention (Bladder feels swollen or full)",
                  "Difficulty Passing Urine (Trouble peeing)",
                  "Difficulty Initiating Urine (Trouble starting to pee)",
                  "Blood in Urine (Seeing blood when peeing)",
                  "Decrease in Urine Volume (Peeing less than usual)",
                  "Increase in Urine Volume (Peeing more than usual)",
                  "Absence of Urine (Not able to pee)",
                  "Pain During Urination (Hurting when peeing)",
                  "Small Stones in Urine (Seeing tiny stones when peeing)",
                  "Previous Recurrent Urinary Tract Infections (History of repeated UTIs)",
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Genitalia",
        symptom: [
          {
            name: "Genital Pain or Discomfort (Hurting or discomfort in the genital area)",
          },
          {
            name: "Genital Swelling (Genital area looks or feels swollen)",
          },
          {
            name: "Genital Sores or Lesions (Sores or wounds in the genital area)",
          },
          {
            name: "Inflammation of the Vagina (Swelling or irritation in the vagina)",
          },
          {
            name: "Lump in the Genital Area (Bump or lump near the genitals)",
          },
          {
            name: "Lump in the Groin (Bump or lump in the groin area)",
          },
          {
            name: "Vaginal Dryness (Feeling dry in the vagina)",
          },
          {
            name: "Vaginal Gas (Air coming from the vagina)",
          },
          {
            name: "Foul-Smelling Vaginal Discharge (Bad-smelling fluid from the vagina)",
          },
          {
            name: "Bloody Vaginal Discharge (Blood in vaginal fluid)",
          },
          {
            name: "Blood Other Than Menstruation (Bleeding when it’s not your period)",
          },
          {
            name: "Cheesy Vaginal Discharge (Thick, white discharge from the vagina)",
          },
          {
            name: "Clitoral Enlargement (Clitoris is larger than usual)",
          },
          {
            name: "Inguinal Lymph Node Abscess, Tenderness, or Enlargement (Sore or swollen lymph nodes in the groin)",
          },
          {
            name: "Itching in the Pubic Hair Area (Itchy feeling around the pubic hair)",
          },
          {
            name: "Pubic Hair Lice (Seeing lice in the pubic hair)",
          },
          {
            name: "Itchy Vaginal Area (Itchy feeling around the vagina)",
          },
          {
            name: "Rash Around Genitalia (Red or irritated skin near the genitals)",
          },
          {
            name: "Previous Pelvic Trauma (Past injury to the pelvic area)",
          },
          {
            name: "Sensation/Visualization of Vaginal Bulge (Feeling or seeing a bulge in the vagina)",
          },
          {
            name: "Pelvic Pressure or Heaviness (Feeling pressure or heaviness in the pelvic area)",
          },
          {
            name: "Feeling of Incomplete Emptying of Urine or Feces (Feeling like you haven’t fully peed or passed stool)",
          },
          {
            name: "Menstrual-Related Symptoms (Problems related to your period)",
            option: [
              {
                name: "What period-related issues are you experiencing?",
                items: [
                  "Delayed or Late Period (Period comes later than usual)",
                  "Heavy Irregular Period (Unusually heavy or irregular bleeding)",
                  "Period is Lighter (Less bleeding than normal)",
                  "Irregularly Frequent Period (Period comes more often than usual)",
                  "No Period Ever (Never had a period)",
                  "Painful Period (Hurting during your period)",
                  "Spotting or Bleeding Between Periods (Bleeding between periods)",
                  "Symptoms like Hot Flashes, Mood Changes, or Sleep Issues Related to Menopause (Issues associated with menopause)",
                  "Early Menopause (Period stopped earlier than expected)",
                ],
              },
            ],
          },
          {
            name: "Sexual-Related Symptoms (Problems related to sexual function)",
            option: [
              {
                name: "What sexual problems are you experiencing?",
                items: [
                  "Pain While Peeing (Hurting when peeing)",
                  "Pain During Sex (Hurting during sexual activity)",
                  "Bleeding After Sex (Bleeding after sexual activity)",
                  "Increased Sexual Desire (Feeling more desire for sex)",
                  "Decreased Sexual Desire (Feeling less desire for sex)",
                  "Previous Diagnosis of Sexually Transmitted Disease (History of STDs)",
                ],
              },
            ],
          },
          {
            name: "Infertility (Trouble having children)",
          },
        ],
      },
    ],
  },
  {
    name: "Pregnant Symptoms",
    bodyParts: [
      {
        name: "Head",
        symptom: [
          { name: "Headache (Pain in the head)" },
          {
            name: "Severe Convulsions (Sudden uncontrollable shaking or loss of consciousness)",
          },
          { name: "Dizziness (Feeling lightheaded or unsteady)" },
          { name: "Depression (Feeling low, sad, or hopeless)" },
          {
            name: "Increased Sense of Smell (Smelling things more strongly than usual)",
          },
          { name: "Nasal Congestion (Blocked or stuffy nose)" },
        ],
      },
      {
        name: "Neck",
        symptom: [{ name: "Neck Swelling (Swollen or enlarged neck)" }],
      },
      {
        name: "Breast",
        symptom: [
          { name: "Breast Pain (Pain or tenderness in the breast)" },
          { name: "Early Breast Milk (Leaking milk before childbirth)" },
        ],
      },
      {
        name: "Chest",
        symptom: [
          {
            name: "Fast or Difficulty of Breath (Rapid or hard-to-catch breath)",
          },
        ],
      },
      {
        name: "Abdomen",
        symptom: [
          { name: "Epigastric Pain (Pain in the upper stomach area)" },
          { name: "Contraction (Feeling of tightening in the belly)" },
          { name: "Heartburn (Burning sensation in the chest after eating)" },
          {
            name: "Bloating or Feeling of Fullness (Abdomen feels full or swollen)",
          },
          { name: "Nausea (Feeling like you might vomit)" },
          {
            name: "Morning Sickness (Vomiting in the morning, often in pregnancy)",
          },
          { name: "Excessive Vomiting (Vomiting more than usual)" },
          { name: "Loss of Appetite (Not feeling hungry)" },
          { name: "Severe Abdominal Pain (Intense stomach pain)" },
          { name: "Contractions of Labor (Labor pains)" },
          {
            name: "Increased or Decreased Fetal Movement (More or fewer movements from the baby)",
          },
          { name: "Constipation (Difficulty passing stool)" },
        ],
      },
      {
        name: "Pelvis",
        symptom: [
          { name: "Pelvic Pressure (Feeling of heaviness in the pelvic area)" },
          {
            name: "Pushing Down Pain (Pain that feels like pressure pushing down)",
          },
          { name: "Vaginal Bleeding (Any bleeding from the vagina)" },
          {
            name: "Leakage of Liquor (Sudden leakage of watery fluid from the vagina)",
          },
          { name: "Decrease in Urine Output (Less urine than normal)" },
          {
            name: "Burning Sensation During Urination (Burning feeling when peeing)",
          },
          {
            name: "Vaginal Itching, Pain, or Swelling (Itching, pain, or swelling in the vaginal area)",
          },
        ],
      },
      {
        name: "Back",
        symptom: [{ name: "Back Pain (Pain in the back)" }],
      },
      {
        name: "Leg",
        symptom: [
          { name: "Swelling (Puffy or enlarged legs)" },
          { name: "Varicose Veins (Swollen or enlarged veins)" },
          { name: "Leg Cramps (Painful muscle spasms in the legs)" },
        ],
      },
      {
        name: "Other General Symptoms",
        symptom: [
          { name: "Fatigue (Feeling extremely tired)" },
          {
            name: "Food Cravings or Aversions (Strong desires for or dislike of certain foods)",
          },
          { name: "Mood Swings (Sudden changes in mood)" },
          { name: "Sleep Disturbance (Trouble sleeping or staying asleep)" },
        ],
      },
      {
        name: "Skin",
        symptom: [
          { name: "Rash (Red or irritated skin)" },
          { name: "Itching (Feeling of needing to scratch the skin)" },
          { name: "Yellowish Discoloration (Skin looks yellowish)" },
        ],
      },
    ],
  },
  {
    name: "Male Baby Symptoms",
    bodyParts: [
      ...commonBabySymptomsWithBodyParts,
      {
        name: "Genitalia",
        symptom: [
          {
            name: "Abnormal Swelling of the Penis (Penis looks or feels swollen)",
          },
          { name: "Scrotal Swelling (Swollen area in the scrotum)" },
          {
            name: "Abnormal Discharge From the Penis (Fluid from the penis, possibly with a bad smell)",
          },
          { name: "Irritability Around the Scrotum (Hurting or sore scrotum)" },
          {
            name: "Itching Around the Genitalia (Itchy feeling in the genital area)",
          },
          { name: "Ulcer on the Penis (Sore or wound on the penis)" },
          { name: "Rash on the Penis (Red or irritated spots on the penis)" },
          { name: "Swelling Around the Penis (Swollen area near the penis)" },
          { name: "Penis Pulled In (Penis seems to be pulled inward)" },
          { name: "Uncircumcised Penis (Penis with foreskin intact)" },
          {
            name: "Empty or Undescended Testicle (Testicle not in the scrotum)",
          },
          { name: "Ambiguous Genitalia (Genital appearance is unclear)" },
          { name: "Diaper Rash (Rash around the diaper area)" },
          {
            name: "Bleeding Around the Genitalia Area (Seeing bleeding around the genitalia area)",
          },
          {
            name: "Urinary Symptoms",
            option: [
              {
                name: "What urinary issues are you experiencing?",
                items: [
                  "Frequently wet diaper (changing diaper frequently from usual day)",
                  "Blood in Urine (Seeing blood when peeing)",
                  "Decrease in Urine Volume (Peeing less than usual or changing diaper less than usual)",
                  "Absence of Urine (Not able to pee)",
                  "Pain During Urination (Hurting or cry when peeing)",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Female Baby Symptoms",
    bodyParts: [
      ...commonBabySymptomsWithBodyParts,
      {
        name: "Genitalia",
        symptom: [
          {
            name: "Foul-Smelling Vaginal Discharge (Bad-smelling fluid from the vagina)",
          },
          { name: "Bloody from Vagina (Seeing blood from the vagina)" },
          {
            name: "Blood Other Than Menstruation (Bleeding when it’s not your period)",
          },
          { name: "Clitoral Enlargement (Clitoris is larger than usual)" },
          { name: "Itchy Vaginal Area (Itchy feeling around the vagina)" },
          {
            name: "Rash Around Genitalia (Red or irritated skin near the genitals)",
          },
          { name: "Previous Pelvic Trauma (Past injury to the pelvic area)" },
          { name: "Ambiguous Genitalia (Genital appearance is unclear)" },
          {
            name: "Urinary Symptoms",
            option: [
              {
                name: "What urinary issues are you experiencing?",
                items: [
                  "Frequently wet diaper (changing diaper frequently from usual day)",
                  "Blood in Urine (Seeing blood when peeing)",
                  "Decrease in Urine Volume (Peeing less than usual or changing diaper less than usual)",
                  "Absence of Urine (Not able to pee)",
                  "Pain During Urination (Hurting or cry when peeing)",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Male Pediatrics Symptoms",
    bodyParts: [
      ...commonPediatricsSymptomsWithBodyParts,
      {
        name: "Genitalia",
        symptom: [
          {
            name: "Abnormal Swelling of the Penis (Penis looks or feels swollen)",
          },
          { name: "Scrotal Swelling (Swollen area in the scrotum)" },
          {
            name: "Pain and Tenderness Around the Scrotum (Hurting or sore scrotum)",
          },
          {
            name: "Itching Around the Genitalia (Itchy feeling in the genital area)",
          },
          { name: "Lump in the Genital Area (Bump or lump near the genitals)" },
          {
            name: "Lump Between Genitalia and Anus (Bump or lump between the genitals and anus)",
          },
          { name: "Ulcer on the Penis (Sore or wound on the penis)" },
          { name: "Rash on the Penis (Red or irritated spots on the penis)" },
          { name: "Swelling Around the Penis (Swollen area near the penis)" },
          { name: "Penis Pulled In (Penis seems to be pulled inward)" },
          { name: "Uncircumcised Penis (Penis with foreskin intact)" },
          { name: "Ambiguous Genitalia (Genital appearance is unclear)" },
          {
            name: "Urinary Symptoms",
            option: [
              {
                name: "What urinary issues are you experiencing?",
                items: [
                  "Urinary Incontinence (Trouble controlling urine)",
                  "Frequently Need to Pee (Going to the bathroom often)",
                  "Bladder Distention (Bladder feels swollen or full)",
                  "Blood in Urine (Seeing blood when peeing)",
                  "Decrease in Urine Volume (Peeing less than usual)",
                  "Increase in Urine Volume (Peeing more than usual)",
                  "Absence of Urine (Not able to pee)",
                  "Pain During Urination (Hurting when peeing)",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Female Pediatrics Symptoms",
    bodyParts: [...commonPediatricsSymptomsWithBodyParts],
  },
];

function calculateJsonStorageSize(data) {
  // Convert the object to a JSON string
  const jsonString = JSON.stringify(data);

  // Calculate the UTF-8 byte size
  const byteSize = new TextEncoder().encode(jsonString).length;

  // Log results
  console.log(`JSON String: ${jsonString}`);
  console.log(`Character Length: ${jsonString.length}`);
  console.log(`UTF-8 Byte Size: ${byteSize} bytes`);

  return byteSize;
}

const size = calculateJsonStorageSize(generalClinicalGuidanceFormData);
console.log(`Storage size required: ${size} bytes`);

export const hospitalFields = [
  {
    name: "hospitalName",
    label: "Hospital name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },

  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
  {
    name: "hospitalType",
    label: "Hospital Type",
    type: "select",
    options: [
      { value: "cardiology", label: "Cardiology" },
      { value: "neurology", label: "Neurology" },
      { value: "oncology", label: "Oncology" },
      // ... add more options for specialties
    ], // Array of options for hospital types
  },
  {
    name: "specialty",
    label: "Specialty",
    type: "select",
    options: [
      { value: "general", label: "General" },
      { value: "childrens", label: "Children's" },
      { value: "cardiac", label: "Cardiac" },
      // ... add more options for hospital types
    ], // Array of options for specialties
  },
];
export const pharmacyFields = [
  {
    name: "pharmacyName",
    label: "Pharmacy name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },

  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
];
export const diagnosticsFields = [
  {
    name: "diagnosticName",
    label: "Diagnostic center name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },
  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
];
export const doctorFields = [
  { name: "firstName", label: "First Name", type: "text" },
  { name: "lastName", label: "Last Name", type: "text" },
  { name: "licenseNumber", label: "License Number", type: "number" },
  {
    name: "specialty",
    label: "Specialty",
    type: "select",
    options: [
      { value: "", label: "" },
      { value: "familyMedicine", label: "Family Medicine" },
      { value: "internalMedicine", label: "Internal Medicine" },
      { value: "pediatrics", label: "Pediatrics" },
    ],
  },
  { name: "email", label: "Email", type: "email" },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password" },
  { name: "confirmPassword", label: "Confirm Password", type: "password" },
];
export const patientSignUpFields = [
  { label: "Name", name: "fullName", type: "text", required: true },
  { label: "Birth Year", name: "birthYear", type: "date" },
  {
    label: "Sex",
    name: "sex",
    type: "select",
    options: ["", "male", "female"],
  },
  { label: "Email", name: "email", type: "email" },
];
//
export const PatientGeneralFormData = {
  title: "GENERAL CLINICAL GUIDANCE",
  adultFields: [
    {
      type: "radioGroup",
      label: "Are you alcoholic?",
      name: "alcoholic",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "Do you have a history of diseases that are listed below?",
      name: "diabetes",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "Hypertension",
      name: "hypertension",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "Cardiac illness",
      name: "cardiac",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "If female, are you pregnant?",
      name: "pregnant",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "textarea",
      label: "Medication history",
      name: "medication_history",
    },
    {
      type: "textarea",
      label: "Past medical history",
      name: "past_medical_history",
    },
  ],
  childFields: [
    {
      type: "radioGroup",
      label: "Brief medical history",
      name: "smoker",
      options: ["Yes", "No"],
      required: true,
    },
    {
      type: "radioGroup",
      label: "Do you have a history of diseases that are listed below?",
      name: "diabetes",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "HIV/AIDS",
      name: "hivAids",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "History of drug allergy",
      name: "drugAllergy",
      options: ["Yes", "No"],
    },
  ],
};
export const sidebarMainData = [
  { path: "index", label: "Index" },
  { path: "home", label: "Home" },
  { path: "find-doctor", label: "Find doctor" },
  { path: "find_right_doctor", label: "Find Right doctor" },
  { path: "health_feed", label: "Health feed" },
  { path: "order_Medicine", label: "Order Medicine" },
  { path: "press", label: "Press" },
  { path: "security", label: "Security" },
  { path: "video-consult", label: "Video Consult" },
];
export const sidebarFooterData = [
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-search"></i>
        <span className="sidebar-span-text">Find Doctors</span>-Book an
        appointment
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-play"></i>
        <span className="sidebar-span-text">Video Consult</span>-Consult top
        doctors
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-pills"></i>
        <span className="sidebar-span-text">Medicine</span>-YESMADE Pharmacy
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-flask"></i>
        <span className="sidebar-span-text">Lab Tests</span>-Book tests &
        checkup
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-scalpel"></i>
        <span className="sidebar-span-text">Surgeries</span>-Expert surgical
        care
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-download"></i>Download the app
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-clock"></i>Faster & complete healthcare experience
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-book-open"></i>Read health articles
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-file-medical-alt"></i>View medical records
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-book"></i>Read about medicines
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-user-md"></i>YESMADE for Providers
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-shield-alt"></i>ABDM - Data security
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-question-circle"></i>Help
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-phone-alt"></i>Contact us
      </>
    ),
  },
  {
    path: "#",
    label: "Terms of service",
  },
];
export const footerData = {
  yesMade: {
    title: "YESMADE",
    items: ["About", "Blog", "Careers", "Press", "Contact Us"],
  },
  forDoctors: {
    title: "For Doctors",
    items: ["YESMADE Consult", "YESMADE Health Feed", "YESMADE Profile"],
  },

  forHospitals: {
    title: "For Hospitals",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  forPatients: {
    title: "For Patients",
    items: [
      "Symptom guidance",
      "Hospital suggestion",
      "Doctor suggestion",
      "Pharmacy suggestion",
      "Diagnostic center suggestion",
      "Home Care suggestion",
      "Nutrition suggestion",
      "Physiotherapy suggestion",
      "Medical equipment needed for home care",
      "Medical travel agency suggestion",
    ],
  },
  forDiagnosis: {
    title: "For Diagnosis",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  forPharmacy: {
    title: "For Pharmacy",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  more: {
    title: "More",
    items: [
      "Help",
      "Developers",
      "Privacy Policy",
      "Terms & Conditions",
      "PCS T&C",
      "Healthcare Directory",
      "YESMADE Health Wiki",
    ],
  },
  social: {
    title: "Social",
    items: ["Facebook", "Twitter", "LinkedIn", "Youtube", "Github"],
  },
};

export const drSpecialties = [
  { key: "dermatology", value: "dermatology", label: "Dermatology" },
  { key: "cardiology", value: "cardiology", label: "Cardiology" },
  { key: "neurology", value: "neurology", label: "Neurology" },
  { key: "pediatrics", value: "pediatrics", label: "Pediatrics" },
  { key: "orthopedics", value: "orthopedics", label: "Orthopedics" },
];
export const selectLoginData = [
  {
    label: "Patient",
    key: "patient",
    value: "patient",
  },
  {
    label: "Doctor",
    key: "doctor",
    value: "doctor",
  },
  {
    label: "Hospital",
    key: "hospital",
    value: "hospital",
  },
  {
    label: "Pharmacy",
    key: "pharmacy",
    value: "pharmacy",
  },
  {
    label: "Diagnostics",
    key: "diagnostic_center",
    value: "diagnostic_center",
  },
];
export const locationLists = [
  "Arada",
  "Arat Kilo",
  "Asko",
  "Ayat",
  "Bole",
  "Bole Bulbula",
  "CMC",
  "Gofa",
  "Gulele",
  "Haya Hulet",
  "Jemo",
  "Kazanchis",
  "Kolfe",
  "Kotebe",
  "Lafto",
  "Lebu",
  "Lideta",
  "Megenagna",
  "Meknisa",
  "Meskel Square",
  "Mexico",
  "Old Airport",
  "Piazza",
  "Qality",
  "Sar Bet",
  "Shiro Meda",
  "Sidist Kilo",
  "Summit",
  "Urael",
  "Wello Sefer",
  "Yeka",
  "Addis Ketema",
  "Akaki Kality",
  "Nifas Silk-Lafto",
  "Bole Medhane Alem",
  "Gurd Shola",
  "Gerji",
  "Gelan Condominium",
  "Gotera",
  "Zenebework",
  "Alem Bank",
  "Bambis",
  "Kera",
  "Saris",
  "Dembel City Center",
  "Sarbet",
];
export const formPostFields = [
  { name: "title", label: "Title", placeholder: "Title" },
];
export const pharmacyMedicineFormFields = [
  {
    name: "name",
    label: "Medicine Name",
    placeholder: "Medicine Name",
  },
  { name: "storage", label: "Storage", placeholder: "storage" },
  { name: "purpose", label: "purpose", placeholder: "purpose" },
  { name: "frequency", label: "Frequency", placeholder: "Frequency" },
  { name: "type", label: "Type", placeholder: "Type" },
];
// hospitals and diagnostic service from
export const diagnosticServiceFormFields = [
  {
    name: "serviceName",
    label: "Service Name",
    placeholder: "Enter the service name",
  },
  { name: "title", label: "Title", placeholder: "Enter the title" },
  { name: "owner", label: "Owner Name ", placeholder: "" },
  {
    name: "specializationType",
    label: "Specialization Type",
    placeholder: "Enter the specialization type",
  },
];
export const categoryFormOptions = [
  { key: "serviceArea1", value: "serviceArea1", label: "Service Area 1" },
  { key: "serviceArea2", value: "serviceArea2", label: "Service Area 2" },
  { key: "serviceArea3", value: "serviceArea3", label: "Service Area 3" },
];
export const fieldFormOptions = [
  { key: "ourService", value: "ourService", label: "Our Service" },
  {
    key: "hospitalService",
    value: "hospitalService",
    label: "Hospital Service",
  },
  {
    key: "diagnosticService",
    value: "diagnosticService",
    label: "Diagnostic Service",
  },
];
export const serviceTypeFormOptions = [
  { key: "General", value: "General", label: "General" },
  { key: "Specialized", value: "Specialized", label: "Specialized" },
  { key: "Emergency", value: "Emergency", label: "Emergency" },
  { key: "Imaging", value: "Imaging", label: "Imaging" },
  { key: "LabTests", value: "Lab Tests", label: "Lab Tests" },
  {
    key: "CardiologyTests",
    value: "Cardiology Tests",
    label: "Cardiology Tests",
  },
  { key: "NeurologyTests", value: "Neurology Tests", label: "Neurology Tests" },
];

//////
export const excludedOrganizationsColumnData = [
  "AGE",
  "BRANCH NUMBER",
  "CREATED AT",
  "DETAILS",
  "EMAIL",
  "EXPERIENCE",
  "FULL NAME",
  "ID",
  "LATITUDE",
  "LICENSE NUMBER",
  "LONGITUDE",
  "ORGANIZATION NAME",
  "PHONE",
  "ROLE",
  "SPECIALTY",
  "STATUS",
  "TELEGRAM ID",
  "UPDATED AT",
  "USER ID",
  "USERNAME",
];

export const excludedDoctorData = [
  "AGE",
  "CREATED AT",
  "DETAILS",
  "EMAIL",
  "EXPERIENCE",
  "FULL NAME",
  "ID",
  "LATITUDE",
  "LICENSE NUMBER",
  "LONGITUDE",
  "PHONE",
  "ROLE",
  "SEX",
  "SPECIALTY",
  "STATUS",
  "TELEGRAM ID",
  "UPDATED AT",
  "USER ID",
  "USERNAME",
];

export const excludedStaffData = [
  "AGE",
  "CREATED AT",
  "DETAILS",
  "EMAIL",
  "FULL NAME",
  "ID",
  "PHONE",
  "TYPE",
  "ROLE",
  "SEX",
  "STATUS",
  "TELEGRAM ID",
  "UPDATED AT",
  "USER ID",
  "USERNAME",
];

//
export const organizationsOptionsSearchData = [
  "AGE",
  "BRANCH NUMBER",
  "EMAIL",
  "EXPERIENCE",
  "FULL NAME",
  "LICENSE NUMBER",
  "ORGANIZATION NAME",
  "PHONE",
  "ROLE",
  "SPECIALTY",
  "STATUS",
  "TELEGRAM ID",
  "USER ID",
  "USERNAME",
];

export const doctorOptionsSearchData = [
  "AGE",
  "EMAIL",
  "EXPERIENCE",
  "FULL NAME",
  "LICENSE NUMBER",
  "PHONE",
  "ROLE",
  "SEX",
  "SPECIALTY",
  "STATUS",
  "TELEGRAM ID",
  "USER ID",
  "USERNAME",
];

export const staffOptionsSearchData = [
  "AGE",
  "EMAIL",
  "FULL NAME",
  "PHONE",
  "TYPE",
  "ROLE",
  "SEX",
  "STATUS",
  "TELEGRAM ID",
  "USER ID",
  "USERNAME",
];

//
export const statusOptions = [
  "Draft",
  "Pending",
  "Approved",
  "Published",
  "Blocked",
  "Archived",
  "Deleted",
];
