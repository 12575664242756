import { React, useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import { Link, useParams } from "react-router-dom";
import "./PharamcistCart.css";

function PharmacistCart() {
  const [cartItems, setCartItems] = useState([]);
  return (
    <>
      <div id="pharmacist-cart">
        <div className="cart-container">
          <h1>Requested Medicines</h1>
          <div className="underline"></div>
          {cartItems.length === 0 ? (
            <p>
              <strong>Your cart is empty.</strong>
            </p>
          ) : (
            <ol>
              {cartItems.map((cartItem) => (
                <li key={cartItem.id}>
                  <div className="cart-wrapper">
                    <div className="content-wrapper">
                      <div className="right-content">
                        <img src={cartItem.image} alt={cartItem.name} />
                      </div>
                      <div>
                        <h2>Name: {cartItem.name}</h2>
                        <h3>dosage: {cartItem.dosage}</h3>
                        <h3>It cures from: {cartItem.pain}</h3>
                      </div>
                    </div>
                    <div className="available-button">
                      <Link
                        to={`/detailInformationForm/${cartItem.id}`}
                        className="available"
                        key={cartItem.id}
                      >
                        Available
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          )}
        </div>
      </div>
    </>
  );
}

export default PharmacistCart;
