import React, { useState, useEffect } from "react";
import usePost from "../../../hooks/usePost";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";
import "./Login.css";
import { Button, Form, Input, Select } from "antd";
import useToast from "../toast/toast";
import { useAccAuth } from "../../../contexts/userAccountAuth";
import { selectLoginData } from "../../../services/data";

const LoginForm = ({ closeModal }) => {
  const { showToast } = useToast();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();
  const [role, setRole] = useState(); // State to track selected role
  const { setAuthUser } = useAccAuth();
  const { post } = usePost("/login", {
    setData,
    setLoading,
    setError,
    setRedirect,
  });
  const navigate = useNavigate();

  const handleRoleChange = (value) => {
    setRole(value); // Update state with selected role
  };

  const handleSubmit = async (values) => {
    await form.validateFields();
    await post(values);
  };

  useEffect(() => {
    if (data) {
      showToast(data.status);

      if (data?.status === "success" && data?.userAccessToken && data?.role) {
        localStorage.setItem("userAccessToken", data?.userAccessToken);
        setAuthUser({ authenticated: true, role: data.role });
        closeModal();
        window.location.reload();
        // Navigate based on the role after successful login
        switch (role) {
          case "doctor":
            navigate("app/doctor_profile");
            break;
          case "patient":
            navigate("app/patient_profile");
            break;
          case "hospital":
            navigate("app/hospital_profile");
            break;
          case "pharmacy":
            navigate("app/pharmacy_profile");
            break;
          case "diagnostic_center":
            navigate("app/diagnostic_profile");
            break;
          default:
            break;
        }
      }
    }
  }, [data, role]); // Dependencies include both data and role

  useEffect(() => {
    if (error) {
      showToast(error.message?.replace("AxiosError:", ""));
    }
  }, [error]);

  return (
    <>
      <Form
        className="login-form"
        form={form}
        layout="vertical"
        variant="filled"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Role"
          rules={[
            {
              required: true,
              message: "Please select your role!",
            },
          ]}
          name="role"
          placeholder="role"
          required
        >
          <Select className="login-select" onChange={handleRoleChange}>
            {selectLoginData.map((item) => (
              <Select.Option key={item.key} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please fill your Password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Link to="app/doctor_forgot_password">Forgot Password ?</Link>
        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
