import React, { useEffect, useState } from "react";
import { Upload, Button, Form, Input, Select, message } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import useFetch from "../../../../hooks/useFetch";

export const pharmacyMedicineFormFields = [
  { name: "productName", label: "Medicine Name", placeholder: "Medicine Name" },
  { name: "quantity", label: "QUANTITY", placeholder: "Quantity" },
  { name: "dosage", label: "Dosage", placeholder: "Dosage" },
  { name: "brandName", label: "BRAND NAME", placeholder: "Brand Name" },
];

const { TextArea } = Input;
const { Option } = Select;

const NewMedicineForm = ({
  form,
  handleModalOk,
  onChange,
  onPreview,
  fileList,
}) => {
  const { data, loading, getRequest } = useFetch("/api/drugs");
  const [drugData, setDrugData] = useState([]);

  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    console.log("---[][][][]", data?.drugs);
    setDrugData(data?.drugs);
  }, [data]);

  return (
    <>
      <Form
        className=" "
        onFinish={handleModalOk}
        form={form}
        layout="vertical"
      >
        {pharmacyMedicineFormFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={[
              {
                required: true,
                message: `Please fill the ${field.label.toLowerCase()}!`,
              },
            ]}
          >
            {field.name === "productName" ? (
              <Select
                showSearch
                placeholder={field.placeholder}
                loading={loading}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {drugData &&
                  drugData.map((drug, index) => (
                    <Option key={drug.id || index} value={drug.NAME_OF_DRUG}>
                      {drug.NAME_OF_DRUG}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input placeholder={field.placeholder} />
            )}
          </Form.Item>
        ))}
        <Form.Item label="Other Detail Description" name="detail">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Post image"
          name="image"
          rules={[
            {
              validator: (_, value) => {
                if (fileList.length === 0) {
                  return Promise.reject(new Error("Please upload an image!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false} // Prevent auto-upload
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}> Upload</Button>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewMedicineForm;
