import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import "./PatientNotification.css";

function UserNotification() {
  const [notificationList, setNotificationList] = useState([]);
  const [notifications, setNotifications] = useState([]);

  return (
    <>
      <section id="notification">
        <div className="notification-bar">
          <div className="notification-wrapper">
            <div className="notification-container">
              <h1>Notifications</h1>
              <div className="underline"></div>
              {notificationList.length === 0 ? (
                <p>
                  <strong>No Notifications Yet.</strong>
                </p>
              ) : (
                <ol>
                  {notificationList.map((cartItem) => {
                    console.log(cartItem);

                    return (
                      <li key={cartItem.id}>
                        <div className="content-wrapper">
                          <div className="right-content">
                            <img src={cartItem.image} alt={cartItem.name} />
                          </div>
                          <div className="left-content">
                            <h3>Name: {cartItem.name}</h3>
                            <h4>dosage: {cartItem.dosage}</h4>
                            <h4>It cures from: {cartItem.pain}</h4>
                            <h4>Price: {cartItem.price}</h4>
                            <Link
                              className="getMedicine"
                              to={`/payment/${cartItem.id}`}
                            >
                              Get this medicine
                            </Link>
                          </div>
                        </div>
                        <div className="line-break"></div>
                      </li>
                    );
                  })}
                </ol>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserNotification;
