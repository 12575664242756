import React, { useState, useEffect } from "react";
import MaleBody from "../../body-part/MaleBody";
import FemaleBody from "../../body-part/FemaleBody";
import PelvisSelectorModal from "./PelvisSelectorModal";
import BreastSelectorModal from "./BreastSelectorModal";
import HeadSelectorModal from "./HeadSelectorModal";

const VisualBodySelectorModal = ({
  formData,
  setFormData,
  targetSymptom,
  setVisualBodyPartSelectorModal,
}) => {
  const [isFrontVisible, setIsFrontVisible] = useState(true);

  const [selectedBodyPartImage, setSelectedBodyPartImage] = useState(<></>);
  const [openPelvisSelectorModal, setOpenPelvisSelectorModal] = useState(false);
  const [openBreastSelectorModal, setOpenBreastSelectorModal] = useState(false);
  const [openHeadSelectorModal, setOpenHeadSelectorModal] = useState(false);

  const toggleCardVisibility = () => {
    setIsFrontVisible((prev) => !prev);
  };

  const selectBodyPart = (part) => {
    setFormData({ ...formData, selectedBodyPart: part });
    setVisualBodyPartSelectorModal(false);
  };

  const handlePathClick = (e) => {
    const clickedBodyPart = e.currentTarget.getAttribute("data-inkscape-label");
    if (clickedBodyPart === "Pelvis") return setOpenPelvisSelectorModal(true);
    if (clickedBodyPart === "Chest") return setOpenBreastSelectorModal(true);
    if (clickedBodyPart === "Head and Face")
      return setOpenHeadSelectorModal(true);

    selectBodyPart(clickedBodyPart);
  };
  const handlePediatricPathClick = (e) => {
    const clickedBodyPart = e.currentTarget.getAttribute("data-inkscape-label");
    if (clickedBodyPart === "Head and Face")
      return setOpenHeadSelectorModal(true);

    selectBodyPart(clickedBodyPart);
  };
  const handleSelectPelvisPart = (value) => {
    selectBodyPart(value);
    setOpenPelvisSelectorModal(false);
  };
  const handleSelectBreastPart = (value) => {
    selectBodyPart(value);
    setOpenBreastSelectorModal(false);
  };
  const handleSelectHeadPart = (value) => {
    selectBodyPart(value);
    setOpenHeadSelectorModal(false);
  };
  useEffect(() => {
    if (formData.age <= 13) {
      switch (formData.gender) {
        case "male":
          setSelectedBodyPartImage(
            <MaleBody
              handlePathClick={handlePediatricPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          break;
        case "female":
          setSelectedBodyPartImage(
            <FemaleBody
              handlePathClick={handlePediatricPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          break;
        default:
          setSelectedBodyPartImage(null);
      }
    } else if (formData.age > 13) {
      switch (formData.gender) {
        case "male":
          setSelectedBodyPartImage(
            <MaleBody
              handlePathClick={handlePathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          break;
        case "female":
          setSelectedBodyPartImage(
            <FemaleBody
              handlePathClick={handlePathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );

          break;
        default:
          setSelectedBodyPartImage(null);
      }
    }
  }, [formData.age, formData.gender, formData.isPregnant]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {selectedBodyPartImage}
      </div>
      <PelvisSelectorModal
        openPelvisSelectorModal={openPelvisSelectorModal}
        setOpenPelvisSelectorModal={setOpenPelvisSelectorModal}
        handleSelectPelvisPart={handleSelectPelvisPart}
      />
      <BreastSelectorModal
        openBreastSelectorModal={openBreastSelectorModal}
        setOpenBreastSelectorModal={setOpenBreastSelectorModal}
        handleSelectBreastPart={handleSelectBreastPart}
      />
      <HeadSelectorModal
        openHeadSelectorModal={openHeadSelectorModal}
        setOpenHeadSelectorModal={setOpenHeadSelectorModal}
        handleSelectHeadPart={handleSelectHeadPart}
        targetSymptom={targetSymptom}
      />
    </>
  );
};

export default VisualBodySelectorModal;
