import React from "react";
import { Modal, Select } from "antd";

const BreastSelectorModal = ({
  openBreastSelectorModal,
  setOpenBreastSelectorModal,
  handleSelectBreastPart,
}) => {
  return (
    <Modal
      title="Select Part"
      open={openBreastSelectorModal}
      onCancel={() => setOpenBreastSelectorModal(false)}
      footer={null}
    >
      <Select
        style={{ width: 200 }}
        onChange={(value) => handleSelectBreastPart(value)}
        options={[
          { label: "Breast", value: "Breast" },
          { label: "Chest", value: "Chest" },
        ]}
      />
    </Modal>
  );
};

export default BreastSelectorModal;
