import React, { useEffect, useState } from "react";
import {
  Select,
  Checkbox,
  Button,
  Typography,
  Collapse,
  message,
  Modal,
  Input,
  Form,
} from "antd";
import { symptomGuidanceData } from "../../../services/data";
import VisualBodySelectorModal from "../visual-body-selector/VisualBodySelectorForModal";
import { useNavigate } from "react-router-dom";
import "./symptomSelector.css";
import usePost from "../../../hooks/usePost";
const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const SymptomSelector = ({
  targetSymptom,
  gender,

  onNext,
  formData,
  setFormData,
}) => {
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [additionalInfoModalOpen, setAdditionalInfoModalOpen] = useState(false);
  const [visualBodyPartSelectorModal, setVisualBodyPartSelectorModal] =
    useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    duration: "",
    medicalConditions: "",
    medications: "",
    additionalSymptoms: "",
  });

  const [createSymptomResp, setCreateSymptomResp] = useState(null);
  const [createSymptomLoading, setCreateSymptomLoading] = useState(false);
  const [createSymptomError, setCreateSymptomError] = useState(null);

  const { post } = usePost("/api/symptom", {
    setData: setCreateSymptomResp,
    setLoading: setCreateSymptomLoading,
    setError: setCreateSymptomError,
  });

  const navigate = useNavigate();
  const bodyParts = symptomGuidanceData
    ?.find((targetSymptoms) => targetSymptoms.name === targetSymptom)
    ?.bodyParts?.filter((part) => part.name === formData.selectedBodyPart);

  const handleSymptomChange = (symptom, checked) => {
    setSelectedSymptoms((prev) => {
      const existingSymptom = prev.find((s) => s.name === symptom.name);

      if (checked) {
        if (existingSymptom) return prev;
        const updatedSymptom = {
          name: symptom.name,
          bodyPart: formData.selectedBodyPart,
          option: symptom.option
            ? symptom.option.map((opt) => ({ name: opt.name, items: [] }))
            : [],
        };
        return [...prev, updatedSymptom];
      } else {
        return prev.filter((s) => s.name !== symptom.name);
      }
    });
  };

  const handleOptionChange = (symptomName, optionName, itemName, checked) => {
    setSelectedSymptoms((prev) =>
      prev.map((symptom) => {
        if (symptom.name !== symptomName) return symptom;
        // If the symptom has options, update them
        if (symptom.option) {
          const updatedOptions = symptom.option.map((opt) => {
            if (opt.name === optionName) {
              // Update the items array based on the checkbox state
              const updatedItems = checked
                ? [...(opt.items || []), itemName] // Add the item if checked
                : (opt.items || []).filter((item) => item !== itemName); // Remove the item if unchecked
              return {
                ...opt,
                items: updatedItems,
              };
            }
            return opt;
          });

          return {
            ...symptom,
            option: updatedOptions,
          };
        }
        return symptom;
      })
    );
  };

  const handleNextClick = () => {
    if (selectedSymptoms.length === 0) {
      Modal.error({
        title: "No Symptoms Selected",
        content: (
          <div>
            Please select at least one symptom before proceeding to the next
            step.
          </div>
        ),
      });
      return;
    }

    const incompleteSymptoms = selectedSymptoms.filter((symptom) => {
      console.log("options", symptom.option);

      if (symptom.option.length)
        return symptom.option.every((opt) => opt.items.length === 0);
    });

    console.log(incompleteSymptoms);

    if (incompleteSymptoms.length > 0) {
      console.log(incompleteSymptoms);

      const extractedText = incompleteSymptoms
        .map((incompleteSymptom) => {
          const text = incompleteSymptom?.option
            .map((opt) => opt.name)
            .join(", ");
          return { name: incompleteSymptom.name, text };
        })
        .map((opt) => `${opt.name}:  ${opt.text}`)
        .join("<br>");

      console.log();

      // Show a modal with an error message using dangerouslySetInnerHTML
      Modal.error({
        title: "Incomplete Selection",
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: `Please ensure at least one item is selected for each: ${extractedText}`,
            }}
          />
        ),
      });
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedSymptomsDetails: selectedSymptoms,
    }));
    setAdditionalInfoModalOpen(true);
  };

  const handleAdditionalInfoSubmit = () => {
    setAdditionalInfoModalOpen(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      additionalInfo: additionalInfo,
    }));
    setPreviewModalOpen(true);
  };

  const handleBodyPartSelect = (value) => {
    setFormData({ ...formData, selectedBodyPart: value });
  };

  const selectedBodyPartData = bodyParts?.find(
    (part) => part.name === formData.selectedBodyPart
  );

  useEffect(() => {
    console.log("The formData", formData);
    console.log(
      "The gender and age ====",
      formData.gender,
      formData.age,
      formData.isPregnant,
      formData.selectedBodyPart,
      formData.selectedSymptomsDetails,
      formData.additionalInfo
    );
  }, [formData]);
  const handleSubmit = async () => {
    const submitData = {
      age: formData.age,
      gender: formData.gender,
      isPregnant: formData.isPregnant,
      symptoms: JSON.stringify({
        selectedGeneralSymptoms: formData.selectedGeneralSymptoms,
        selectedSymptomsDetails: formData.selectedSymptomsDetails,
        additionalInfo: formData.additionalInfo,
      }),
    };
    console.log(" the submitted data is ", submitData);
    // setFormData(submitData);

    await post(submitData);
    console.log("Saved form data:", formData);
  };

  useEffect(() => {
    console.log(createSymptomError);

    if (createSymptomError?.status) {
      message[createSymptomError?.status && "error"]?.(
        createSymptomError?.message && "Error creating symptoms"
      );
      setCreateSymptomLoading(false);
    }
  }, [createSymptomError]);
  useEffect(() => {
    console.log("=====--------====", createSymptomResp);

    if (createSymptomResp?.status) {
      message[createSymptomResp?.status && "success"]?.(
        createSymptomResp?.message
      );
      setCreateSymptomLoading(false);
    }

    if (createSymptomResp?.status === "success") {
      setPreviewModalOpen(false);

      navigate("/app/symptom-history");
    }
  }, [createSymptomResp]);
  return (
    <div
      style={{
        padding: "20px",
        margin: "auto",
        marginTop: "1rem",
      }}
    >
      <Title level={4}>{targetSymptom}</Title>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Select
          style={{ width: "100%", marginBottom: "20px" }}
          placeholder="Select Body Part"
          value={formData.selectedBodyPart}
          onChange={handleBodyPartSelect}
          showSearch
        >
          {symptomGuidanceData
            ?.find((targetSymptoms) => targetSymptoms.name === targetSymptom)
            ?.bodyParts.map((part) => (
              <Select.Option key={part.name} value={part.name}>
                {part.name}
              </Select.Option>
            ))}
        </Select>
        <div>
          <img
            onClick={() => setVisualBodyPartSelectorModal(true)}
            onClose={() => setVisualBodyPartSelectorModal(false)}
            style={{ height: "50px", margin: 5 }}
            src={`../../../../images/body-images/${gender}-front-body-image.png`}
          />
        </div>
      </div>

      {selectedBodyPartData && (
        <div>
          {selectedBodyPartData?.symptom?.map((symptom) => (
            <div key={symptom.name} style={{ marginBottom: "20px" }}>
              <Checkbox
                value={symptom.name}
                checked={
                  !!selectedSymptoms.find((s) => s.name === symptom.name)
                }
                onChange={(e) => handleSymptomChange(symptom, e.target.checked)}
              >
                {symptom.name}
              </Checkbox>
              {symptom.option && (
                <div style={{ marginTop: "10px" }}>
                  {selectedSymptoms.find((s) => s.name === symptom.name) && (
                    <Collapse accordion defaultActiveKey={[symptom.name]}>
                      <Panel header="Options" key={symptom.name}>
                        {symptom.option.map((opt) => {
                          if (opt.items && opt.items.length > 0) {
                            return (
                              <div key={opt.name}>
                                <h3
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  {opt.name}
                                </h3>
                                <div>
                                  {opt.items.map((item) => (
                                    <div
                                      style={{
                                        marginBottom: 10,
                                      }}
                                    >
                                      <Checkbox
                                        key={item}
                                        value={item}
                                        checked={
                                          selectedSymptoms
                                            .find(
                                              (s) => s.name === symptom.name
                                            )
                                            ?.option.find(
                                              (o) => o.name === opt.name
                                            )
                                            ?.items.includes(item) || false
                                        }
                                        onChange={(e) =>
                                          handleOptionChange(
                                            symptom.name,
                                            opt.name,
                                            item,
                                            e.target.checked
                                          )
                                        }
                                      >
                                        {item}
                                      </Checkbox>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Panel>
                    </Collapse>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <Button
        type="primary"
        onClick={() => handleNextClick()}
        style={{ alignContent: "flex-end" }}
      >
        Next
      </Button>

      <Modal
        title="Additional Information"
        open={additionalInfoModalOpen}
        onCancel={() => setAdditionalInfoModalOpen(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setAdditionalInfoModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAdditionalInfoSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Duration of Symptoms">
            <Select
              placeholder="Select duration"
              value={additionalInfo.duration}
              onChange={(value) =>
                setAdditionalInfo({ ...additionalInfo, duration: value })
              }
            >
              <Select.Option value="Less than 24 hours">
                Less than 24 hours
              </Select.Option>
              <Select.Option value="1-2 days">1-2 days</Select.Option>
              <Select.Option value="3-5 days">3-5 days</Select.Option>
              <Select.Option value="More than 5 days">
                More than 5 days
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Previous Medical Conditions">
            <TextArea
              placeholder="Do you have any existing medical conditions or a history of gynecologic issues?"
              value={additionalInfo.medicalConditions}
              onChange={(e) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  medicalConditions: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item label="Current Medications">
            <TextArea
              placeholder="Are you currently taking any medications? Please list them if applicable."
              value={additionalInfo.medications}
              onChange={(e) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  medications: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item label="Additional Symptoms">
            <TextArea
              placeholder="Please provide any additional details about your symptoms that are not covered in the form."
              value={additionalInfo.additionalSymptoms}
              onChange={(e) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  additionalSymptoms: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Preview Selected Symptoms and Additional Information"
        open={previewModalOpen}
        onCancel={() => setPreviewModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setPreviewModalOpen(false)}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={createSymptomLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          {formData.selectedGeneralSymptoms.length > 0 && (
            <>
              <Title level={5}>General Symptoms</Title>
              <ul>
                {formData.selectedGeneralSymptoms.map(
                  (generalSymptom, index) => (
                    <li key={index}>
                      <strong>-</strong> {generalSymptom}
                    </li>
                  )
                )}
              </ul>
            </>
          )}
          <div>
            <br />
            <Title level={5}>Selected Symptoms</Title>
            <ul>
              {selectedSymptoms.map((symptom, index, array) => {
                const isFirstOccurrence =
                  index === 0 || symptom.bodyPart !== array[index - 1].bodyPart;

                return (
                  <li key={symptom.name}>
                    {isFirstOccurrence && (
                      <li>
                        <strong>Body Part: {symptom.bodyPart}</strong>
                      </li>
                    )}
                    <strong>Symptom:</strong> {symptom.name}
                    {symptom.option && symptom.option.length > 0 && (
                      <ul>
                        {symptom.option.map((opt) =>
                          opt.items && opt.items.length > 0 ? (
                            <li key={opt.name}>
                              <strong>Option:</strong> {opt.name}
                              <ul>
                                {opt.items.map((item) => (
                                  <li key={item}>
                                    <strong>Item:</strong> {item}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : null
                        )}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          {additionalInfo &&
            (additionalInfo.duration ||
              additionalInfo.medicalConditions ||
              additionalInfo.medications ||
              additionalInfo.additionalSymptoms) && (
              <>
                <br />
                <Title level={5}>Additional Information</Title>
                <ul>
                  {additionalInfo.duration && (
                    <li>
                      <strong>Duration of Symptoms:</strong>
                      {additionalInfo.duration}
                    </li>
                  )}
                  {additionalInfo.medicalConditions && (
                    <li>
                      <strong>Previous Medical Conditions:</strong>{" "}
                      {additionalInfo.medicalConditions}
                    </li>
                  )}
                  {additionalInfo.medications && (
                    <li>
                      <strong>Current Medications:</strong>{" "}
                      {additionalInfo.medications}
                    </li>
                  )}
                  {additionalInfo.additionalSymptoms && (
                    <li>
                      <strong>Additional Symptoms:</strong>{" "}
                      {additionalInfo.additionalSymptoms}
                    </li>
                  )}
                </ul>
              </>
            )}
        </div>
      </Modal>
      <Modal
        title="Visual Body Selector"
        open={visualBodyPartSelectorModal}
        onCancel={() => setVisualBodyPartSelectorModal(false)}
        onOk={() => setVisualBodyPartSelectorModal(false)}
        footer={null}
      >
        <VisualBodySelectorModal
          targetSymptom={targetSymptom}
          formData={formData}
          setFormData={setFormData}
          setVisualBodyPartSelectorModal={setVisualBodyPartSelectorModal}
        />
      </Modal>
    </div>
  );
};

export default SymptomSelector;
