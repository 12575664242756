import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../common/footer/Footer";
import Sidebar from "../../../common/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
// import { medicalProfessionalsData } from "../../../../services/data";
import ProfileImg from "../../../../image/service-img-5.png";
import useFetchUsers from "../../../../hooks/useFetchUsers";
import ErrorPage from "../../../common/error-page/ErrorPage";
import {
  FaCommentDots,
  FaThumbsUp,
  FaGem,
  FaCheckCircle,
} from "react-icons/fa";

import "./DoctorList.css";

const DoctorList = () => {
  const [medicalProfessionalsData, setMedicalProfessionalsData] = useState([]);
  const [medicalProfessionalsDataErr, setMedicalProfessionalsDataErr] =
    useState(false);
  const { data, loading, fetchUsers } = useFetchUsers("/adminApi/users");

  useEffect(() => {
    fetchUsers({ role: "doctor" });
  }, []);
  useEffect(() => {
    console.log("---", data);
  }, [data]);
  useEffect(() => {
    if (data) {
      setMedicalProfessionalsData(data);
    }
  }, [data]);

  return (
    <>
      {" "}
      <Sidebar />
      <div id="doctorList">
        <div className="container">
          <SearchDoctor medicalProfessionalsData={medicalProfessionalsData} />
          {medicalProfessionalsDataErr && <ErrorPage />}
          {medicalProfessionalsData &&
            medicalProfessionalsData?.map((item, i) => {
              // const { ["Full NAME"] lastName, email, Phone, active } = item;
              console.log("=====", item);

              return (
                <section
                  id="find-right-doctor"
                  className="border-bottom"
                  key={i}
                >
                  <article className="doctor-card card border container-grid-2-1">
                    <div className="" style={{ margin: "auto" }}>
                      <div className="circle-img">
                        <img
                          className="profile-image"
                          src={ProfileImg}
                          alt="Doctor Profile Image"
                        />
                      </div>

                      <div className="">
                        <h2 className="doctor-name">
                          <Link href="">
                            {" "}
                            Dr. {item.ID} {item["FULL NAME"]}
                          </Link>
                          <span>
                            {/* <span
                              className={
                                { active }
                                  ? "active-sign mx-2"
                                  : "inactive-sign mx-2"
                              }
                            ></span> */}
                          </span>
                        </h2>
                        <p className="doctor-specialty">General Physician</p>
                        <p className="doctor-experience">54 years experience</p>
                        <h3 className="patient-stories">
                          <span>
                            <FaCommentDots className="text-green" />
                          </span>
                          108 Patient Stories
                        </h3>
                      </div>
                    </div>

                    <div className="doctor-detail-row">
                      <div className="grid-2-1 border">
                        <div className="recommendation card-content">
                          <Link>
                            <span className="bg-green mini-box">
                              <FaThumbsUp className="text-light" /> 93%
                            </span>
                            Patient Recommendation
                          </Link>
                        </div>

                        <div className="excellence-rating card-content">
                          <Link>
                            <span className="bg-blue mini-box">
                              <FaGem className="text-light" /> 4.0
                            </span>
                            Hospital Excellence Rating
                          </Link>
                        </div>
                      </div>

                      <div className="card-content border">
                        <Link href="#">
                          Electronics City • Practo Hospitals - Neo
                        </Link>
                        <br />
                        <Link href="#">
                          <span className="text-green">Open</span> • Updated
                          today
                        </Link>
                        <br />
                        <Link href="#">~ ₹700 Consultation Fees</Link> <br />
                      </div>

                      <div className="availability-info card-content border">
                        <h3 className="text-green">NEXT AVAILABLE AT</h3>

                        <div className="available-slots grid-2-1">
                          <div>
                            <small className="mx-1">11:30 AM, 29th Feb</small>
                            <Link href="#" className="btn  btn-contact">
                              Contct Clinic
                            </Link>
                          </div>

                          <div>
                            <small className="mx-1">11:30 AM, 29th Feb</small>
                            <Link href="#" className="btn  btn-book">
                              Book Clinic
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </section>
              );
            })}
        </div>
        <Footer />
      </div>
    </>
  );
};
const SearchDoctor = ({ medicalProfessionalsData }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (doctor) => {
    // Navigate to the selected doctor page
    navigate(`/app/doctor_info/${doctor.ID}`);
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) {
      setSearchResults([]);
      return;
    }

    const filteredData = medicalProfessionalsData?.filter((doctor) =>
      doctor?.["FULL NAME"]?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm, medicalProfessionalsData]);

  return (
    <section id="search">
      <div className="medicine-nav-container">
        <div className="search-container">
          <div className="search-bar search">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search for Medicine"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <div className="search-list-container">
                <ul className="lists">
                  {searchResults.length > 0
                    ? searchResults.map((doctor) => (
                        <li
                          className="text-primary name"
                          key={doctor.ID}
                          onClick={() => handleMedicineClick(doctor)}
                        >
                          {doctor?.["FULL NAME"]}
                        </li>
                      ))
                    : searchTerm && <p>No results found.</p>}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export { SearchDoctor };
export default DoctorList;
