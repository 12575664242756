import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography, Spin, Space, Card } from "antd";
import useFetch from "../../../hooks/useFetch2";
import usePost from "../../../hooks/usePost";
import useToast from "../toast/toast";
import { useAccAuth } from "../../../contexts/userAccountAuth";
import { useSession } from "../../../contexts/sessionData";

const { Title, Text } = Typography;

const Verification = () => {
  const [form] = Form.useForm();
  const { showToast } = useToast();
  const { setAuthUser } = useAccAuth();

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const navigate = useNavigate();

  const timerLength = 10;
  const [timer, setTimer] = useState(timerLength);
  const [isResendVisible, setResendVisible] = useState(false);

  const { authenticated, setAuthenticated } = useSession(false);

  const [verifyResp, setVerifyResp] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState(null);
  const [verifyError, setVerifyError] = useState(null);

  const {
    data: resendResp,
    loading: resendLoading,
    error: resendError,
    get,
  } = useFetch("/auth/resendCode");

  const { post: verifyCode, reset: resetVerifyCode } = usePost(
    "/auth/verifyAndCreate",
    {
      setData: setVerifyResp,
      setLoading: setVerifyLoading,
      setError: setVerifyError,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendVisible(true);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (resendResp?.status === "success") {
      setTimer(timerLength);
      setResendVisible(false);
    }

    if (resendError) {
      console.error(resendError);
      setResendVisible(false);
    }
  }, [resendResp, resendError]);

  useEffect(() => {
    if (verifyError) showToast(verifyError.message?.replace("AxiosError:", ""));
    if (verifyError?.status === "error") setVerifyResp(false);
    resetVerifyCode();
  }, [verifyError]);

  useEffect(() => {
    console.log("verifyResp", verifyResp);
    if (
      verifyResp &&
      verifyResp.status === "success" &&
      verifyResp.redirect &&
      verifyResp.tokenPhone
    ) {
      // if (verifyResp && verifyResp.status === "success" && verifyResp.redirect) {
      localStorage.setItem("firstTimeUseToken", verifyResp.tokenPhone);
      localStorage.setItem("authenticated", "yes");
      setAuthUser({ authenticated: true });
      // navigate(verifyResp.redirect);
      const location = window.location.origin;
      window.open(location + verifyResp.redirect, "_self");
    }
  }, [verifyResp]);

  const handleResend = () => {
    get();
  };

  const handleCodeChange = () => {
    const code = form.getFieldValue("code");

    if (code?.length <= 6) {
      setButtonEnabled(true);
    }
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    values.phone = localStorage.getItem("phone");
    values.phone = "+" + values.phone;
    if (!values?.phone) return showToast("phone number not found");
    console.log("values", values);
    const { code } = values;

    if (code.length >= 6) {
      verifyCode(values);
    }
  };

  return (
    <Space direction="vertical" align="center" style={{ width: "100%" }}>
      <Card
        title={
          <h2
            style={{
              textAlign: "center",
              color: "#3f51b5",
              margin: 0,
              fontWeight: "600",
            }}
          >
            Verify Your Code
          </h2>
        }
        style={{
          maxWidth: 500,
          margin: "auto",
          padding: 20,
          borderRadius: 12,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item name="code" style={{ height: "30px" }}>
            <Input.OTP
              type="text"
              maxLength={6}
              onChange={handleCodeChange}
              placeholder="Enter verification code"
              style={{
                height: "30px",
                borderRadius: "8px",
                padding: "0 10px",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!buttonEnabled}
              loading={verifyLoading}
              style={{
                height: "36px", // Match input height
                width: "100%",
                fontSize: "13px",
                // backgroundColor: "#b5a9a9",
                color: "#fff",
                borderRadius: "8px", // Consistent rounded corners
              }}
            >
              Verify Code
            </Button>
          </Form.Item>
        </Form>
        {isResendVisible ? (
          <Text
            style={{
              textAlign: "center",
              color: "#3f51b5",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Didn’t get the code?{" "}
            <Button
              type="link"
              onClick={handleResend}
              disabled={resendLoading}
              style={{ color: "#1890ff", fontSize: "16px", fontWeight: "600" }}
            >
              Resend
            </Button>
          </Text>
        ) : (
          <Text
            style={{
              textAlign: "center",
              color: "#3f51b5",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Resend Code in {timer} seconds
          </Text>
        )}
      </Card>
    </Space>
  );
};

export default Verification;
