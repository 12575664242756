import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import PatientForm from "../services/general-clinical-guidance/patient-symptom-form/PatientForm";
import MaleBody from "../body-part/MaleBody";
import FemaleBody from "../body-part/FemaleBody";

export default function SymptomChecker({
  setCheckedStates,
  setSelectedData,
  selectedData,
  checkedStates,
  age,
  selectedGender,
  pregnancyStatus,
}) {
  const [isFrontVisible, setIsFrontVisible] = useState(true); // Front card is visible initially
  const [selectedBodyPart, setSelectedBodyPart] = useState("");
  const [isSymptomModalOpen, setIsSymptomModalOpen] = useState(false);
  const [selectedBodyPartImage, setSelectedBodyPartImage] = useState(<></>);

  const toggleCardVisibility = () => {
    setIsFrontVisible((prev) => !prev);
  };
  const handlePathClick = (e) => {
    const clickedBodyPart = e.currentTarget.getAttribute("data-inkscape-label");
    console.log(`${clickedBodyPart} clicked`);
    setSelectedBodyPart(clickedBodyPart);
    setIsSymptomModalOpen(true);
  };

  useEffect(() => {
    console.log("selected age", age);
  }, [age]);
  useEffect(() => {
    console.log("selectedGender", selectedGender);

    switch (selectedGender) {
      case "male":
        setSelectedBodyPartImage(
          <MaleBody
            handlePathClick={handlePathClick}
            toggleCardVisibility={toggleCardVisibility}
            isFrontVisible={isFrontVisible}
          />
        );
        break;
      case "female":
        setSelectedBodyPartImage(
          <FemaleBody
            handlePathClick={handlePathClick}
            toggleCardVisibility={toggleCardVisibility}
            isFrontVisible={isFrontVisible}
          />
        );
        break;
    }
  }, [selectedGender]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}
    >
      {selectedBodyPartImage}
      <Modal
        open={isSymptomModalOpen}
        footer={[
          <Button key="cancel" onClick={() => setIsSymptomModalOpen(false)}>
            Cancel
          </Button>,
        ]}
        onCancel={() => setIsSymptomModalOpen(false)}
      >
        <PatientForm
          bodyPart={selectedBodyPart}
          setIsSymptomsModalOpen={setIsSymptomModalOpen}
          setCheckedStates={setCheckedStates}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          checkedStates={checkedStates}
          selectedGender={selectedGender}
          age={age}
        />
      </Modal>
    </div>
  );
}
