import React, { useEffect, useState } from "react";
import { Table, Typography, message, Modal, Button, Spin, Card } from "antd";
import useFetch from "../../../hooks/useFetch2";

const { Title } = Typography;

const SymptomRequest = () => {
  const [symptomData, setSymptomData] = useState([]);
  const [symptomDataWithFilter, setSymptomDataWithFilter] = useState([]);
  const [symptomDataLoading, setSymptomDataLoading] = useState(false);
  const [symptomDataError, setSymptomDataError] = useState(null);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [selectedSymptomItem, setSelectedSymptomItem] = useState(null); // New state for parsed data
  const [modalVisible, setModalVisible] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sortField: null,
    sortOrder: null,
  });
  const { get } = useFetch("/api/symptom", {
    setLoading: setSymptomDataLoading,
    setError: setSymptomDataError,
    setData: setSymptomData,
  });

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (symptomDataError) {
      message.error(symptomDataError);
    }
  }, [symptomDataError]);

  useEffect(() => {
    console.log("symptomData", symptomData);
    const mappedData = symptomData?.data?.map((item) => ({
      id: item.id, // Unique key for each row
      userId: item.userId,
      symptomId: item.symptomId,
      bodyPart:
        JSON.parse(item.symptoms).selectedSymptomsDetails[0]?.bodyPart || "N/A",
      date: new Date(item.createdAt).toLocaleDateString(),
      rawData: item, // Store raw data for modal details
    }));
    setSymptomDataWithFilter(mappedData);
  }, [symptomData]);

  // Handle table changes (pagination, filters, sorting)
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
    get();
  };

  // Handle row click to show modal with details
  const handleRowClick = (record) => {
    const parsedSymptoms = JSON.parse(record.rawData.symptoms); // Parse symptoms here
    setSelectedSymptom(record.rawData);
    setSelectedSymptomItem(parsedSymptoms); // Set parsed data
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedSymptom(null);
    setSelectedSymptomItem(null); // Clear parsed data
  };

  // Define table columns
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: false,
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      sorter: false,
    },
    {
      title: "Symptom ID",
      dataIndex: "symptomId",
      key: "symptomId",
      sorter: false,
    },
    {
      title: "Body Part",
      dataIndex: "bodyPart",
      key: "bodyPart",
      filters: [
        { text: "Face", value: "Face" },
        { text: "Head", value: "Head" },
        { text: "Eye", value: "Eye" },
        { text: "Ear", value: "Ear" },
        { text: "Nose", value: "Nose" },
        { text: "Mouth", value: "Mouth and Throat" },
        { text: "Scalp", value: "Scalp" },
        { text: "Neck", value: "Neck" },
        { text: "Chest", value: "Chest" },
        { text: "Arm", value: "Arm" },
        { text: "Breast", value: "Breast" },
        { text: "Leg", value: "Leg" },
        { text: "Abdomen", value: "Abdomen" },
        { text: "Back", value: "Back" },
        { text: "Pelvis", value: "Pelvis" },
        { text: "Genitalia", value: "Genitalia" },
        { text: "Buttock", value: "Buttock" },
        { text: "Skin", value: "Skin" },
      ],
      onFilter: (value, record) => record.bodyPart === value,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        new Date(b.rawData.createdAt) - new Date(a.rawData.createdAt),
    },
  ];

  if (symptomDataLoading && symptomData.length === 0) {
    return <Spin size="large" />;
  }

  return (
    <Card style={{ padding: 10, maxWidth: 800, margin: "auto" }}>
      <Title level={4}>Symptom Request</Title>
      <Table
        columns={columns}
        dataSource={symptomDataWithFilter}
        pagination={tableParams.pagination}
        loading={symptomDataLoading}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => handleRowClick(record), // Handle row click
        })}
        rowKey="key"
      />

      {/* Modal to display detailed information */}
      <Modal
        title="Symptom Details"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedSymptom && selectedSymptomItem && (
          <div>
            {/* Display General Symptoms */}
            {selectedSymptomItem.selectedGeneralSymptoms?.length > 0 && (
              <>
                <Title level={5}>General Symptoms</Title>
                <ul>
                  {selectedSymptomItem.selectedGeneralSymptoms.map(
                    (generalSymptom, index) => (
                      <li key={index}>
                        <strong>-</strong> {generalSymptom}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
            {/* Display Selected Symptoms */}
            <br />
            <Title level={5}>Selected Symptoms</Title>
            <ul>
              {selectedSymptomItem.selectedSymptomsDetails.map(
                (symptom, index, array) => {
                  const isFirstOccurrence =
                    index === 0 ||
                    symptom.bodyPart !== array[index - 1].bodyPart;

                  return (
                    <div key={`${symptom.bodyPart}-${symptom.name}`}>
                      {isFirstOccurrence && (
                        <li>
                          <strong>Body Part:</strong> {symptom.bodyPart}
                        </li>
                      )}
                      <li>
                        <strong>Symptom:</strong> {symptom.name}
                        {symptom.option && symptom.option.length > 0 && (
                          <ul>
                            {symptom.option.map((opt) =>
                              opt.items && opt.items.length > 0 ? (
                                <li key={opt.name}>
                                  <strong>Option:</strong> {opt.name}
                                  <ul>
                                    {opt.items.map((item) => (
                                      <li key={item}>
                                        <strong>Item:</strong> {item}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ) : null
                            )}
                          </ul>
                        )}
                      </li>
                    </div>
                  );
                }
              )}
            </ul>

            {/* Display Additional Info */}
            {selectedSymptomItem.additionalInfo &&
              (selectedSymptomItem.additionalInfo.duration ||
                selectedSymptomItem.additionalInfo.medicalConditions ||
                selectedSymptomItem.additionalInfo.medications ||
                selectedSymptomItem.additionalInfo.additionalSymptoms) && (
                <div>
                  <br />
                  <Title level={5}>Additional Information</Title>
                  <ul>
                    {selectedSymptomItem.additionalInfo.duration && (
                      <li>
                        <strong>Duration of Symptoms:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.duration}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo.medicalConditions && (
                      <li>
                        <strong>Previous Medical Conditions:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.medicalConditions}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo.medications && (
                      <li>
                        <strong>Current Medications:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.medications}
                      </li>
                    )}
                    {selectedSymptomItem.additionalInfo.additionalSymptoms && (
                      <li>
                        <strong>Additional Symptoms:</strong>{" "}
                        {selectedSymptomItem.additionalInfo.additionalSymptoms}
                      </li>
                    )}
                  </ul>
                </div>
              )}
          </div>
        )}
      </Modal>
    </Card>
  );
};

export default SymptomRequest;
